export function createEntityDeps<D>(
  moduleName: string,
): [() => D, (depsToProvide: D) => void] {
  const key = Symbol('injectable dependencies')

  const useDeps = () => {
    const deps = inject<D>(key)
    if (!deps) {
      throw new Error(
        `Dependencies of module [${moduleName}] are used, but not provided!`,
      )
    }
    return deps as D
  }

  const provideDeps = (depsToProvide: D) =>
    useNuxtApp().vueApp.provide(key, depsToProvide)

  return [useDeps, provideDeps]
}
