export default defineNuxtPlugin(() => {
  const stFetch = useRawStFetch()

  const isAvailable = ref(true)
  const isReady = ref(false)
  const ip = ref<string>()
  const countryCode = ref<string>()

  const router = useRouter()

  async function initGeo() {
    const { error, data } = await stFetch('/geo/availability/assert', {})

    isAvailable.value = !error
    isReady.value = true

    if (data) {
      countryCode.value = data.countryCode
    }

    if (error) {
      ip.value = error.data?.ip
      countryCode.value = error.data?.countryCode

      onNuxtReady(() => {
        router.push({
          query: {
            modal: 'restriction',
            countryCode: countryCode.value,
            ip: error.data.ip,
          },
        })
      })
    }
  }

  initGeo()

  return {
    provide: {
      geo: {
        isAvailable,
        isReady,
        ip,
        countryCode,
      },
    },
  }
})
