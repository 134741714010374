export interface RouterBackReturn {
  goBack: () => void
}

export function useRouterBack(): RouterBackReturn {
  const router = useRouter()

  const { $previousRoute } = useNuxtApp()
  const backPath = router?.options?.history?.state?.back

  function goBack() {
    const previousRoute = $previousRoute as Ref<any>
    if (previousRoute && previousRoute.value) {
      router.push(previousRoute.value)
    } else if (backPath) {
      router.back()
    } else {
      router.push('/')
    }
  }

  return {
    goBack,
  }
}
