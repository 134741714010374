<template>
  <span :class="classes" class="st-counter" data-t="st-counter">
    <StIcon v-if="live" name="live" size="16" class="counter-icon" />
    <span class="number">
      <slot>
        {{ props.label }}
      </slot>
    </span>
  </span>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    label?: string | number
    bgColor?: 'white' | 'orange' | 'transparent' | 'black' | 'ghost'
    size?: 's' | 'xs'
    color?: 'orange' | 'white' | 'black'
    live?: boolean
  }>(),
  {
    label: '',
    bgColor: 'white',
    size: 's',
    color: 'orange',
    live: false,
  },
)

const classes = computed(() => [
  `${props.bgColor}-bg`,
  `size-${props.size}`,
  props.live ? 'live' : 'no-live',
  `color-${props.color}`,
])
</script>

<style scoped>
.st-counter {
  display: flex;
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-xs-medium);

  border-radius: var(--border-radius-full);

  &.color-white {
    color: var(--text-primary);
  }

  &.color-orange {
    color: var(--text-link);
  }

  &.color-black {
    color: var(--background-base);
  }

  &.size-s {
    padding: var(--spacing-025) var(--spacing-075) var(--spacing-025)
      var(--spacing-025);

    &.no-live {
      min-width: 20px;
      padding: var(--spacing-025) var(--spacing-050);
    }
  }

  &.size-xs {
    padding-right: var(--spacing-050);

    &.no-live {
      min-width: 16px;
      padding: 0 var(--spacing-050);
    }

    &.number {
      /* stylelint-disable-next-line */
      margin-left: -2px;
    }
  }

  &.white-bg {
    background: var(--text-primary);

    &.color-white {
      color: var(--background-base);
    }

    .counter-icon {
      color: var(--text-link);
    }
  }

  &.orange-bg {
    color: var(--text-primary);
    background: var(--text-link);
  }

  &.black-bg {
    background: var(--background-base);

    .counter-icon {
      color: var(--text-link);
    }
  }

  &.ghost-bg {
    background: var(--background-ghost-tertiary);

    .counter-icon {
      color: var(--text-link);
    }
  }

  &.transparent-bg {
    background: transparent;

    .counter-icon {
      color: var(--text-link);
    }
  }
}
</style>
