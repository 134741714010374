import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import history_45global from "/app/packages/st-core/middleware/history.global.ts";
import modal_45global from "/app/apps/site/middleware/modal.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  history_45global,
  modal_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  game: () => import("/app/entities/st-casino/middleware/game.ts"),
  auth: () => import("/app/apps/site/middleware/auth.ts"),
  "casino-main-page": () => import("/app/apps/site/middleware/casinoMainPage.ts"),
  geo: () => import("/app/apps/site/middleware/geo.ts"),
  kyc: () => import("/app/apps/site/middleware/kyc.ts"),
  search: () => import("/app/apps/site/middleware/search.ts")
}