import type { AsyncComponentLoader, Component } from 'vue'
import { useTelegram } from '@st/telegram/composables/useTelegram'
import {
  LazyGameErrorModal,
  LazyAuthorizationForm,
  LazyBiometricModal,
  LazyBonusesWidget,
  LazyChangeNicknameForm,
  LazyMChangeNicknameForm,
  LazyChangePasswordForm,
  LazyMChangePasswordForm,
  LazyChooseGameModal,
  LazyCookiesModal,
  LazyDeleteAddressModal,
  LazyDepositBonusModal,
  LazyDepositBonusTooltip,
  LazyFreebetTooltip,
  LazyFreespinTooltip,
  LazyMFreespinRollingModal,
  LazyFreespinRollingModal,
  LazyIpRestrictionModal,
  LazyMAuthorization,
  LazyMRegistration,
  LazyMNewAddressModal,
  LazyMPaymentsModal,
  LazyMWithdrawalStatus,
  LazyNewAddressModal,
  LazyMNotificationsList,
  LazyPaymentsModal,
  LazyRecoveryPasswordForm,
  LazyMRecoveryPassword,
  LazyReferralBonusTooltip,
  LazyRegistrationForm,
  LazyRegularBonusTooltip,
  LazySearchForm,
  LazySumSubModal,
  LazyVerificationModal,
  LazyWithdrawalStatus,
  LazyMBetHistoryModal,
  LazyCashBonusTooltip,
  LazyPwaInstallModal,
  LazyUserVerificationModal,
  LazyVerificationKycModal,
  LazyChangeVerificationDataModal,
  LazySetEmailForm,
  LazyLowBalanceModal,
  LazyGameGeoErrorModal,
  LazyExchangeStatus,
  LazyCreateAccountModal,
  LazyChangeEmailModal,
  LazyMWebPushInstructionModal,
  LazyWebPushInstructionModal,
  LazySelfExclusionModal,
} from '#components'

type Middleware = 'auth' | 'geo' | 'search' | 'kyc'

interface ModalConfig {
  component: AsyncComponentLoader | Component
  wrapper: 'modal' | 'drawer' | 'drawer-compact'
  closeOnClickOutside?: boolean
  hideCloseButton?: boolean
  closableWithSwipe?: boolean
  middleware?: Middleware[]
}

export function useModalConfig() {
  const { isMobile } = usePlatform()
  const { isTelegramMiniApp } = useTelegram()

  const desktopModals = computed<Record<string, ModalConfig>>(() => ({
    selfExclusion: {
      component: LazySelfExclusionModal,
      closeOnClickOutside: false,
      wrapper: 'modal',
      middleware: ['geo'],
    },
    changeEmail: {
      component: LazyChangeEmailModal,
      wrapper: 'modal',
      middleware: ['geo'],
    },
    register: {
      component: LazyRegistrationForm,
      wrapper: 'modal',
      closeOnClickOutside: false,
      middleware: ['geo'],
    },
    login: {
      component: LazyAuthorizationForm,
      wrapper: 'modal',
      middleware: ['geo'],
    },
    recoverPassword: {
      component: LazyRecoveryPasswordForm,
      wrapper: 'modal',
      middleware: ['geo'],
    },
    payments: {
      component: LazyPaymentsModal,
      closeOnClickOutside: false,
      wrapper: 'modal',
      middleware: ['auth', 'geo', 'kyc'],
    },
    verificationKyc: {
      component: LazyVerificationKycModal,
      wrapper: 'modal',
    },
    search: {
      component: LazySearchForm,
      wrapper: 'modal',
      middleware: ['search'],
    },
    withdrawalStatus: {
      component: LazyWithdrawalStatus,
      wrapper: 'modal',
    },
    newAddress: {
      component: LazyNewAddressModal,
      wrapper: 'modal',
    },
    deleteAddress: {
      component: LazyDeleteAddressModal,
      wrapper: 'modal',
    },
    changeNickname: {
      component: LazyChangeNicknameForm,
      wrapper: 'modal',
    },
    changePersonal: {
      component: LazyChangeVerificationDataModal,
      wrapper: 'modal',
    },
    cookies: {
      component: LazyCookiesModal,
      wrapper: 'modal',
    },
    restriction: {
      component: LazyIpRestrictionModal,
      wrapper: 'modal',
      closeOnClickOutside: false,
    },
    verification: {
      component: LazyVerificationModal,
      wrapper: 'modal',
    },
    biometric: {
      component: LazyBiometricModal,
      wrapper: 'modal',
    },
    sumSubVerification: {
      component: LazySumSubModal,
      wrapper: 'modal',
    },
    changePassword: {
      component: LazyChangePasswordForm,
      wrapper: 'modal',
    },
    chooseGame: {
      component: LazyChooseGameModal,
      wrapper: 'modal',
    },
    freespinRolling: {
      component: LazyFreespinRollingModal,
      wrapper: 'modal',
    },
    depositBonus: {
      component: LazyDepositBonusModal,
      wrapper: 'modal',
    },
    freespinTooltip: {
      component: LazyFreespinTooltip,
      wrapper: 'modal',
    },
    freebetTooltip: {
      component: LazyFreebetTooltip,
      wrapper: 'modal',
    },
    regularBonusTooltip: {
      component: LazyRegularBonusTooltip,
      wrapper: 'modal',
    },
    referralBonusTooltip: {
      component: LazyReferralBonusTooltip,
      wrapper: 'modal',
    },
    depositBonusTooltip: {
      component: LazyDepositBonusTooltip,
      wrapper: 'modal',
    },
    cashBonusTooltip: {
      component: LazyCashBonusTooltip,
      wrapper: 'modal',
    },
    userVerification: {
      component: LazyUserVerificationModal,
      wrapper: 'modal',
    },
    pwaInstall: {
      component: LazyPwaInstallModal,
      wrapper: 'modal',
    },
    setEmail: {
      component: LazySetEmailForm,
      wrapper: 'modal',
    },
    lowBalance: {
      component: LazyLowBalanceModal,
      wrapper: 'modal',
      middleware: ['geo', 'auth'],
    },
    gameError: {
      component: LazyGameErrorModal,
      wrapper: 'modal',
    },
    gameGeoError: {
      component: LazyGameGeoErrorModal,
      wrapper: 'modal',
    },
    exchangeStatus: {
      component: LazyExchangeStatus,
      wrapper: 'modal',
    },
    createAccount: {
      component: LazyCreateAccountModal,
      wrapper: 'modal',
    },
    webPushInstruction: {
      component: LazyWebPushInstructionModal,
      wrapper: 'modal',
    },
  }))

  const mobileModals = computed<Record<string, ModalConfig>>(() => ({
    selfExclusion: {
      component: LazySelfExclusionModal,
      closeOnClickOutside: false,
      wrapper: 'drawer-compact',
      middleware: ['geo'],
    },
    changeEmail: {
      component: LazyChangeEmailModal,
      wrapper: 'drawer-compact',
      middleware: ['geo'],
    },
    changePersonal: {
      component: LazyChangeVerificationDataModal,
      wrapper: 'drawer-compact',
    },
    pwaInstall: {
      component: LazyPwaInstallModal,
      wrapper: 'drawer-compact',
    },
    userVerification: {
      component: LazyUserVerificationModal,
      wrapper: 'drawer',
    },
    register: {
      component: LazyMRegistration,
      wrapper: isTelegramMiniApp.value ? 'drawer-compact' : 'drawer',
      middleware: ['geo'],
    },
    login: {
      component: LazyMAuthorization,
      wrapper: 'drawer',
      middleware: ['geo'],
    },
    recoverPassword: {
      component: LazyMRecoveryPassword,
      wrapper: 'drawer',
      middleware: ['geo'],
    },
    notify: {
      component: LazyMNotificationsList,
      wrapper: 'drawer',
    },
    changeNickname: {
      component: LazyMChangeNicknameForm,
      wrapper: 'drawer-compact',
    },
    search: {
      component: LazySearchForm,
      wrapper: 'drawer',
    },
    payments: {
      component: LazyMPaymentsModal,
      wrapper: 'drawer',
      middleware: ['geo', 'auth', 'kyc'],
    },
    lowBalance: {
      component: LazyLowBalanceModal,
      wrapper: 'drawer-compact',
      middleware: ['geo', 'auth'],
    },
    verificationKyc: {
      component: LazyVerificationKycModal,
      wrapper: 'drawer-compact',
    },
    newAddress: {
      component: LazyMNewAddressModal,
      wrapper: 'drawer',
    },
    withdrawalStatus: {
      component: LazyMWithdrawalStatus,
      wrapper: 'drawer-compact',
    },
    sumSubVerification: {
      component: LazySumSubModal,
      wrapper: 'drawer',
    },
    restriction: {
      component: LazyIpRestrictionModal,
      wrapper: 'drawer-compact',
    },
    biometric: {
      component: LazyBiometricModal,
      wrapper: 'drawer-compact',
    },
    verification: {
      component: LazyVerificationModal,
      wrapper: 'drawer-compact',
    },
    changePassword: {
      component: LazyMChangePasswordForm,
      wrapper: 'drawer-compact',
    },
    chooseGame: {
      component: LazyChooseGameModal,
      wrapper: 'drawer-compact',
    },
    freespinRolling: {
      component: LazyMFreespinRollingModal,
      wrapper: 'drawer-compact',
    },
    depositBonus: {
      component: LazyDepositBonusModal,
      wrapper: 'drawer-compact',
    },
    bonuses: {
      component: LazyBonusesWidget,
      wrapper: 'drawer-compact',
    },
    freespinTooltip: {
      component: LazyFreespinTooltip,
      wrapper: 'drawer-compact',
    },
    freebetTooltip: {
      component: LazyFreebetTooltip,
      wrapper: 'drawer-compact',
    },
    regularBonusTooltip: {
      component: LazyRegularBonusTooltip,
      wrapper: 'drawer-compact',
    },
    referralBonusTooltip: {
      component: LazyReferralBonusTooltip,
      wrapper: 'drawer-compact',
    },
    depositBonusTooltip: {
      component: LazyDepositBonusTooltip,
      wrapper: 'drawer-compact',
    },
    cashBonusTooltip: {
      component: LazyCashBonusTooltip,
      wrapper: 'drawer-compact',
    },
    bets: {
      component: LazyMBetHistoryModal,
      wrapper: 'drawer',
      middleware: ['geo', 'auth'],
    },
    setEmail: {
      component: LazySetEmailForm,
      wrapper: 'drawer-compact',
    },
    gameError: {
      component: LazyGameErrorModal,
      wrapper: 'drawer-compact',
    },
    gameGeoError: {
      component: LazyGameGeoErrorModal,
      wrapper: 'drawer-compact',
    },
    exchangeStatus: {
      component: LazyExchangeStatus,
      wrapper: 'drawer-compact',
    },
    createAccount: {
      component: LazyCreateAccountModal,
      wrapper: 'drawer-compact',
    },
    webPushInstruction: {
      component: LazyMWebPushInstructionModal,
      wrapper: 'drawer-compact',
    },
  }))

  return computed(() =>
    isMobile.value ? mobileModals.value : desktopModals.value,
  )
}
