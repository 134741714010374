import type { FetchOptions } from 'ofetch'
import { useApplicationType, usePlatform } from '@st/use/composables'

export function useApiConfig() {
  const optionsFromConfig = useRuntimeConfig().public.api

  const { baseURL: defaultBaseUrl, serverBaseURL } = optionsFromConfig

  const platformBaseURL =
    (import.meta.client ? defaultBaseUrl : serverBaseURL) || defaultBaseUrl

  const bus = useApiEventBus()

  const { platform } = usePlatform()
  const { isTelegramMiniApp, isPwa } = useApplicationType()

  function getXPlatform() {
    if (isTelegramMiniApp.value) {
      return 'telegramMiniApp'
    }
    if (isPwa.value) {
      return 'pwa'
    }
    return `${platform.value}site`
  }

  function normalizeBaseURL(url: string) {
    if (import.meta.client && url.startsWith('/')) {
      return `${window.location.origin}${url}`
    }
    return url
  }

  function createApiConfig(options: FetchOptions = {}): FetchOptions {
    const mergedConfig: FetchOptions = {
      ...options,
      credentials: 'include',
      onRequest: (context) => {
        bus.emit('request', context)
        if (options.onRequest) options.onRequest(context)
      },
      onResponse: (context) => {
        bus.emit('response', context)
        if (options.onResponse) options.onResponse(context)
      },
      onRequestError: (context) => {
        bus.emit('requestError', context)
        if (options.onRequestError) options.onRequestError(context)
      },
      onResponseError: (context) => {
        bus.emit('responseError', context)
        if (options.onResponseError) options.onResponseError(context)
      },
    }

    mergedConfig.baseURL = normalizeBaseURL(options.baseURL || platformBaseURL)

    mergedConfig.headers = {
      ...mergedConfig.headers,
      'X-platform': getXPlatform(),
    }

    return mergedConfig
  }

  return { createApiConfig }
}

export function useAdminApiConfig() {
  const optionsFromConfig = useRuntimeConfig().public.api as any
  const requestURL = useRequestURL()

  function normalizeBaseURL(baseURL: string) {
    return baseURL.startsWith('/') ? `${requestURL.origin}${baseURL}` : baseURL
  }

  function createApiConfig(options: FetchOptions = {}): FetchOptions {
    const mergedConfig: FetchOptions = {
      ...optionsFromConfig,
      ...options,
      credentials: 'include',
      onRequest: (context) => {
        // Добавляет godAdminId для запросов на localhost и CI
        if (['localhost', 'admin'].includes(document.location.hostname)) {
          console.log(
            '%c Your request with godAdminId=1 mode on dev! ',
            'background: #fdfdfd; color: #000; padding: 5px; display: block; text-align: center;',
          )
          context.options.query = options.query || {}
          context.options.query.godAdminId = 1
        }
        if (options.onRequest) options.onRequest(context)
      },
      onResponse: (context) => {
        if (options.onResponse) options.onResponse(context)
      },
      onRequestError: (context) => {
        if (options.onRequestError) options.onRequestError(context)
      },
      onResponseError: async (context) => {
        if (options.onResponseError) options.onResponseError(context)
      },
    }

    mergedConfig.baseURL = mergedConfig.baseURL
      ? normalizeBaseURL(mergedConfig.baseURL)
      : normalizeBaseURL('/')

    return mergedConfig
  }

  return { createApiConfig }
}
