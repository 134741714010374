<template>
  <span
    class="st-badge"
    :class="[`size-${size}`, fontColor, background]"
    data-t="st-badge"
  >
    <StIcon
      v-if="isLeftIcon"
      class="icon"
      :class="[{ orange: type === 'live' }, iconColor]"
      :name="type"
      :size="iconSize"
    />
    <span class="badge-text">
      <slot>
        {{ props.label }}
      </slot>
    </span>
  </span>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types.ts'

const props = withDefaults(
  defineProps<{
    label?: string | number
    iconSize?: number | string
    type?: IconName
    size?: 's' | 'm' | 'xs' | 'xxs'
    background?: 'gray' | 'transparent' | 'ghost' | 'red'
    iconColor?: 'orange' | 'white' | 'gray'
    isLeftIcon?: boolean
  }>(),
  {
    label: '',
    iconSize: 12,
    type: 'live',
    size: 'm',
    background: 'gray',
    iconColor: 'gray',
    isLeftIcon: true,
  },
)

const fontColor = computed(() => (props.type === 'live' ? '' : 'font-gray'))
</script>

<style scoped>
.st-badge {
  display: inline-flex;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: center;

  width: min-content;

  font: var(--desktop-text-xs-medium);

  &.size-s {
    padding: var(--spacing-025) var(--spacing-075);
    border-radius: var(--border-radius-075);
  }

  &.size-m {
    padding: var(--spacing-050) var(--spacing-100);
    border-radius: var(--border-radius-100);
  }

  &.size-xs {
    padding: var(--spacing-025) var(--spacing-075) var(--spacing-025)
      var(--spacing-050);
    border-radius: var(--border-radius-full);
  }

  &.size-xxs {
    min-height: 14px;
    padding-right: var(--spacing-075);
    border-radius: var(--border-radius-full);

    .badge-text {
      /* stylelint-disable */
      margin-left: -4px;
      /* stylelint-enable */
      font: var(--desktop-text-xxs-medium);
    }
  }

  &.font-gray {
    color: var(--text-tertiary);
  }

  &.gray {
    background: var(--background-ghost-tertiary);
  }

  &.transparent {
    background: transparent;
  }

  &.ghost {
    background: var(--background-ghost-tertiary);
    backdrop-filter: blur(20px);
    box-shadow: 0 8px 32px -8px #111118;
  }

  &.red {
    background-color: var(--background-error);
  }
}

.icon {
  min-width: 10px;

  &.orange {
    background-color: var(--icon-brand);
  }

  &.white {
    background-color: var(--icon-primary);
  }

  &.gray {
    background-color: var(--icon-secondary);
  }
}
</style>
