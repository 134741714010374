import { useUserStore } from '@st/user/stores/useUserStore'
import { useTelegram } from '@st/telegram/composables/useTelegram'

export default defineNuxtPlugin({
  order: 2,
  name: 'showTelegramAuth',
  dependsOn: ['telegramSdk' as any],
  setup() {
    const { isAuthenticated, isReady } = storeToRefs(useUserStore())
    const { isTelegramMiniApp } = useTelegram()

    const router = useRouter()

    onNuxtReady(async () => {
      await until(isReady).toBe(true)
      if (!isAuthenticated.value && isTelegramMiniApp.value) {
        router.replace({
          query: {
            modal: 'register',
          },
        })
      }
    })
  },
})
