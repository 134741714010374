import {
  init,
  mountMiniApp,
  miniAppReady,
  mountViewport,
  expandViewport,
  mountClosingBehavior,
  enableClosingConfirmation,
  mountSwipeBehavior,
  disableVerticalSwipes,
  setMiniAppHeaderColor,
  isTMA,
} from '@telegram-apps/sdk-vue'

/**
 * Инициализация Telegram Mini App
 * установка необходимых компонентов
 * стили хедера, открытие на весь экран
 * подтверждение закрытия, отключение вертикального свайпа
 */
async function initTgMiniApp() {
  init()

  if (mountMiniApp.isAvailable()) {
    await mountMiniApp()
  }
  if (miniAppReady.isAvailable()) {
    miniAppReady()
  }
  if (setMiniAppHeaderColor.isAvailable()) {
    setMiniAppHeaderColor('#111118')
  }

  if (mountViewport.isAvailable()) {
    mountViewport()
  }
  if (expandViewport.isAvailable()) {
    expandViewport()
  }

  if (mountClosingBehavior.isAvailable()) {
    mountClosingBehavior()
  }
  if (enableClosingConfirmation.isAvailable()) {
    enableClosingConfirmation()
  }

  if (mountSwipeBehavior.isAvailable()) {
    mountSwipeBehavior()
  }
  if (disableVerticalSwipes.isAvailable()) {
    disableVerticalSwipes()
  }
}

export default defineNuxtPlugin({
  order: 1,
  name: 'telegramSdk',
  async setup() {
    const isTelegramMiniApp = ref(false)

    if (isTMA()) {
      isTelegramMiniApp.value = true
      initTgMiniApp()
    }

    return {
      provide: {
        isTelegramMiniApp,
      },
    }
  },
})
