import type { CashBonus } from '../types'
import { useRefreshBonusesSocket } from '../composables/useRefreshBonusesSocket'
import { useBonusesDeps } from '../useDeps'

/**
 * стор для денежных вознаграждений, участвующих
 * в бонусной программе за депозит
 */
export const useCashBonusesStore = defineStore('cashBonuses', () => {
  const stFetch = useRawStFetch()
  const cashBonuses = ref<CashBonus[]>([])
  const cashBonusesCount = computed(() => cashBonuses.value.length)
  const isLoading = ref(false)

  async function fetchCashBonuses() {
    isLoading.value = true
    const { data, error } = await stFetch('/bonus/find', {
      method: 'post',
      body: {
        params: {
          expired: false,
          claimed: false,
          bonusProgramType: ['bonusForDeposit'],
        },
        pagination: {
          page: 0,
          perPage: 50,
          orderBy: [
            {
              fieldName: 'id',
              sortOrder: 'ASC',
            },
          ],
        },
      },
    })
    isLoading.value = false

    if (error) {
      console.error('Failed to load deposit bonuses', error)
      return
    }

    cashBonuses.value = data.data
  }

  const { isAuthenticated } = useBonusesDeps()
  watchEffect(() => {
    if (isAuthenticated.value) {
      fetchCashBonuses()
    } else {
      cashBonuses.value = []
    }
  })

  useRefreshBonusesSocket(fetchCashBonuses)

  return {
    cashBonuses,
    isLoading,
    cashBonusesCount,
    fetchCashBonuses,
  }
})
