import { useIsNuxtReady } from '@st/use/composables'

export function useIpqsScript() {
  const config = useRuntimeConfig()
  const apiKey = config.public.ipqsKey

  const scriptPath = `https://www.ipqscdn.com/api/${apiKey}/learn.js`

  const { isNuxtReady } = useIsNuxtReady()

  const ipqs = useScript(
    {
      src: scriptPath,
      async: true,
      crossorigin: 'anonymous',
      referrerpolicy: false,
    },
    {
      trigger: computed(() => isNuxtReady.value && !!apiKey),
      use: () => globalThis.Startup,
    },
  )

  return ipqs
}
