<template>
  <component :is="messageComponent" :notification="item" :compact />
</template>

<script setup lang="ts">
import { useAccountTransactionNotification } from '../../../composables/useAccountTransactionNotification'
import type { Notification } from '../../../types'

interface Props {
  item: Notification
  compact?: boolean
}

const { compact = false, item } = defineProps<Props>()

const { notificationOperationType } = useAccountTransactionNotification(
  toRef(() => item),
)

/**
 * Здесь в качестве типа возвращаемых данных используем any,
 * поскольку несмотря на то, что мы передаем все правильные данные в пропсы
 * - ts на уровне темплейта этого не понимает при динамическом компоненте.
 * Безболезненный способ обхода - прописать в темплейте каждый отдельный компонент
 * на каждый конкретный случай, но в данном случае это как будто перебор
 */
const messageComponent = computed<any>(() => {
  /*
    у accountTransactionCreated компонент сопоставляется
    по полю operationType внутри data
  */
  if (notificationOperationType.value) {
    switch (notificationOperationType.value) {
      case 'referralAccrual':
        return defineAsyncComponent(
          () => import('./templates/ReferalAccrualMessage.vue'),
        )
      case 'freebetAccrual':
      case 'freebetProcessAccrual':
      case 'freebetWinAccrual':
        return defineAsyncComponent(
          () => import('./templates/FreebetMessage.vue'),
        )
      case 'freespinRewardAccrual':
        return defineAsyncComponent(
          () => import('./templates/FreespinWinMessage.vue'),
        )
      case 'carrotAccrual': {
        const reasonForGetting =
          item.type === 'accountTransactionCreated'
            ? item.data.meta?.reasonForGetting
            : undefined

        if (reasonForGetting === 'telegramBotSubscription')
          return defineAsyncComponent(
            () => import('./templates/CarrotAccrualTelegramMessage.vue'),
          )

        return defineAsyncComponent(
          () => import('./templates/CarrotAccrualDefaultMessage.vue'),
        )
      }
      default:
        return null
    }
  }

  /*
    компонент подбирается по полю type нотификации
  */
  switch (item.type) {
    case 'carrotSystem':
    case 'carrotMarketing':
      return defineAsyncComponent(() => import('./templates/CarrotMessage.vue'))
    case 'depositCreated':
      return defineAsyncComponent(
        () => import('./templates/DepositCreatedMessage.vue'),
      )
    case 'depositProcessed':
      return defineAsyncComponent(
        () => import('./templates/DepositProcessedMessage.vue'),
      )
    case 'withdrawalProcessed':
      return defineAsyncComponent(
        () => import('./templates/WithdrawalProcessedMessage.vue'),
      )
    case 'betProcessedLossOrdinary':
    case 'betProcessedWinOrdinary':
    case 'betUnacceptedOrdinary':
      return defineAsyncComponent(
        () => import('./templates/BetProcessedOrdinaryMessage.vue'),
      )
    case 'betProcessedWinExpress':
    case 'betProcessedLossExpress':
    case 'betUnacceptedExpress':
      return defineAsyncComponent(
        () => import('./templates/BetProcessedExpressMessage.vue'),
      )
    case 'userVerificationCreated':
      return defineAsyncComponent(
        () => import('./templates/VerificationMessage.vue'),
      )
    case 'bonusForDepositProgramUsersCreated':
      return defineAsyncComponent(
        () => import('./templates/BonusForDepositProgramCreated.vue'),
      )
    case 'userVerificationStatus':
      return defineAsyncComponent(
        () => import('./templates/VerificationStatus.vue'),
      )
    case 'bonusCreated':
    case 'bonusExpired':
      return defineAsyncComponent(() => import('./templates/BonusMessage.vue'))
    case 'userFreespinProgramCreated':
      return defineAsyncComponent(
        () => import('./templates/FreespinAccrualMessage.vue'),
      )
    case 'userAuthProviderCreated':
      return defineAsyncComponent(
        () => import('./templates/AuthProviderMessage.vue'),
      )
    case 'admin':
      return defineAsyncComponent(() => import('./templates/AdminMesage.vue'))
    case 'userBonusRollingStepsClaimed':
      return defineAsyncComponent(
        () => import('./templates/BonusRollingStepsClaimed.vue'),
      )
    default:
      return null
  }
})
</script>
