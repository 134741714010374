<template>
  <section class="m-st-section" :class="platform">
    <StSectionHeader
      v-if="!props.omitHeader"
      v-bind="props"
      :data-t="props.headerDataT"
    >
      <template #desktop>
        <slot name="desktopHeader" />
      </template>
      <template #addition>
        <slot name="addition" />
      </template>
      <template #interactive>
        <slot name="interactive" />
      </template>
    </StSectionHeader>
    <slot />
  </section>
</template>

<script setup lang="ts">
import { type Props as StSectionHeaderProps } from '../StSectionHeader/StSectionHeader.vue'

interface Props extends Omit<StSectionHeaderProps, 'dataT'> {
  omitHeader?: boolean
  headerDataT?: StSectionHeaderProps['dataT']
}

const { platform } = usePlatform()

const props = withDefaults(defineProps<Props>(), {
  withChevron: true,
  headerDataT: undefined,
})
</script>

<style scoped>
.m-st-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
}

.mobile {
  &.m-st-section {
    padding: var(--spacing-200) 0;
  }

  &.m-st-section > * {
    padding: 0 var(--spacing-200);
  }
}
</style>
