<template>
  <MStSection
    :title="t('tournament.tournament')"
    :title-icon="size === 's' ? 'cup-solid' : undefined"
    :size="size"
    :with-chevron="size === 's'"
  >
    <TournamentCard :card="card" is-mobile class="page-tournament-card" />
  </MStSection>
</template>

<script setup lang="ts">
import MStSection from '@st/ui/components/StSection/MStSection.vue'
import type { Tournament } from '../../types'
import TournamentCard from './TournamentCard.vue'

interface Props {
  card: Tournament
  size?: 's' | 'm'
}

const { card, size = 'm' } = defineProps<Props>()

const { t } = useI18n()
</script>
