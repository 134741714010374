import type { Freespin, UserFreespinProgramStatusPayload } from '../types'
import { useRefreshBonusesSocket } from '../composables/useRefreshBonusesSocket'
import { useBonusesDeps } from '../useDeps'

export const useFreespinsStore = defineStore('freespins', () => {
  const stFetch = useRawStFetch()
  const io = useSocket()
  const isLoading = ref(false)
  const freespins = ref<Freespin[]>([])

  /*
    фриспины без бонусной программы за депозит
  */
  const notDepositFreespins = computed(() =>
    freespins.value.filter((freespin) => !freespin.bonusForDepositProgram),
  )

  /*
    фриспины с бонусной программой за депозит
  */
  const depositFreespins = computed(() =>
    freespins.value.filter((freespin) => freespin.bonusForDepositProgram),
  )

  async function fetchFreespins() {
    let total = 0
    let page = 0
    const items: Freespin[] = []
    isLoading.value = true
    do {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await stFetch('/user-freespin-program/find', {
        method: 'post',
        body: {
          params: {
            status: ['new', 'inProgress', 'rolling'],
          },
          pagination: {
            page,
            perPage: 50,
            orderBy: [
              {
                fieldName: 'createdAt',
                sortOrder: 'DESC',
              },
            ],
          },
        },
      })
      items.push(...data.data)
      total = data.paging.total
      page += 1
    } while (items.length < total)

    freespins.value = items
    isLoading.value = false
  }

  const count = computed(() => freespins.value.length)

  const { isAuthenticated } = useBonusesDeps()
  watchEffect(() => {
    if (isAuthenticated.value) {
      fetchFreespins()
    } else {
      freespins.value = []
    }
  })

  const router = useRouter()
  const currentFreespinRolling = ref<Freespin | null>(null)
  function handleShowFreespinRollingModal(id: number) {
    if (currentFreespinRolling.value?.userFreespinProgramId === id) return

    const freespin = freespins.value.find(
      (item) => item.userFreespinProgramId === id,
    )

    if (freespin) {
      router.replace({ query: { modal: 'freespinRolling', id } })
      currentFreespinRolling.value = freespin
    }
  }

  io.on(
    'userFreespinProgramStatus',
    async (payload: UserFreespinProgramStatusPayload) => {
      await fetchFreespins()
      if (payload.userFreespinProgramStatus === 'rolling') {
        handleShowFreespinRollingModal(payload.userFreespinProgramId)
      }
    },
  )

  io.on('userFreespinProgramAvailableFreespinsNumberChanged', fetchFreespins)

  const rollingGamesNames = ref<Record<number, string>>({})
  useRefreshBonusesSocket(fetchFreespins)

  return {
    fetchFreespins,
    freespins,
    notDepositFreespins,
    depositFreespins,
    count,
    currentFreespinRolling,
    rollingGamesNames,
    isLoading,
  }
})
