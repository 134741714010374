export interface UseCountdownOptions {
  isPaused: Ref<boolean>
}

export interface UseCountdownReturn {
  countdown: Ref<string>
  countdownInMs: Ref<number>
  countdownInDays: Ref<number>
  countdownInUnits: Ref<{
    days: string
    hours: string
    minutes: string
    seconds: string
  }>
  isExpired: Ref<boolean>
}

export function useCountdown(
  deadline: Ref<number | string>,
  options?: UseCountdownOptions,
): UseCountdownReturn {
  const { parseDate, duration: parseDuration } = useDate()

  const currentTimestamp = useTimestamp({ interval: 1000 })
  const initialTimestamp = ref(currentTimestamp.value)

  const isExpired = computed(() =>
    parseDate(toValue(deadline)).isBefore(currentTimestamp.value),
  )

  watch(
    () => toValue(options?.isPaused),
    () => {
      initialTimestamp.value = currentTimestamp.value
    },
  )

  const countdownAsDuration = computed(() => {
    const timestamp = toValue(options?.isPaused)
      ? initialTimestamp.value
      : currentTimestamp.value
    return parseDuration(
      parseDate(toValue(deadline)).diff(parseDate(timestamp)),
    )
  })

  const countdownInMs = computed(() =>
    countdownAsDuration.value.asMilliseconds(),
  )

  const countdownInDays = computed(() =>
    Math.floor(countdownAsDuration.value.asDays()),
  )

  const countdown = computed(() => {
    const duration = countdownAsDuration.value

    if (duration.asDays() > 1) return duration.format('DD:HH:mm:ss')
    if (duration.asHours() > 1) return duration.format('HH:mm:ss')
    return duration.format('mm:ss')
  })

  const countdownInUnits = computed(() => {
    const duration = countdownAsDuration.value

    return {
      days: duration.asDays().toString(),
      hours: duration.hours().toString().padStart(2, '0'),
      minutes: duration.minutes().toString().padStart(2, '0'),
      seconds: duration.seconds().toString().padStart(2, '0'),
    }
  })

  return {
    countdown,
    countdownInMs,
    countdownInDays,
    countdownInUnits,
    isExpired,
  }
}
