import { usePragmaticDGAStore } from '../stores/usePragmaticDGAStore'

export default defineNuxtPlugin({
  name: 'pragmaticDGA',
  order: 3,
  setup() {
    const { initDgaStore } = usePragmaticDGAStore()

    const restrictionRoutes = ['/promo/registration']
    usePreventInitByRoute(restrictionRoutes, () => {
      initDgaStore()
    })
  },
})
