<template>
  <div class="bets-wrapper">
    <div v-if="!isEmpty" class="bookmaker-bets">
      <HistoryBet v-for="item in extendedBets" :key="item.id" :bet="item" />
      <template v-if="pending">
        <StSkeletonLoader
          v-for="i in 5"
          :key="i"
          height="110px"
          bg-color="var(--background-secondary)"
          :shimmer-opacity="0.28"
          radius="var(--border-radius-150)"
        />
      </template>
      <div ref="endOfTheList" />
    </div>
    <div v-else class="empty-list">
      <p>{{ t('bets.empty') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@st/use/composables'
import HistoryBet from './parts/HistoryBet.vue'
import type { FindBetParams } from '../../types'
import { useFindHistoryBets } from '../../composables/useFindHistoryBets'

interface Props {
  betType?: 'inGame' | 'processed'
}
const props = withDefaults(defineProps<Props>(), {
  betType: 'inGame',
})
const betTypesParams = computed<
  ('new' | 'accepted' | 'processed' | 'paused')[]
>(() => {
  switch (props.betType) {
    case 'inGame':
      return ['paused', 'accepted']
    case 'processed':
      return ['processed']
    default:
      return ['paused', 'accepted']
  }
})
const params = computed<FindBetParams>(() => ({
  status: betTypesParams.value,
}))

const { t } = useI18n()

const endOfTheList = ref<HTMLDivElement>()

const { canLoadMore, loadMoreBets, extendedBets, pending, isEmpty } =
  useFindHistoryBets(params)

useInfiniteScroll({
  el: endOfTheList,
  loadMore: async () => {
    if (canLoadMore.value) {
      await loadMoreBets()
    }
  },
  disabledObserver: computed(() => !canLoadMore.value),
})
</script>

<style scoped>
.bets-wrapper {
  display: flex;
  height: 100%;
}

.bookmaker-bets {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  gap: var(--spacing-100);
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  width: 248px;
  height: 188px;
  margin: auto;

  background-image: url('./empty.png');
  background-repeat: no-repeat;
  background-position: top center;

  p {
    margin: 0;
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
  }
}

.pending {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}
</style>
