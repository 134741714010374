import Decimal from 'decimal.js'

Decimal.set({
  precision: 24,
  toExpPos: 24,
  toExpNeg: -24,
  rounding: Decimal.ROUND_DOWN,
})

export default Decimal
