<template>
  <header
    class="header"
    :class="{
      'full-width': fullWidth,
      'drag-line': withDragLinePadding,
      'border-bottom': withBorderBottom,
    }"
    :data-t="dataT?.root"
  >
    <StButton
      v-if="withBackButton"
      icon="chevron-left"
      type="text-white"
      size="m"
      @click="emit('back')"
    />
    <div class="title-wrapper">
      <h2 v-if="title" class="title" :data-t="dataT?.title">{{ title }}</h2>
      <slot />
    </div>
    <StButton
      v-if="withCloseButton"
      icon="cross-large"
      type="text-white"
      size="m"
      data-t="drawer-close-button-gbwh"
      @click="handleClose"
    />
  </header>
</template>

<script setup lang="ts">
interface Props {
  withBackButton?: boolean
  title?: string
  fullWidth?: boolean
  disableRouterBack?: boolean
  withDragLinePadding?: boolean
  withBorderBottom?: boolean
  withCloseButton?: boolean
  dataT?: { root?: string; title?: string }
}

const {
  withBackButton,
  title,
  fullWidth,
  disableRouterBack,
  withDragLinePadding,
  withBorderBottom,
  dataT,
  withCloseButton = true,
} = defineProps<Props>()

const emit = defineEmits<{
  back: []
  close: []
}>()

const router = useRouter()

function handleClose(): void {
  emit('close')
  if (!disableRouterBack) router.replace({ query: {} })
}
</script>

<style scoped>
.header {
  display: flex;
  gap: var(--spacing-125);
  align-items: center;

  height: 48px;
  padding: var(--spacing-150) var(--spacing-200);

  &.full-width {
    padding-left: 0;
  }

  &.drag-line {
    padding-top: var(--spacing-250);
  }

  &.border-bottom {
    height: 48.5px;
    border-bottom: var(--border-width-light) solid var(--border-primary);
  }
}

.title-wrapper {
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-200);
}

.title {
  margin: 0;
  font: var(--title-font, var(--mobile-title-2-semibold));
}

:deep(.st-button.type-text-white.only-icon) {
  padding: 0;
}
</style>
