import type { Ref } from 'vue'
import type { RequestBody, ResponseBody } from '@st/openapi-tools'
import type { paths as ApiSchema } from '../paths'

type MaybeRefOrGetter<T> = T | Ref<T> | (() => T)

// Даже не спрашивайте меня как это работает)
type PaginatedRouteRequestBody =
  | {
      params: any
      pagination:
        | { orderBy: { fieldName: string }[] }
        | { perPage: number; page?: number }
    }
  | undefined

type PaginatedRouteResponseBody = { data: any[] } | undefined

export type UseInfiniteListParams<Path extends keyof ApiSchema> = RequestBody<
  ApiSchema,
  Path,
  'post'
> extends PaginatedRouteRequestBody
  ? {
      url: MaybeRefOrGetter<Path>
      orderBy: MaybeRefOrGetter<
        Exclude<
          RequestBody<ApiSchema, Path, 'post'>,
          undefined
        >['pagination']['orderBy']
      >
      filterBy?: MaybeRefOrGetter<
        Exclude<RequestBody<ApiSchema, Path, 'post'>, undefined>['params']
      >
      perPage: number
      immediate?: boolean
    }
  : never

export type UseInfiniteListReturn<Path extends keyof ApiSchema> = ResponseBody<
  ApiSchema,
  Path,
  'post'
> extends PaginatedRouteResponseBody
  ? {
      items: Ref<
        Exclude<ResponseBody<ApiSchema, Path, 'post'>, undefined>['data']
      >
      total: Ref<undefined | number>
      canLoadMore: Ref<boolean>
      loadMore: () => Promise<void>
      isReady: Ref<boolean>
      isLoading: Ref<boolean>
      error: Ref<unknown>
      isReadyFirstChunk: Ref<boolean>
      reset: () => void
    }
  : never

export function useInfiniteList<Path extends keyof ApiSchema>({
  url,
  orderBy,
  filterBy,
  perPage,
  immediate = true,
}: UseInfiniteListParams<Path>): UseInfiniteListReturn<Path> {
  const stFetch = useRawStFetch()
  const total = ref<number | undefined>(0)
  const currentPage = ref<number>(0)

  const items: Ref<unknown[]> = ref([])

  const error = ref<unknown>()
  const isLoading = ref(false)
  const isReady = computed(() => !isLoading.value)
  const isReadyFirstChunk = ref(false)

  const canLoadMore = computed(
    () =>
      !isLoading.value &&
      !error.value &&
      (total.value === undefined || total.value > items.value.length),
  )

  async function loadMore() {
    isLoading.value = true

    const { data: response, error: errorResponse } = await stFetch(
      toValue(url),
      {
        method: 'post',
        body: {
          params: toValue(filterBy) ?? {},
          pagination: {
            page: currentPage.value,
            perPage,
            orderBy: toValue(orderBy),
          },
        },
      } as any,
    )
    isLoading.value = false
    if (errorResponse) {
      error.value = errorResponse
      return
    }
    const { data, paging } = response as {
      data: []
      paging: {
        total: number
        count: number
        page: number
      }
    }

    items.value.push(...data)
    total.value = paging.total
    currentPage.value = paging.page + 1
    isReadyFirstChunk.value = true
  }

  function reset() {
    total.value = undefined
    currentPage.value = 0
    items.value = []
    error.value = undefined
    isLoading.value = false
    isReadyFirstChunk.value = false
    if (immediate) loadMore()
  }

  if (immediate) loadMore()

  watch(
    () => [toValue(filterBy), toValue(orderBy)],
    () => {
      reset()
    },
    {
      deep: true,
    },
  )

  return {
    items,
    total,
    canLoadMore,
    loadMore,
    isLoading,
    isReady,
    error,
    isReadyFirstChunk,
    reset,
  } as any
}
