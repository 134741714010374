<template>
  <div v-if="activeAccount" class="user-actions" data-t="user-actions-19b7">
    <button class="account" @click="isShownAccounts = true">
      <span class="account-label" data-t="account-label-c1p6">
        {{ t('header.balance', { currency: activeAccount.code }) }}
        <StIcon name="chevron-down" :size="12" />
      </span>
      <span class="account-value" data-t="account-value-mpmy">
        <StIcon
          class="account-value-icon"
          :name="activeAccount.icon"
          :size="16"
        />
        <span class="account-value-text">{{ text }}</span>
      </span>
    </button>
    <NuxtI18nLink
      class="deposit-button"
      data-t="deposit-button-9cll"
      replace
      :to="{ query: { modal: 'payments', tab: 'deposit' } }"
    >
      <span class="deposit-icon" :class="{ ghost: !isDefaultHeaderColor }">
        <StIcon name="plus-large" :size="16" />
      </span>
    </NuxtI18nLink>
    <StDrawer v-model="isShownAccounts" :full-screen="false">
      <StDrawerHeader
        :title="t('header.activeAccount')"
        @close="isShownAccounts = false"
      />
      <UserAccount
        type="mobile"
        hide-header
        @account-select="isShownAccounts = false"
      />
    </StDrawer>
  </div>
</template>

<script setup lang="ts">
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'

const { t } = useI18n()
const { activeAccount } = storeToRefs(useAccountsStore())
const { format } = useCryptoFormatter({
  maximumSignificantDigits: 10,
})
const isShownAccounts = ref(false)
const { isDefaultHeaderColor } = useMobileLayout()

const route = useRoute()
const text = computed(() =>
  route.meta.inGame ? t('header.inGame') : format(activeAccount.value.balance),
)
</script>

<style scoped>
.account {
  all: unset;

  cursor: pointer;
  user-select: none;

  flex: 1 1 0;

  min-width: 0;
  margin-left: var(--spacing-200);
}

.user-actions {
  display: flex;
  flex: 1 1 0;
  align-items: center;
  min-width: 0;
}

.account-label {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  justify-content: flex-end;

  font: var(--mobile-caption-2-medium);
  color: var(--text-tertiary);
}

.account-value {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: flex-end;

  margin-top: var(--spacing-025);

  font: var(--mobile-text-medium);
}

.account-value-icon {
  flex-shrink: 0;
}

.account-value-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.deposit-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  color: var(--text-primary);
}

.deposit-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  background: var(--button-gray-default);
  border-radius: var(--border-radius-full);

  &.ghost {
    background: var(--background-ghost-secondary);
  }
}
</style>
