import {
  mountBackButton,
  isTMA,
  showBackButton,
  hideBackButton,
  isBackButtonVisible,
  onBackButtonClick,
  offBackButtonClick,
} from '@telegram-apps/sdk-vue'

export default defineNuxtPlugin({
  order: 2,
  name: 'telegramBackButtonWatcher',
  setup() {
    const route = useRoute()
    const router = useRouter()

    if (mountBackButton.isAvailable()) {
      mountBackButton()
    }

    function checkIsMainPage() {
      return /^\/([a-z]{2}\/)?(mobile\/?)?$/.test(route.path)
    }

    function backButtonListener() {
      router.back()
    }

    onNuxtReady(() => {
      watch(
        () => route.path,
        () => {
          if (!isTMA()) return
          if (checkIsMainPage()) {
            hideBackButton()
            offBackButtonClick(backButtonListener)
            return
          }
          if (!isBackButtonVisible()) {
            showBackButton()
            onBackButtonClick(backButtonListener)
          }
        },
        {
          immediate: true,
        },
      )
    })
  },
})
