<template>
  <ClientOnly v-if="!userCurrencyCode">
    <StSkeletonLoader
      :shimmer-opacity="0.32"
      :width="mobile ? 'auto' : '200px'"
      :height="mobile ? '52px' : '36px'"
    />
  </ClientOnly>
  <div v-else class="currency-widget" :class="{ mobile }">
    <div class="label">
      <StTooltip
        v-model="isOpenTooltip"
        :trigger="mobile ? 'click' : 'hover'"
        :placement="mobile ? 'top' : 'bottom'"
        background="container-secondary"
      >
        <template #activator>
          {{ t('casinoGamePage.gameCurrency') }}
          <StIcon name="circle-info-solid" size="16" class="info-icon" />
        </template>
        <div class="tooltip-wrapper">
          <h3 class="tooltip-title">
            {{
              t('casinoGamePage.gameCurrencyTooltipTitle', {
                gameCurrencyCode,
                userCurrencyCode,
              })
            }}
          </h3>
          <p class="tooltip-body">
            {{ t('casinoGamePage.gameCurrencyTooltipBody') }}
          </p>
        </div>
      </StTooltip>
    </div>
    <div class="icons">
      <div class="currency">
        <StIcon :name="userCurrncyIcon" size="16" />
        <span>{{ userCurrencyCode }}</span>
      </div>
      <StIcon name="arrow-right" :size="16" class="arrow" />
      <div class="currency">
        <StIcon :name="gameCurrencyIcon" size="16" />
        <span class="currency">{{ gameCurrencyCode }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { IconName } from '@st/ui/components/StIcon/types'
import { useCasinoDeps } from '@st/casino/useDeps'
import { delay } from '@st/utils'

const props = defineProps<{
  mobile?: boolean
}>()

const { userSettings, activeAccount } = useCasinoDeps()
const { t } = useI18n()

const isOpenTooltip = ref(false)

onMounted(async () => {
  if (props.mobile || localStorage.getItem('haveSeenGameCurrencyTooltip'))
    return

  isOpenTooltip.value = true
  localStorage.setItem('haveSeenGameCurrencyTooltip', 'true')
  await delay(6000)
  isOpenTooltip.value = false
})

const gameCurrencyCode = computed(
  () => userSettings.value?.preferredCurrencyCodeForCasino ?? 'USD',
)

const gameCurrencyIcon = computed<IconName>(() => {
  const iconByCurrency: Record<string, IconName> = {
    USD: 'c-dollar-symbol',
    EUR: 'c-euro-symbol',
  }
  return (
    iconByCurrency[gameCurrencyCode.value.toUpperCase()] ?? 'c-dollar-symbol'
  )
})

const userCurrencyCode = computed(() => activeAccount.value?.code)
const userCurrncyIcon = computed(() => activeAccount.value?.icon)
</script>

<style scoped>
.currency-widget {
  display: flex;
  gap: var(--spacing-125);
  align-items: center;
}

.label {
  cursor: pointer;

  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);

  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--icon-primary);
  }

  .info-icon {
    margin-left: var(--spacing-050);

    &:hover {
      color: var(--icon-primary);
    }
  }
}

.icons {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  padding: var(--spacing-100) var(--spacing-125);

  background: var(--background-base);
  border-radius: var(--border-radius-100);
}

.currency {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  font: var(--desktop-text-sm-medium);
}

.arrow {
  color: var(--text-secondary);
}

.tooltip-wrapper {
  max-width: 260px;
  padding: var(--spacing-150) var(--spacing-200);
}

.tooltip-title {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
}

.tooltip-body {
  margin: 0;
  margin-top: var(--spacing-075);
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
}

.currency-widget.mobile {
  justify-content: space-between;
  padding: var(--spacing-075) var(--spacing-150);
  border: 1px solid var(--border-primary);
  border-radius: var(--border-radius-150);

  .label {
    font: var(--mobile-text-medium);
    color: var(--text-primary);
  }

  .info-icon {
    color: var(--icon-tertiary);
  }

  .currency {
    font: var(--mobile-headline-semibold);
  }
}
</style>
