<template>
  <div class="overlay" :class="{ mobile: isMobile }">
    <div class="checked">
      <svg
        :width="isMobile ? 16 : 28"
        :height="isMobile ? 16 : 28"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="checkmark"
      >
        <path
          class="checkmark-stroke"
          d="M4.86658 14.7131L10.6102 20.4168L23.5332 7.5835"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isMobile } = usePlatform()
</script>

<style scoped>
.checked {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 52px;
  height: 52px;
  padding: var(--spacing-100);

  background-color: var(--icon-brand);
  backdrop-filter: blur(20px);
  border-radius: var(--border-radius-full);

  animation: fade-scale 0.4s ease-in;
}

.checkmark-stroke {
  fill: none;
  stroke: white;
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;

  animation: draw-check 0.3s forwards;
  animation-delay: 0.2s;
}

.overlay {
  position: absolute;
  z-index: 100;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background: rgb(0 0 0 / 50%);
  border-radius: var(--border-radius-100);
}

.mobile {
  .checked {
    width: 32px;
    height: 32px;
    padding: var(--spacing-050);
  }

  .checkmark {
    width: 16px;
    height: 16px;
  }
}

@keyframes fade-scale {
  0% {
    transform: scale(0.15);
  }

  35% {
    transform: scale(1.2);
  }

  48% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes draw-check {
  0% {
    stroke-dashoffset: 50;
  }

  100% {
    stroke-dashoffset: 0;
  }
}
</style>
