import type { IconName } from '@st/ui/components/StIcon/types'

const [useQuestsDeps, provideQuestsDeps] = createEntityDeps<{
  getCurrencyIcon: (currencyCode: string) => IconName
  connectTelegramMiniApp: () => Promise<void>
  isTelegramMiniApp: Ref<boolean>
  TelegramAuthButton: Component<{
    authParams: {
      language: any
      referralCode?: string
      promocodeName?: string
    }
    disabled?: boolean
  }>
  isAuthenticated: Ref<boolean>
  openLogin: () => Promise<void> | void
  tryToAuthenticate: () => Promise<void>
  isReadyUser: Ref<boolean>
  installPwa: () => Promise<void>
}>('quests')

export { useQuestsDeps, provideQuestsDeps }
