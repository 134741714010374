import type { LocationQuery } from 'vue-router'
import { useVerificationStore } from '../stores/useVerificationStore'

export function useVerificationModals() {
  const verificationStore = useVerificationStore()
  const { fetchUserVerification } = verificationStore
  const { userVerification } = storeToRefs(verificationStore)

  async function showVerificationDepositModal(query: LocationQuery) {
    await fetchUserVerification()

    if (!userVerification.value) {
      return undefined
    }
    if (
      (['userWait', 'adminWait', 'adminInProgress'].includes(
        userVerification.value.status,
      ) &&
        userVerification.value.deposit) ||
      userVerification.value.status === 'rejected'
    ) {
      return navigateTo({ query: { ...query, modal: 'verificationKyc' } })
    }
    return undefined
  }

  async function changeRoute(path: 'verification' | 'biometric') {
    await navigateTo({ query: { modal: path } })
  }

  async function showVerificationReminderModals() {
    await fetchUserVerification()

    if (!userVerification.value) return

    const { status, required, type } = userVerification.value

    if (status === 'userWait' && required) {
      if (type === 'actionLiveness') {
        await changeRoute('biometric')
      } else {
        await changeRoute('verification')
      }
    }
  }

  return { showVerificationDepositModal, showVerificationReminderModals }
}
