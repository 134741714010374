import { use2FaStore } from '../stores/use2FaStore'

export function useUser2FaChannels() {
  const faStore = use2FaStore()
  const { fetchChannels } = faStore
  const { channels, isReady } = storeToRefs(faStore)

  const emailChannel = computed(() =>
    channels.value.find(({ channel }) => channel === 'email'),
  )
  const telegramChannel = computed(() =>
    channels.value.find(({ channel }) => channel === 'telegram'),
  )

  const activeChannel = ref<'email' | 'telegram'>()
  const stFetch = useRawStFetch()
  const toast = useToast()
  const { t } = useNuxtApp().$i18n

  async function setActiveChannel(channel: 'email' | 'telegram') {
    activeChannel.value = channel

    const { error } = await stFetch('/user/communication/channel/active/set', {
      method: 'post',
      body: { channel },
    })

    if (error) {
      toast.open({
        type: 'negative',
        label: t('profile.providerCreateError'),
      })
    }
  }

  watchEffect(() => {
    activeChannel.value = channels.value.find(({ active }) => active)?.channel
  })

  return {
    fetchChannels,
    setActiveChannel,
    activeChannel,
    channels,
    emailChannel,
    telegramChannel,
    isReady,
  }
}
