<template>
  <div class="st-copy" :class="classes" @click="copy">
    <div v-if="!hideValueDisplay" class="clipboard">
      <slot>{{ value }}</slot>
    </div>
    <StTooltip trigger="hover" placement="top">
      <template #activator>
        <StIcon
          data-t="activator"
          class="icon-activator"
          :name="isCopied ? 'check' : copyIcon"
          :size="iconSize"
        />
      </template>
      <span class="tooltip" data-t="clipboard-tooltip">
        {{ tooltipMesage }}
      </span>
    </StTooltip>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '../StIcon/types'

interface Props {
  value: string | number
  tooltip?: string
  successToast?: string
  iconSize?: number | string
  type?: 'default' | 'primary'
  copyIcon?: Exclude<IconName, 'check'>
  hideValueDisplay?: boolean
}

const { t } = useI18n()

const {
  value,
  tooltip,
  successToast,
  iconSize = 16,
  type = 'default',
  copyIcon = 'copy-solid',
} = defineProps<Props>()

const tooltipMesage = computed(() => tooltip || t('coupon.copy'))

const { copy, isCopied } = useCopyWithToast(
  computed(() => String(value)),
  computed(() => successToast || t('coupon.copySuccess')),
)

const classes = computed(() => [type])
</script>

<style scoped>
.icon-activator {
  cursor: pointer;
  color: var(--text-tertiary);
}

.st-copy {
  cursor: pointer;
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  &:hover {
    color: var(--text-primary);

    .icon-activator {
      color: var(--text-primary);
    }
  }

  &.primary {
    .icon-activator {
      color: var(--icon-brand);
    }

    &:hover {
      .icon-activator {
        color: #dd6a28; /* stylelint-disable-line */
      }
    }
  }
}

.clipboard {
  overflow: hidden;
  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100) var(--spacing-150);

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
}
</style>
