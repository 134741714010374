/**
 * Возвращает ссылку на хелпцентер с допустимой локалью
 * и email поддержки
 */

interface SupportUrlReturn {
  /**
   * Ссылка на help центр
   */
  supportUrl: ComputedRef<string>
  supportEmail: string
  supportEmailLink: string
  /**
   * Принимает путь к статье, отдает полную ссылку в help центр
   * @example
   * createSupportLink('/acticles/123') // 'https://help.betone.com/articles/123'
   */
  createSupportLink: (path: string) => string
}

export function useSupportUrl(): SupportUrlReturn {
  const availableLocales = ['ru', 'en']
  const { locale } = useI18n()
  const supportCenter = 'https://help.betone.com/'

  const supportUrl = computed(() =>
    availableLocales.includes(locale.value)
      ? `${supportCenter}${locale.value}`
      : `${supportCenter}en`,
  )

  const supportEmail = 'support@betone.com'
  const supportEmailLink = `mailto:${supportEmail}`

  function createSupportLink(path: string) {
    const preparedPath = path.startsWith('/') ? path.slice(1) : path
    const preparedSupportUrl = supportUrl.value.endsWith('/')
      ? supportUrl.value
      : `${supportUrl.value}/`

    return `${preparedSupportUrl}${preparedPath}`
  }

  return { supportUrl, supportEmail, supportEmailLink, createSupportLink }
}
