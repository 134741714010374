import { useIntercomScript } from './useIntercomScript'

export function useIntercomApi() {
  const { intercom } = useIntercomScript()

  /* 
    механизм ручной загрузки скрипта
    можно вызывать toogleChat для открытия чата
    при первом вызове будет загружен скрипт
  */
  const isChatOpened = ref(false)
  intercom.onLoaded(() => {
    globalThis.Intercom('onHide', () => {
      isChatOpened.value = false
    })
  })

  const isLoading = computed(() => intercom.status.value === 'loading')

  async function toogleChat() {
    await intercom.load()
    intercom.onLoaded(() => {
      if (isChatOpened.value) {
        globalThis.Intercom('hide')
      } else {
        globalThis.Intercom('show')
      }
      isChatOpened.value = !isChatOpened.value
    })
  }

  return {
    toogleChat,
    isLoading,
  }
}
