import { useUserStore } from '../stores/useUserStore'

export function useUserAuthProviders() {
  const userStore = useUserStore()
  const { getAuthProvidersList } = userStore
  const { authProvidersList } = storeToRefs(userStore)

  const hasEmailProvider = computed(
    () => authProvidersList.value?.find((item) => item.provider === 'email'),
  )
  const hasGoogleProvider = computed(
    () => authProvidersList.value?.find((item) => item.provider === 'google'),
  )
  const hasTelegramProvider = computed(
    () => authProvidersList.value?.find((item) => item.provider === 'telegram'),
  )
  const hasAnyProvider = computed(
    () =>
      hasEmailProvider.value ||
      hasGoogleProvider.value ||
      hasTelegramProvider.value,
  )

  return {
    providers: authProvidersList,
    hasEmailProvider,
    hasGoogleProvider,
    hasTelegramProvider,
    hasAnyProvider,
    getAuthProvidersList,
  }
}
