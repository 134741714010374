import { usePragmaticDGAStore } from '../stores/usePragmaticDGAStore'

export function usePragmaticDGA(id: MaybeRefOrGetter<number | undefined>) {
  const { getGameExternalId, subscribeGame } = usePragmaticDGAStore()
  const { gamesLimits } = storeToRefs(usePragmaticDGAStore())

  const gameExternalId = computed(() => {
    const currentId = toValue(id)
    return currentId ? getGameExternalId(currentId) : undefined
  })
  const isGameHasDGA = computed(() => !!gameExternalId.value)

  const limits = computed(() => {
    if (!gameExternalId.value) return undefined

    return gamesLimits.value[gameExternalId.value]
  })

  const isGameHasLimits = computed(
    () =>
      !!limits.value &&
      Object.values(limits.value).some((i) => i !== undefined),
  )

  watchEffect(() => {
    if (isGameHasDGA.value && gameExternalId.value) {
      subscribeGame(gameExternalId.value)
    }
  })

  return {
    limits,
    isGameHasLimits,
  }
}
