<template>
  <div class="reward-container" :class="`size-${size}`">
    <span class="amount">{{ rewardAmount }}</span>
    <StIcon :name="referralCurrency?.icon" size="16" />
  </div>
</template>
<script setup lang="ts">
import { useReferralBonus } from '../useReferralBonus'

const { size = 'm' } = defineProps<{
  size?: 'm' | 'l'
}>()

const { rewardAmount, referralCurrency } = useReferralBonus()
</script>

<style scoped>
.reward-container {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  padding: var(--spacing-025) var(--spacing-025) var(--spacing-025)
    var(--spacing-050);

  background: linear-gradient(270deg, #ebac2f 0%, #fad231 100%);
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 16%));
  border-radius: var(--border-radius-full);
}

.amount {
  font: var(--mobile-text-semibold);

  color: #792d01; /* stylelint-disable-line */
}

.size-l {
  &.reward-container {
    padding: 0 var(--spacing-050) 0 var(--spacing-075);
  }

  .amount {
    font: var(--desktop-text-md-semibold);
  }
}
</style>
