import type {
  UserVerification,
  VerificationActiveLevelState,
  UserVerificationTypeCreate,
} from '../components/types'
import { useVerificationDeps } from '../useDeps'

export const useVerificationStore = defineStore('verification', () => {
  const stFetch = useRawStFetch()
  const { $i18n } = useNuxtApp()
  const { t } = $i18n
  const io = useSocket()
  const { userKycLevel, setKycLevel } = useVerificationDeps()

  const currentKycLevel = computed(() => userKycLevel.value ?? 0)
  const userVerification = ref<UserVerification | null>(null)

  async function fetchUserVerification() {
    const { data, error } = await stFetch('/user-verification/active/get', {
      method: 'post',
    })
    if (error) {
      console.error(error)
      return
    }
    // @ts-expect-error удалить после обновления роута
    userVerification.value =
      data.status === 'approved' || data.status === 'canceled' ? null : data
  }

  async function createUserVerification(type: UserVerificationTypeCreate) {
    const { error } = await stFetch('/user-verification/create', {
      method: 'post',
      body: { type },
    })
    if (error) {
      console.error(error)
      return
    }
    await fetchUserVerification()
  }

  const isZeroLevel = computed(
    () =>
      currentKycLevel.value === 0 &&
      (!userVerification.value || userVerification.value.type === 'level2'),
  )

  const isZeroKycAndSecondVerification = computed(
    () =>
      currentKycLevel.value === 0 && userVerification.value?.type === 'level2',
  )

  const activeLevel = computed(() => {
    if (isZeroLevel.value) {
      return 2
    }
    if (
      !userVerification.value ||
      userVerification.value.type === 'actionLiveness'
    ) {
      return currentKycLevel.value + 1
    }
    if (userVerification.value.type === 'level1') {
      return 1
    }
    if (userVerification.value.type === 'level2') {
      return 2
    }

    return 3
  })

  const currentVerificationState = computed<VerificationActiveLevelState>(
    () => {
      if (!userVerification.value) {
        return {
          state: 'active',
        }
      }

      switch (userVerification.value.status) {
        case 'adminWait':
        case 'adminInProgress': {
          return {
            state: 'pending',
          }
        }
        case 'userWait': {
          return {
            state: 'yellow',
            message: t('verification.pressButtonVerification'),
          }
        }
        case 'rejected': {
          return {
            state: 'red',
            message: t('verification.contactSupport'),
          }
        }
        default: {
          return {
            state: 'active',
          }
        }
      }
    },
  )

  io.on('userKycLevelSet', async ({ kycLevel }) => {
    setKycLevel(kycLevel)
    await fetchUserVerification()
  })

  return {
    userVerification,
    activeLevel,
    currentVerificationState,
    currentKycLevel,
    isZeroLevel,
    isZeroKycAndSecondVerification,
    fetchUserVerification,
    createUserVerification,
  }
})
