import { delay } from '@st/utils'
import { useIpqs } from '@st/ipqs/composables/useIpqs'
import { useIntercomStore } from '@st/intercom/stores/useIntercomStore'
import { useAnalytics } from '@st/analytics/composables/useAnalytics'

export interface User {
  externalId: string
  createdAt: string
  email: string
  nickname: string
  kycLevel: number
  avatarUrl: string
  intercomHash: string
  referralCode: string
  customerSegment: number
}

export interface UserSettings {
  clearCouponAfterBet: boolean
  acceptChangeBetRate: 'up' | 'any' | 'never'
  acceptChangeBetCashOutAmount: 'up' | 'any' | 'never'
  language: string
  preferredAccountType: 'real' | 'bonus' | 'freebet'
  preferredCurrencyCode: string
  preferredCurrencyCodeForCasino: string
  showNicknameOnMainPage: boolean
  showZeroAccounts: boolean
  agreedGetPromoNewsByEmail: boolean
  agreedGetPromoNewsByTelegram: boolean
  agreedGetSystemNotificationsByTelegram: boolean
  preferredCountryCodeForCurrencyExchange: string
}

interface UserSettingUpdateParam {
  name: keyof UserSettings
  value: UserSettings[keyof UserSettings]
}

interface AuthProvider {
  provider: 'email' | 'google' | 'telegram'
  externalId: string | null
}

export const useUserStore = defineStore('user', () => {
  const { setIntercomUserData, closeChatSession } = useIntercomStore()
  const stFetch = useRawStFetch()
  const app = useNuxtApp()
  const { sendVariable } = useAnalytics()

  const user = ref<User | null>(null)
  const userSettings = ref<UserSettings | null>(null)
  const isAuthenticated = computed(() => !!user.value)
  const isReady = ref<boolean>(false)
  const isManualLogin = ref(false)
  const isJustRegisteredUser = ref(false)
  const authProvidersList = ref<AuthProvider[]>([])

  async function fetchUserSettings() {
    const { data } = await stFetch('/user-settings/get', {
      method: 'post',
    })
    if (!data) return
    userSettings.value = data
  }

  async function updateUserSettings(
    data: UserSettingUpdateParam[],
  ): Promise<void> {
    await stFetch('/user-settings/set', { method: 'post', body: data })
    await fetchUserSettings()
  }

  async function socketReconnect() {
    if (!app.$io) return

    app.$io.disconnect()
    await delay(150)
    app.$io.connect()
  }

  function updateUserLanguage() {
    if (
      userSettings.value?.language &&
      app.$i18n.locale.value !== userSettings.value.language
    ) {
      updateUserSettings([{ name: 'language', value: app.$i18n.locale.value }])
    }
  }

  async function getAuthProvidersList() {
    const { data } = await stFetch('/user/auth/provider/list', {
      method: 'post',
    })

    if (data) {
      authProvidersList.value = data
    }
  }

  const wasAuthenticated = useCookie('wasAuthenticated', {
    default: () => false,
  })

  const { sendFingerPrintData } = useIpqs()

  /**
   * Обрабатывает успешный логин пользователя.
   * Это касается успешного прохождения реги/авторизации
   * и автоматического логина при повторном заходе на сайт
   */
  async function handleLogin(userData: User) {
    user.value = userData
    isReady.value = true
    await fetchUserSettings()
    await socketReconnect()
    setIntercomUserData(userData)
    sendFingerPrintData(userData.externalId)
    updateUserLanguage()
    await getAuthProvidersList()
    wasAuthenticated.value = true
    sendVariable('userId', user.value.externalId)
  }

  /**
   * При повторном заходе на сайт у юзера уже может стоять кука авторизации.
   * Но мы никак не можем проверить ее наличие - она http-only.
   * Поэтому этот метод пробует запросить данные юзера.
   * Если запрос прошел - значит юзер авторизован, иначе нет.
   */
  async function tryToAuthenticate() {
    const { data } = await stFetch('/user', { method: 'post' })
    if (data) {
      await handleLogin(data)
    }
    isReady.value = true
  }

  async function refreshUserData() {
    const { data, error } = await stFetch('/user', { method: 'post' })
    if (error) {
      console.error('Failed to refresh user data:', error)
      return
    }
    user.value = data
  }

  function setKycLevel(level: number) {
    if (user.value) {
      user.value.kycLevel = level
    }
  }

  function resetUserData() {
    user.value = null
    userSettings.value = null
    isManualLogin.value = false
    closeChatSession()
  }

  const router = useRouter()

  interface HandleFullLogoutParams {
    withoutRedirect: boolean
  }

  async function handleFullLogout(params?: HandleFullLogoutParams) {
    resetUserData()
    await socketReconnect()
    if (!params?.withoutRedirect) {
      router.push('/')
    }
  }
  async function logout() {
    await stFetch('/auth/logout', { method: 'post' })
    handleFullLogout()
  }

  return {
    authProvidersList,
    user,
    isReady,
    isManualLogin,
    isAuthenticated,
    userSettings,
    isJustRegisteredUser,
    setKycLevel,
    handleLogin,
    updateUserSettings,
    logout,
    resetUserData,
    fetchUserSettings,
    refreshUserData,
    handleFullLogout,
    tryToAuthenticate,
    getAuthProvidersList,
  }
})
