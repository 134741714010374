import { useIntercomScript } from '../composables/useIntercomScript'

interface User {
  externalId: string
  createdAt: string
  email: string
  nickname: string
  kycLevel: number
  avatarUrl: string
  intercomHash: string
  customerSegment: number
}

export const useIntercomStore = defineStore('intercom', () => {
  const config = useRuntimeConfig()
  const apiKey = config.public.intercomKey as string
  const state = ref<'pending' | 'ready' | 'idle'>('idle')
  const isChatInitedWithUser = ref(false)
  const app = useNuxtApp()

  const userData = ref<User | null>(null)

  const {
    intercom: { status: scriptStatus },
  } = useIntercomScript()

  async function initDefaultChat() {
    if (isChatInitedWithUser.value) return

    try {
      state.value = 'pending'

      globalThis.Intercom?.('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: apiKey,
        custom_launcher_selector: '#intercomButton',
        language_override: app.$i18n?.locale.value ?? 'en',
      })
    } catch (error) {
      console.error('Intercom boot error', error)
    } finally {
      state.value = 'ready'
    }
  }

  function setIntercomUserData(data: User) {
    userData.value = data
  }
  async function initUserChat({
    externalId,
    createdAt,
    intercomHash,
    nickname,
    customerSegment,
  }: User) {
    isChatInitedWithUser.value = true
    try {
      state.value = 'pending'

      globalThis.Intercom?.('boot', {
        api_base: 'https://api-iam.intercom.io',
        custom_launcher_selector: '#intercomButton',
        app_id: apiKey,
        name: nickname,
        user_id: externalId,
        created_at: createdAt,
        user_hash: intercomHash,
        language_override: app.$i18n?.locale.value ?? 'en',
        customer_segment: customerSegment,
      })
    } catch (error) {
      console.error('Intercom boot user error', error)
    } finally {
      state.value = 'ready'
    }
  }

  function updateChatSession(data?: User | null) {
    if (state.value === 'idle') return

    const { externalId, createdAt, intercomHash, nickname, customerSegment } =
      data ?? {}

    try {
      if (intercomHash) {
        globalThis.Intercom?.('update', {
          api_base: 'https://api-iam.intercom.io',
          custom_launcher_selector: '#intercomButton',
          app_id: apiKey,
          name: nickname,
          user_id: externalId,
          created_at: createdAt,
          user_hash: intercomHash,
          language_override: app.$i18n?.locale.value ?? 'en',
          customer_segment: customerSegment,
        })
      } else {
        globalThis.Intercom?.('update')
      }
    } catch (error) {
      console.error('Intercom update error', error)
    }
  }

  function closeChatSession() {
    try {
      globalThis.Intercom?.('shutdown')
      isChatInitedWithUser.value = false
      userData.value = null
      updateChatSession()
      initDefaultChat()
    } catch (error) {
      console.error('Intercom shutdown error', error)
    }
  }

  const apiEventBus = useApiEventBus()
  const isForbiddenByCountry = ref(false)
  apiEventBus.on('responseError', (context) => {
    // eslint-disable-next-line no-underscore-dangle
    const response = context.response?._data ?? {}

    if (response.error !== 'FORBIDDEN_COUNTRY') return

    isForbiddenByCountry.value = true
    closeChatSession()
  })

  async function initChat() {
    if (userData.value && !isForbiddenByCountry.value) {
      initUserChat(userData.value)
    } else {
      initDefaultChat()
    }
  }

  watch(
    () => userData.value,
    (newData) => {
      if (newData && scriptStatus.value === 'loaded') {
        globalThis.Intercom?.('shutdown')
        initChat()
        updateChatSession(newData)
      }
    },
  )

  watch(
    () => scriptStatus.value,
    (newStatus) => {
      if (newStatus === 'loaded') {
        initChat()
      }
    },
  )

  const route = useRoute()
  watch(
    () => route.path,
    () => {
      updateChatSession()
    },
  )

  return {
    setIntercomUserData,
    updateChatSession,
    closeChatSession,
    initChat,
  }
})
