import { filterObjectByFilledValues } from '@st/utils'
import type { TelegramAuthParams, AuthEndpoint } from '../types'

type AuthState = 'idle' | 'loading' | 'error' | 'finished'
interface TelegramAuthWidgetReturn {
  status: Ref<AuthState>
  widgetId: Ref<string>
  handleTelegramAuth: (
    errorHandler?: (error?: string) => void,
  ) => Promise<unknown>
  removeHandler: () => void
}

/* 
  хук для работы с кастомным вызовом виджета телеграма (через iframe)
  слушает postMessage и обновляет стейты с запросами на бэк
*/
export function useTelegramAuthWidget(
  params: MaybeRefOrGetter<TelegramAuthParams>,
  endpoint: AuthEndpoint = '/auth/telegram',
): TelegramAuthWidgetReturn {
  const config = useRuntimeConfig()
  const { telegramLoginWidgetUrl } = config.public

  const stFetch = useRawStFetch()
  const status = ref<AuthState>('idle')
  const widgetId = ref(Math.random().toString(36).substring(2))
  const filteredParams = filterObjectByFilledValues(toValue(params))

  // Переменная для хранения обработчика сообщений ( чтобы можно было отписаться от него )
  let handleMessage: (event: MessageEvent) => Promise<void>

  /**
   * Функция для обработки авторизации через Telegram виджет.
   * Слушает сообщения от iframe виджета Telegram и обрабатывает их, обновляя состояние авторизации.
   *
   * @param {function} [errorHandler] - Необязательная функция для обработки ошибок.
   * Так как эта функция своего рода вотчер, не всегда можно завязаться на reject.
   * Поэтому в случае ошибки, можно передать сюда функцию, которая будет вызвана при ошибке.
   *
   * @returns {Promise<unknown>} - Возвращает Promise, который разрешается с данными авторизации
   * (например, `tgData`) или отклоняется в случае ошибки.
   *
   * @example
   * const { handleTelegramAuth } = useTelegramAuthWidget(params, endpoint);
   */
  function handleTelegramAuth(errorHandler?: (error?: string) => void) {
    return new Promise((resolve, reject) => {
      if (import.meta.server) {
        reject()
        return
      }

      // Функция для обработки ответа
      handleMessage = async (event: MessageEvent) => {
        // Проверка на безопасность (обрабатываем сообщения только от сервиса авторизации)
        if (
          !telegramLoginWidgetUrl.startsWith(event.origin) ||
          event.data?.widgetId !== widgetId.value
        )
          return

        // Начало авторизации
        if (event.data?.type === 'startAuth') {
          status.value = 'loading'
        }

        // Если получили tgData
        if (event.data?.type === 'tgData') {
          status.value = 'finished'

          const { error } = await stFetch(endpoint, {
            method: 'post',
            body: {
              ...filteredParams,
              tgData: event.data.tgData,
            },
          })

          if (error) {
            errorHandler?.(error.error)
          } else {
            window.removeEventListener('message', handleMessage)
            resolve(event.data)
          }
        }

        if (event.data.type === 'error') {
          status.value = 'error'
          errorHandler?.()
        }
      }

      // Подписываемся на сообщения postMessage
      window.addEventListener('message', handleMessage)
    })
  }

  function removeHandler() {
    if (handleMessage) {
      window.removeEventListener('message', handleMessage)
    }
  }

  return {
    status,
    widgetId,
    handleTelegramAuth,
    removeHandler,
  }
}
