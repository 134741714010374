import { useUserStore } from '@st/user/stores/useUserStore'
import { useTagsStore } from '@st/user/stores/useTagsStore'
import { provideVerificationDeps } from '@st/verification/useDeps'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import type { IconName } from '@st/ui/components/StIcon/types'
import { provideQuestsDeps } from '@st/quests/useDeps'
import { useCouponStore } from '@st/coupon/stores/useCouponStore'
import { provideBookmakerDeps } from '@st/bookmaker/useDeps'
import { providePwaDeps } from '@st/pwa/useDeps'
import { useTelegram } from '@st/telegram/composables/useTelegram'
import { provideBonusesDeps } from '@st/bonuses/useDeps'
import { provideProfileDeps } from '@st/profile/useDeps'
import { providePaymentsDeps } from '@st/payments/useDeps'
import { useCasinoStore } from '@st/casino/stores/useCasinoStore'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import { useConverterStore } from '@st/payments/stores/useConverterStore'
import { useBonusesCountStore } from '@st/bonuses/stores/useBonusesCountStore'
import RewardLabel from '@st/bonuses/components/ReferralBonus/parts/RewardLabel.vue'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import BetHistory from '@st/coupon/components/BetHistory/BetHistory.vue'
import MBetHistory from '@st/coupon/components/BetHistory/MBetHistory.vue'
import { useWebPushStore } from '@st/pwa/stores/useWebPushStore'
import { use2FaStore } from '@st/user/stores/use2FaStore'
import { useKycLevel } from '@st/user/composables/useKycLevel'
import { usePasswordValidator } from '@st/user/composables/usePasswordValidator'
import { useGoogleRedirect } from '@st/user/composables/useGoogleRedirect'
import { useUser2FaChannels } from '@st/user/composables/useUser2FaChannels'
import GameCard from '@st/casino/components/GameCard/GameCard.vue'
import TelegramAuthButton from '@st/telegram/components/TelegramAuthButton/TelegramAuthButton.client.vue'
import CasinoBetsHistory from '@st/casino/components/CasinoBetsHistory/CasinoBetsHistory.vue'
import { provideCasinoDeps } from '@st/casino/useDeps'
import { useGameInitStore } from '@st/casino/stores/useGameInitStore'
import MAccountWidget from '@st/payments/components/MAccountWidget/MAccountWidget.vue'
import PageTournamentCard from '@st/tournaments/components/TournamentCard/PageTournamentCard.vue'
import MPageTournamentCard from '@st/tournaments/components/TournamentCard/MPageTournamentCard.vue'
import { usePageTournament } from '@st/tournaments/composables/usePageTournament'
import { usePromotions } from '@st/promotions/composables/usePromotions'
import { useIntercomApi } from '@st/intercom/composables/useIntercomApi'
import { usePwaInstall } from '@st/pwa/composables/usePwaInstall'
import { useModalConfig } from '../modal.config'
import { useConnectTelegramMiniApp } from '../composables/useConnectTelegramMiniApp'

export default defineNuxtPlugin({
  order: 1,
  name: 'diContainer',
  dependsOn: ['i18n:plugin', 'i18n-base-init'],
  setup: () => {
    const { isTelegramMiniApp } = useTelegram()

    const userStore = useUserStore()
    const { setKycLevel } = userStore
    const {
      isAuthenticated,
      isReady: isReadyUser,
      user,
      userSettings,
    } = storeToRefs(userStore)
    const {
      logout,
      updateUserSettings,
      refreshUserData,
      getAuthProvidersList,
      tryToAuthenticate,
    } = userStore

    providePaymentsDeps({
      isTelegramMiniApp,
      isUserSettingsSet: computed(() => !!userSettings.value),
      updateUserPreferredCurrencyCode: (currencyCode) =>
        updateUserSettings([
          {
            name: 'preferredCurrencyCode',
            value: currencyCode,
          },
        ]),
    })

    const currencyStore = useCurrenciesStore()
    const { getCurrencyByCode, getCurrencyById } = currencyStore
    const { appCurrency } = toRefs(currencyStore)
    const { convert } = useConverterStore()
    const tagsStore = useTagsStore()
    const { hasTag } = tagsStore
    const { isReady: isReadyTags } = storeToRefs(tagsStore)

    const accountsStore = useAccountsStore()
    const { getAccountById } = accountsStore
    const { activeAccount } = storeToRefs(accountsStore)
    const { isAllAccountsEmpty } = storeToRefs(useAccountsStore())
    const { installPwa } = usePwaInstall()

    provideVerificationDeps({
      hasSkipKycLevelCheckOnDepositTag: computed(() =>
        hasTag('skipKycLevelCheckOnDeposit'),
      ),
      isAuthenticated,
      isReadyUser,
      isReadyTags,
      userKycLevel: computed(() => user.value?.kycLevel ?? null),
      appCurrencyCode: computed(() => appCurrency.value.code),
      getCurrencyIcon: (id: number) => getCurrencyById(id)?.icon as IconName,
      getFiatAmount: (currencyId: number, amount: string) =>
        convert(amount, { from: currencyId, to: appCurrency.value.id }),
      setKycLevel,
    })

    const router = useRouter()
    const route = useRoute()

    const { handleTelegramConnect } = useConnectTelegramMiniApp()

    const couponStore = useCouponStore()

    provideBookmakerDeps({
      hasOutcome: couponStore.hasOutcome,
    })

    provideQuestsDeps({
      connectTelegramMiniApp: async () => {
        await handleTelegramConnect()
      },
      isTelegramMiniApp,
      TelegramAuthButton,
      openLogin: () => {
        router.push({ query: { ...route.query, modal: 'login' } })
      },
      tryToAuthenticate,
      isAuthenticated,
      isReadyUser,
      getCurrencyIcon: (code: string) =>
        getCurrencyByCode(code)?.icon as IconName,
      installPwa,
    })

    const modals = useModalConfig()

    const casinoTournament = ref()
    onMounted(() => {
      const { tournament } = usePageTournament('casino')
      casinoTournament.value = tournament.value
    })

    provideCasinoDeps({
      useIntercomApi,
      isAuthenticated,
      isReady: isReadyUser,
      userSettings,
      MAccountWidget,
      activeAccount,
      appCurrency,
      isAllAccountsEmpty,
      casinoTournament,
      PageTournamentCard,
      MPageTournamentCard,
      getCurrencyIdByCode: (code: string) => getCurrencyByCode(code)?.id,
      getCurrencyIconById: (id: number) =>
        getCurrencyById(id)?.icon as IconName,
      gameCurrencyCode: (bet: any) =>
        getCurrencyById(bet.gameCurrencyId)?.code ?? 'USD',
    })

    const { getCategoryNameById } = useCasinoStore()
    const { isGameLoading } = storeToRefs(useGameInitStore())

    provideBonusesDeps({
      bonusesTooltips: {
        freespinTooltip: modals.value.freespinTooltip.component,
        freebetTooltip: modals.value.freebetTooltip.component,
        regularBonusTooltip: modals.value.regularBonusTooltip.component,
        depositBonusTooltip: modals.value.depositBonusTooltip.component,
        cashBonusTooltip: modals.value.cashBonusTooltip.component,
      },
      GameCard,
      isAuthenticated,
      referralCode: computed(() => user.value?.referralCode),
      appCurrencyCode: computed(() => appCurrency.value.code),
      getCurrencyIcon: (code: string) =>
        getCurrencyByCode(code)?.icon as IconName,
      getCurrencyIconById: (id: number) =>
        getCurrencyById(id)?.icon as IconName,
      getCurrencyCodeById: (id: number) => getCurrencyById(id)?.code as string,
      getCategoryNameById,
      getAccountById,
      getReferralCurrency: (id: number) => {
        const currency = getCurrencyById(id)
        if (!currency) return undefined

        return {
          icon: currency.icon,
          code: currency.code,
        }
      },
      convert,
      isGameLoading,
    })

    const { locale } = useNuxtApp().$i18n
    const webPushStore = useWebPushStore()
    const { init: initWebPush } = webPushStore
    const { isEnableNotifications, hasSubscription, isDisabledNotifications } =
      storeToRefs(webPushStore)
    const { bonusesCount } = storeToRefs(useBonusesCountStore())
    const { betCounter, isReady: isReadyActiveBets } =
      storeToRefs(useActiveBetsStore())
    const twoFaStore = use2FaStore()
    const { hasTelegram, hasEmail } = storeToRefs(twoFaStore)
    const { kycLevelName } = useKycLevel()
    const { passwordValidators } = usePasswordValidator()
    const {
      hasTelegramProvider,
      hasEmailProvider,
      hasGoogleProvider,
      providers,
    } = useUserAuthProviders()
    const { handleGoogleRedirect } = useGoogleRedirect()
    const {
      emailChannel,
      telegramChannel,
      activeChannel,
      isReady: isReadyChannels,
      fetchChannels,
      setActiveChannel,
    } = useUser2FaChannels()
    const { isEnabledPromotions } = usePromotions()

    provideProfileDeps({
      initWebPush,
      getCurrencyIconById: (id: number) =>
        getCurrencyById(id)?.icon as IconName,
      getCurrencyCodeById: (id: number) => getCurrencyById(id)?.code as string,
      logout,
      getAuthProvidersList,
      updateUserSettings,
      refreshUserData,
      handleGoogleRedirect: async () => {
        await handleGoogleRedirect(
          { language: locale.value },
          '/site/api/user/auth/google/redirect',
        )
      },
      fetchChannels,
      setActiveChannel,
      isEnableNotifications,
      isDisabledNotifications,
      hasSubscription,
      bonusesCount,
      betCounter,
      isReadyActiveBets,
      activeAccount,
      user,
      userSettings,
      isAuthenticated,
      hasTelegram,
      hasEmail,
      kycLevelName,
      passwordValidators,
      hasTelegramProvider: computed(() => !!hasTelegramProvider.value),
      hasEmailProvider: computed(() => !!hasEmailProvider.value),
      hasGoogleProvider: computed(() => !!hasGoogleProvider.value),
      isOnlyOneProvider: computed(() => providers.value.length === 1),
      isEmailChannel: computed(() => !!emailChannel.value),
      isTelegramChannel: computed(() => !!telegramChannel.value),
      isReadyChannels,
      activeChannel,
      isEnabledPromotions,
      RewardLabel,
      BetHistory,
      MBetHistory,
      CasinoBetsHistory,
    })

    providePwaDeps({
      isAuthenticated,
    })
  },
})
