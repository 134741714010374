import { type IconName } from '../StIcon/types'

export type CryptoIconType = 'colored' | 'grayscale' | 'transparent'
export type CurrencyCode =
  | 'bnb'
  | 'btc'
  | 'eth'
  | 'ltc'
  | 'pol'
  | 'sol'
  | 'ton'
  | 'trx'
  | 'usdc'
  | 'usdt'

export const iconsMap: Record<
  CryptoIconType,
  Record<CurrencyCode, IconName>
> = {
  colored: {
    bnb: 'c-crypto-bnb',
    btc: 'c-crypto-btc',
    eth: 'c-crypto-eth',
    ltc: 'c-crypto-ltc',
    pol: 'c-crypto-pol',
    sol: 'c-crypto-sol',
    ton: 'c-crypto-ton',
    trx: 'c-crypto-trx',
    usdc: 'c-crypto-usdc',
    usdt: 'c-crypto-usdt',
  },
  grayscale: {
    bnb: 'c-crypto-bnb-gs',
    btc: 'c-crypto-btc-gs',
    eth: 'c-crypto-eth-gs',
    ltc: 'c-crypto-ltc-gs',
    pol: 'c-crypto-pol-gs',
    sol: 'c-crypto-sol-gs',
    ton: 'c-crypto-ton-gs',
    trx: 'c-crypto-trx-gs',
    usdc: 'c-crypto-usdc-gs',
    usdt: 'c-crypto-usdt-gs',
  },
  transparent: {
    bnb: 'crypto-bnb-trsp',
    btc: 'crypto-btc-trsp',
    eth: 'crypto-eth-trsp',
    ltc: 'crypto-ltc-trsp',
    pol: 'crypto-pol-trsp',
    sol: 'crypto-sol-trsp',
    ton: 'crypto-ton-trsp',
    trx: 'crypto-trx-trsp',
    usdc: 'crypto-usdc-trsp',
    usdt: 'crypto-usdt-trsp',
  },
}
