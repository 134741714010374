export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    const router = useRouter()
    const {
      public: { sentry },
    } = useRuntimeConfig()

    if (!sentry.dsn) {
      console.error('NUXT_PUBLIC_SENTRY_DSN is missing!')
      return
    }

    import('@sentry/vue').then(({ init, browserTracingIntegration }) =>
      init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        release: import.meta.env.APP_VERSION,
        integrations: [browserTracingIntegration({ router })],
        ignoreErrors: [
          'TypeError: Object.fromEntries is not a function', // не поддерживаем устаревшие браузеры
          'Error: Network Error',
          'Error: Request aborted', // ошибка axios из sb
          'ReferenceError: globalThis is not defined', // не поддерживаем устаревшие браузеры
          'Fetch limits error', // couponLimits
          'Non-Error promise rejection captured', // не обработанные reject у Promise
        ],
        sampleRate: sentry.sampleRate,
        tracesSampleRate: sentry.tracesSampleRate,
        profilesSampleRate: sentry.profilesSampleRate,
      }),
    )
  },
})
