import { useCasinoDeps } from '../useDeps'

export const useFavoriteGamesStore = defineStore('favorite-games', () => {
  const stFetch = useRawStFetch()
  const { isMobile, isDesktop } = usePlatform()
  const { isAuthenticated } = useCasinoDeps()

  const { data: favoriteGames, execute: fetchFavoriteGames } = useStFetch(
    '/casino/game/favorite/find',
    {
      method: 'post',
      body: {
        params: {
          forMobile: isMobile.value || undefined,
          forDesktop: isDesktop.value || undefined,
        },
        pagination: {
          page: 0,
          perPage: 200,
          orderBy: [
            {
              fieldName: 'markedAsFavoriteAt',
              sortOrder: 'DESC',
            },
          ],
        },
      },
      immediate: false,
      watch: false,
      transform: (response) => response.data,
      default: () => [],
    },
  )

  const favoriteGamesIds = ref<Set<number>>(new Set([]))

  async function addFavoriteGame(gameId: number) {
    favoriteGamesIds.value.add(gameId)
    try {
      await stFetch('/user-favorite/casino-game/create', {
        method: 'post',
        body: {
          gameId,
        },
      })
    } catch (e) {
      favoriteGamesIds.value.delete(gameId)
      console.error(e)
    }
  }

  async function deleteFavoriteGame(gameId: number) {
    favoriteGamesIds.value.delete(gameId)
    try {
      await stFetch('/user-favorite/casino-game/delete', {
        method: 'post',
        body: {
          gameId,
        },
      })
    } catch (e) {
      favoriteGamesIds.value.add(gameId)
      console.error(e)
    }
  }

  function checkGameFavorite(gameId: number): boolean {
    return favoriteGamesIds.value.has(gameId)
  }

  const gameIdsCount = computed(() => favoriteGamesIds.value?.size ?? 0)

  async function initStore() {
    await fetchFavoriteGames()
    const gameIDs = favoriteGames.value?.map((game) => game.gameId)
    favoriteGamesIds.value = new Set(gameIDs)
  }

  watch(
    () => isAuthenticated.value,
    () => {
      if (isAuthenticated.value) {
        initStore()
      }
    },
    { immediate: true },
  )

  return {
    favoriteGamesIds,
    gameIdsCount,
    favoriteGames,
    addFavoriteGame,
    deleteFavoriteGame,
    checkGameFavorite,
  }
})
