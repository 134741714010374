import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import type { Currency, UserAccount, Network } from '../interfaces'
import { useAccountsStore } from '../stores/useAccountsStore'
import { useCurrenciesStore } from '../stores/useCurrenciesStore'
import { usePaymentsDeps } from '../useDeps'

interface Options {
  selectedCurrencyId?: Ref<number | undefined>
  omitZeroBalanceFiltration?: boolean
}

export interface ExtendedUserAccount extends UserAccount {
  isAvailable: boolean
}

export function useAllowedAssets(options?: Options) {
  const {
    realAccounts: allRealAccounts,
    filteredRealAccounts: nonZeroAllRealAccounts,
    preferredCurrencyCode,
  } = storeToRefs(useAccountsStore())
  const currenciesStore = useCurrenciesStore()
  const { getCurrencyByCode } = currenciesStore
  const { currencies: allCurrencies } = storeToRefs(currenciesStore)
  const { settings } = storeToRefs(useSettingsStore())
  const {
    isUserSettingsSet,
    updateUserPreferredCurrencyCode,
    isTelegramMiniApp,
  } = usePaymentsDeps()

  const accountAllowedCurrencies = computed<Currency[]>(() =>
    Object.values(allCurrencies.value).filter(
      (currency) => currency.allowedForAccount,
    ),
  )

  const isUsingFiltration = computed<boolean>(
    () => !!settings.value && isTelegramMiniApp.value,
  )

  const tmaCurrencies = computed<Currency[]>(() =>
    accountAllowedCurrencies.value.reduce<Currency[]>((acc, currency) => {
      const currencyNetworksIds =
        settings.value?.currencySettings[currency.id]
          .telegramMiniAppAllowedNetworkIds ?? []
      if (currencyNetworksIds.length) {
        acc.push({
          ...currency,
          networks: currency.networks.filter(({ id }) =>
            currencyNetworksIds.includes(id),
          ),
        })
      }
      return acc
    }, []),
  )

  const tmaCurrenciesIdsDict = computed<Record<number, Currency>>(() =>
    tmaCurrencies.value.reduce<Record<number, Currency>>((acc, currency) => {
      acc[currency.id] = currency
      return acc
    }, {}),
  )

  const tmaCurrenciesIds = computed<number[]>(() =>
    Object.keys(tmaCurrenciesIdsDict.value).map((id) => +id),
  )

  function isPreferredCurrencyAllowedInTMA(): boolean {
    const preferredCurrencyId = getCurrencyByCode(
      preferredCurrencyCode.value as string,
    )?.id as number
    return tmaCurrenciesIds.value.includes(preferredCurrencyId)
  }

  function setPreferredCurrencyAllowedForTMA(params?: {
    isSignUp: boolean
  }): void {
    if (!settings.value || !isUserSettingsSet.value) return
    const currencyTON = tmaCurrencies.value.find(
      ({ codeLowerCase }) => codeLowerCase === 'ton',
    )
    if (!currencyTON) return
    /**
     * На регистрации всегда проставляем TON, на логине - только если текущая
     * предпочитаемая валюта не совпадает
     */
    if (!params?.isSignUp && isPreferredCurrencyAllowedInTMA()) return
    updateUserPreferredCurrencyCode(currencyTON.code)
  }

  function isCurrencyForTMA(id: number): boolean {
    return !!tmaCurrenciesIdsDict.value[id]
  }

  const realAccounts = computed<ExtendedUserAccount[]>(() => {
    let usedAccounts = options?.omitZeroBalanceFiltration
      ? allRealAccounts.value
      : nonZeroAllRealAccounts.value
    if (isUsingFiltration.value) {
      usedAccounts = usedAccounts.filter(
        ({ balance, currencyId }) => isCurrencyForTMA(currencyId) || !!+balance,
      )
    }
    return usedAccounts.map((acc) => ({
      ...acc,
      isAvailable: !isUsingFiltration.value || isCurrencyForTMA(acc.currencyId),
    }))
  })

  const networks = computed<Network[]>(() => {
    if (!options?.selectedCurrencyId?.value) return []
    const { networks: allNetworks } =
      allCurrencies.value[options.selectedCurrencyId.value]
    if (!isUsingFiltration.value) return allNetworks
    return tmaCurrenciesIdsDict.value[options.selectedCurrencyId.value].networks
  })

  const currencies = computed<Currency[]>(() => {
    if (!isUsingFiltration.value) return accountAllowedCurrencies.value
    return tmaCurrencies.value
  })

  const prohibitedCurrencies = computed<Currency[]>(() => {
    if (!isUsingFiltration.value) return []
    return accountAllowedCurrencies.value.filter(
      ({ id }) => !isCurrencyForTMA(id),
    )
  })

  return {
    tmaCurrencies,
    realAccounts,
    networks,
    currencies,
    prohibitedCurrencies,
    setPreferredCurrencyAllowedForTMA,
  }
}
