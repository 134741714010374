import { useCasinoDeps } from '@st/casino/useDeps'

export function useMaxWin(gameId: Ref<number>) {
  const { appCurrency } = useCasinoDeps()
  const { format } = useCurrencyFormatter({
    currency: appCurrency.value.code,
  })

  const { data: bigWins } = useStFetch('/casino/game/biggest-wins', {
    method: 'post',
    body: computed(() => ({
      gameId: gameId.value,
    })),
    transform: (resp) => ({
      daily: format(resp.daily),
      monthly: format(resp.monthly),
      total: format(resp.total),
    }),
    default: () => ({
      daily: '0',
      monthly: '0',
      total: '0',
    }),
  })

  return { bigWins }
}
