import { useApplicationType, useIsNuxtReady } from '@st/use/composables'

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var _mtm: unknown[]
}

export function useAnalyticsScript() {
  const { isNuxtReady } = useIsNuxtReady()
  const { platform } = usePlatform()
  const { isTelegramMiniApp, isPwa } = useApplicationType()
  const config = useRuntimeConfig()
  const scriptUrl = config.public.matomoScriptUrl

  const matomo = useScript(
    {
      src: scriptUrl,
      async: true,
      crossorigin: false,
      referrerpolicy: false,
    },
    {
      trigger: computed(() => isNuxtReady.value && !!scriptUrl),
      // eslint-disable-next-line no-underscore-dangle
      use: () => globalThis._mtm,
    },
  )

  const currentPlatform = computed(() => {
    if (isTelegramMiniApp.value) {
      return 'telegramMiniApp'
    }
    if (isPwa.value) {
      return 'pwa'
    }
    return platform.value
  })

  matomo.onLoaded((mtm) => {
    mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
    mtm.push({
      platform: currentPlatform.value,
    })
  })

  matomo.onError((err) => {
    console.error(`Analytics script not loaded: ${err}`)
  })

  return matomo
}
