import type { GameLimits } from '../types'

export const usePragmaticDGAStore = defineStore('pragmaticDGA', () => {
  const progmaticWs = ref<WebSocket | null>(null)
  const config = useRuntimeConfig()
  const { wsUrl, casinoId } = config.public.pragmatic

  const PERMISSION_HOSTS = ['betone.com', 'localhost', 'st.stage.sxl.bet']

  const isDGAEnabled = computed(() => {
    if (import.meta.client) {
      return PERMISSION_HOSTS.includes(window.location.hostname)
    }
    return false
  })

  const { execute: fetchDgaGames, data: gameIdsMap } = useStFetch(
    '/casino/game/pragmatic/active/list',
    {
      method: 'POST',
      immediate: false,
      watch: false,
    },
  )
  function getGameExternalId(gameId: number) {
    return gameIdsMap.value?.find((i) => String(i.gameId) === String(gameId))
      ?.externalIdNumeric
  }

  const gamesLimits = ref<Record<string, GameLimits>>({})

  function handleMessage(event: MessageEvent) {
    try {
      const data = JSON.parse(event.data)

      gamesLimits.value[data.tableId] = {
        maxBet: data?.tableLimits?.maxBet,
        minBet: data?.tableLimits?.minBet,
        maxPlayers: data?.tableLimits?.maxPlayers,
        totalSeatedPlayers: data?.totalSeatedPlayers,
      }
    } catch (err) {
      console.error(err)
    }
  }
  const initWebSocket = () => {
    if (!isDGAEnabled.value || !wsUrl) return
    if (progmaticWs.value?.readyState === WebSocket.OPEN) return

    progmaticWs.value = new WebSocket(wsUrl)

    // Переподписываем все слушатели на новый сокет
    progmaticWs.value.addEventListener('message', handleMessage)

    progmaticWs.value.addEventListener('open', () => {
      progmaticWs.value?.send(
        JSON.stringify({
          casinoId,
          type: 'available',
        }),
      )
    })
    progmaticWs.value.addEventListener('close', () => {
      setTimeout(initWebSocket, 1000)
    })
    progmaticWs.value.addEventListener('error', (error) => {
      console.error('pragmaticDGA WS error:', error)
    })
  }
  function subscribeGame(gameId: number) {
    if (gamesLimits.value[gameId]) return
    if (progmaticWs.value?.readyState === WebSocket.OPEN) {
      progmaticWs.value?.send(
        JSON.stringify({
          casinoId,
          type: 'subscribe',
          currency: 'USD',
          key: gameId,
        }),
      )
    }
  }

  async function initDgaStore() {
    if (isDGAEnabled.value) {
      await fetchDgaGames()
      initWebSocket()
      window.addEventListener('online', initWebSocket)
    }
  }

  return {
    getGameExternalId,
    initWebSocket,
    subscribeGame,
    gamesLimits,
    isDGAEnabled,
    initDgaStore,
  }
})
