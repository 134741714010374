import { createEntityDeps } from '@st/use/composables'

const [useCasinoDeps, provideCasinoDeps] = createEntityDeps<{
  isAuthenticated: Ref<boolean>
  isReady: Ref<boolean>
  userSettings: Ref<any>
  MAccountWidget: Component
  activeAccount: Ref<any>
  appCurrency: Ref<any>
  isAllAccountsEmpty: Ref<boolean>
  casinoTournament: Ref<any>
  PageTournamentCard: Component
  MPageTournamentCard: Component
  getCurrencyIdByCode: (code: string) => number | undefined
  getCurrencyIconById: (id: number) => string | undefined
  gameCurrencyCode: (bet: any) => MaybeRef<string>
  useIntercomApi: any
}>('casino')

export { useCasinoDeps, provideCasinoDeps }
