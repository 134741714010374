import { useUserStore } from '@st/user/stores/useUserStore'
import { useTelegram } from '@st/telegram/composables/useTelegram'
import { useAllowedAssets } from '../composables/useAllowedAssets'

export default defineNuxtPlugin({
  name: 'tmaCurrenciesUpdate',
  order: 4,
  async setup() {
    const { isAuthenticated, isJustRegisteredUser } =
      storeToRefs(useUserStore())
    const { setPreferredCurrencyAllowedForTMA } = useAllowedAssets()
    const { isTelegramMiniApp } = useTelegram()

    watchEffect(() => {
      if (!isTelegramMiniApp.value || !isAuthenticated.value) return
      setPreferredCurrencyAllowedForTMA({
        isSignUp: isJustRegisteredUser.value,
      })
      isJustRegisteredUser.value = false
    })
  },
})
