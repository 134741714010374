import type { IconName } from '@st/ui/components/StIcon/types'
import type { UserAccount } from '@st/payments/interfaces'
import type { User, UserSettings } from '@st/user/stores/useUserStore'

const [useProfileDeps, provideProfileDeps] = createEntityDeps<{
  initWebPush: () => Promise<void>
  getCurrencyIconById: (currencyId: number) => IconName
  getCurrencyCodeById: (currencyId: number) => string
  logout: () => Promise<void>
  getAuthProvidersList: () => Promise<void>
  updateUserSettings: (
    data: {
      name: keyof UserSettings
      value: UserSettings[keyof UserSettings]
    }[],
  ) => Promise<void>
  refreshUserData: () => Promise<void>
  handleGoogleRedirect: () => Promise<void>
  fetchChannels: () => Promise<void>
  setActiveChannel: (channel: 'email' | 'telegram') => Promise<void>
  isEnableNotifications: Ref<boolean>
  isDisabledNotifications: Ref<boolean>
  hasSubscription: Ref<boolean>
  betCounter: Ref<number>
  isReadyActiveBets: Ref<boolean>
  bonusesCount: Ref<number>
  activeAccount: Ref<UserAccount>
  user: Ref<User | null>
  userSettings: Ref<UserSettings | null>
  isAuthenticated: Ref<boolean>
  hasTelegram: Ref<boolean>
  hasEmail: Ref<boolean>
  kycLevelName: Ref<string>
  passwordValidators: Ref<
    {
      rule: (value: string) => boolean
      errorMessage: string
    }[]
  >
  hasTelegramProvider: Ref<boolean>
  hasEmailProvider: Ref<boolean>
  hasGoogleProvider: Ref<boolean>
  isOnlyOneProvider: Ref<boolean>
  isEmailChannel: Ref<boolean>
  isTelegramChannel: Ref<boolean>
  isReadyChannels: Ref<boolean>
  activeChannel: Ref<'email' | 'telegram' | undefined>
  isEnabledPromotions: Ref<boolean>
  RewardLabel: Component<{
    size?: 'm' | 'l'
  }>
  BetHistory: Component<{
    betType?: 'inGame' | 'processed'
  }>
  MBetHistory: Component<{
    betType?: 'inGame' | 'processed'
  }>
  CasinoBetsHistory: Component<{
    mobile?: boolean
  }>
}>('profile')

export { useProfileDeps, provideProfileDeps }
