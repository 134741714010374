import { useRefreshBonusesSocket } from '../composables/useRefreshBonusesSocket'
import type { RegularBonus } from '../types'
import { useBonusesDeps } from '../useDeps'

/**
 * Логика работы с регулярными бонусами довольно поехавшая
 * Используется огромная пачка роутов чтобы все правильно отрисовать
 *
 * Этот стор нужен чтобы все это говнище инкапсулировать
 * На выходе получается единообразный массив бонусов типа RegularBonus
 *
 * Жизненный цикл бонуса может включать 3 этапа
 * status: default - юзер не участвует в бонусной программе ( deprecated )
 * status: waitingForAccrual - юзер участвует в программе и ждет начисления награды
 * status: readyToClaim - награда начислена и ее можно забрать
 */
export const useRegularBonusesStore = defineStore('regularBonuses', () => {
  const { data, execute: fetchBonuses } = useStFetch('/bonus/find', {
    method: 'post',
    body: {
      params: {
        expired: false,
        claimed: false,
        bonusProgramType: ['cashback', 'rakeBack', 'reload'],
      },
      pagination: {
        perPage: 50,
        page: 0,
        orderBy: [
          {
            fieldName: 'id',
            sortOrder: 'ASC',
          },
        ],
      },
    },
    immediate: false,
  })
  const regularBonusesCount = ref(0)
  const bonuses = computed(() => data.value?.data ?? [])
  const {
    data: cashbackPrograms,
    execute: fetchCashbackList,
    status: cashbackListLoadingStatus,
  } = useStFetch('/cashback/list/v2', {
    method: 'post',
    immediate: false,
    watch: false,
    default: () => [],
  })
  const cashbacks = computed<RegularBonus[]>(() => {
    // бонусы которые можно забрать
    const cashBacksBonuses = bonuses.value
      .filter(({ bonusProgramType }) => bonusProgramType === 'cashback')
      .map((bonus) => {
        const bonusProgram = cashbackPrograms.value.find(
          (program) => program.bonusProgramId === bonus.bonusProgramId,
        )

        return {
          type: 'cashback' as const,
          status: 'readyToClaim' as const,
          amount: bonus.amount,
          currencyId: bonus.currencyId,
          bonusId: bonus.id,
          bonusProgramId: bonus.bonusProgramId,
          expiredAt: bonus.expiredAt,
          percent: bonusProgram?.baseMultiplier,
          upToAmount: bonusProgram?.maxBonusAmountInCashbackProgramCurrency,
          baseGgrMultiplier: bonusProgram?.baseGgrMultiplier,
          progressTotal:
            bonusProgram?.userDropThresholdInCashbackProgramCurrency,
          id: `cashback${bonus.id}`,
        }
      })

    const programs = cashbackPrograms.value
    // программы ожидающих начисления ( с исключением бонусов, которые можно забрать)
    const onlyCashbackProgramms = programs
      .filter((program) => {
        const bonus = cashBacksBonuses.find(
          (item) => item.bonusProgramId === program.bonusProgramId,
        )

        return !bonus
      })
      .map((program) => ({
        type: 'cashback' as const,
        status: 'waitingForAccrual' as const,
        accrualAt: program.validDateTo,
        id: `cashback${program.bonusProgramId}`,
        percent: program.baseMultiplier,
        upToAmount: program.maxBonusAmountInCashbackProgramCurrency,
        baseGgrMultiplier: program?.baseGgrMultiplier,
        amount: program.amount,
        progressCurrent: program.currentUserDropInCashbackProgramCurrency,
        progressTotal: program.userDropThresholdInCashbackProgramCurrency,
      }))

    const result = [...cashBacksBonuses, ...onlyCashbackProgramms]

    return result
  })

  const { data: reloadPrograms, execute: fetchReloadPrograms } = useStFetch(
    '/reload-program/list',
    {
      method: 'post',
      immediate: false,
      watch: false,
      default: () => [],
    },
  )
  const reloads = computed<RegularBonus[]>(() => {
    const reloadsWithPrograms = reloadPrograms.value.map((program) => {
      const bonus = bonuses.value.find(
        (item) =>
          item.bonusProgramId === program.bonusProgramId &&
          item.bonusProgramType === 'reload',
      )

      if (bonus) {
        return {
          id: `reload${program.bonusProgramId}`,
          type: 'reload' as const,
          status: 'readyToClaim' as const,
          amount: bonus.amount,
          currencyId: bonus.currencyId,
          bonusId: bonus.id,
          expiredAt: bonus.expiredAt,
          level: bonus.bonusForDepositProgram?.level,
          availableUntil: program.availableUntil,
        }
      }
      return {
        id: `reload${program.bonusProgramId}`,
        type: 'reload' as const,
        status: 'waitingForAccrual' as const,
        accrualAt: program.accrualAt,
        amount: program.amount,
        currencyId: program.currencyId,
        availableUntil: program.availableUntil,
      }
    })
    const reloadsWithoutPrograms = bonuses.value
      .filter(
        (item) =>
          item.bonusProgramType === 'reload' &&
          !reloadsWithPrograms.find(({ bonusId }) => item.id === bonusId),
      )
      .map((bonus) => ({
        id: `reload${bonus.id}`,
        type: 'reload' as const,
        status: 'readyToClaim' as const,
        amount: bonus.amount,
        currencyId: bonus.currencyId,
        bonusId: bonus.id,
        expiredAt: bonus.expiredAt,
        level: bonus.bonusForDepositProgram?.level,
      }))

    return [...reloadsWithPrograms, ...reloadsWithoutPrograms]
  })

  const { data: rakeBackPrograms, execute: fetchRakebackPrograms } = useStFetch(
    '/rake-back-program/list',
    {
      method: 'post',
      immediate: false,
      watch: false,
      default: () => [],
    },
  )
  const rakebacks = computed<RegularBonus[]>(() => {
    const rakeBackBonuses = bonuses.value.filter(
      (bonus) => bonus.bonusProgramType === 'rakeBack',
    )

    const rakeBackProgramsWithoutBonuses = rakeBackPrograms.value.filter(
      ({ status, bonusProgramId }) => {
        const bonus = rakeBackBonuses.find(
          (item) => item.bonusProgramId === bonusProgramId,
        )

        return status === 'open' && !bonus
      },
    )

    const defaultRakeBacks = rakeBackProgramsWithoutBonuses.map((program) => ({
      id: `rakeBack${program.bonusProgramId}`,
      type: 'rakeBack' as const,
      status: 'waitingForAccrual' as const,
      percent: program.percent,
      accrualAt: program.validDateTo,
      betSettings: program.betSettings,
    }))

    const readyToClaimRakeBacks = rakeBackBonuses.map((bonus) => {
      const bonusProgram = rakeBackPrograms.value.find(
        ({ bonusProgramId }) => bonus.bonusProgramId === bonusProgramId,
      )

      return {
        id: `rakeBack${bonus.id}`,
        type: 'rakeBack' as const,
        status: 'readyToClaim' as const,
        bonusId: bonus.id,
        amount: bonus.amount,
        currencyId: bonus.currencyId,
        expiredAt: bonus.expiredAt,
        betSettings: bonusProgram ? bonusProgram.betSettings : null,
      }
    })

    return [...defaultRakeBacks, ...readyToClaimRakeBacks]
  })

  const regularBonuses = computed<RegularBonus[]>(() => [
    ...cashbacks.value,
    ...reloads.value,
    ...rakebacks.value,
  ])

  const { isAuthenticated } = useBonusesDeps()

  async function refreshBonuses() {
    if (isAuthenticated.value) {
      await fetchBonuses()
      regularBonusesCount.value = data.value?.paging.total ?? 0
      fetchCashbackList()
      fetchReloadPrograms()
      fetchRakebackPrograms()
    } else {
      regularBonusesCount.value = 0
    }
  }
  watch(
    () => isAuthenticated.value,
    async () => {
      refreshBonuses()
    },
    { immediate: true },
  )

  useRefreshBonusesSocket(refreshBonuses)

  return {
    bonuses,
    regularBonuses,
    reloadPrograms,
    cashbackListLoadingStatus,
    regularBonusesCount,
    refreshBonuses,
    cashbackPrograms,
  }
})
