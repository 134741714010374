function loadDayjsLocale(locale: string) {
  switch (locale) {
    case 'ru':
      return import('dayjs/locale/ru.js')
    case 'en':
      return import('dayjs/locale/en.js')
    case 'es':
      return import('dayjs/locale/es.js')
    case 'pt':
      return import('dayjs/locale/pt.js')
    default:
      return import('dayjs/locale/en.js')
  }
}

export default defineNuxtPlugin({
  name: 'i18n-base-init',
  async setup() {
    const { $i18n } = useNuxtApp()

    loadDayjsLocale($i18n.locale.value)
  },
})
