import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import banner from '../components/assets/promotion_1.jpg'

export interface Promotion {
  id: number
  img: string
  link: string
  title: string
  expiredDate: string
  description: string
  fullDescription: string
}

export const usePromotions = () => {
  const { t } = useNuxtApp().$i18n

  const settingsStore = useSettingsStore()
  const { settings } = storeToRefs(settingsStore)

  const promotions: Promotion[] = [
    {
      id: 1,
      img: banner,
      link: '/casino/categories/slots/drops-and-wins',
      title: t('promotions.title1'),
      expiredDate: '04.06.2025',
      description: t('promotions.description'),
      fullDescription: t('promotions.fullDescription'),
    },
  ]

  const isEnabledPromotions = computed(
    () => !!settings.value?.frontPromotionEnabled,
  )

  return { promotions, isEnabledPromotions }
}
