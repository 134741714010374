import type { IconName } from '@st/ui/components/StIcon/types'
import type { AccountTransactionNotification, Notification } from '../types'

export function useAccountTransactionNotification(
  notification: Ref<Notification>,
) {
  function isAccountTransactionNotificationGuard(
    notificationItem: Ref<Notification>,
  ): notificationItem is Ref<AccountTransactionNotification> {
    return 'operationType' in notificationItem.value.data
  }

  const notificationOperationType = computed(() => {
    if (!isAccountTransactionNotificationGuard(notification)) return null

    return notification.value.data.operationType
  })

  const useAccountIcon = computed<IconName | ''>(() => {
    if (!notificationOperationType.value) return ''

    switch (notificationOperationType.value) {
      case 'freebetAccrual':
        return 'freebet'
      case 'freebetProcessAccrual':
        return 'freebet'
      case 'referralAccrual':
        return 'user-plus-solid'
      case 'freespinRewardAccrual':
        return 'freespins'
      case 'freebetWinAccrual':
        return 'freespins'
      case 'carrotAccrual': {
        const reasonForGetting =
          notification.value.type === 'accountTransactionCreated'
            ? notification.value.data?.meta?.reasonForGetting
            : undefined

        if (reasonForGetting === 'telegramBotSubscription')
          return 'telegram-solid'

        return 'file-check-alt'
      }
      default:
        return ''
    }
  })

  return {
    notificationOperationType,
    useAccountIcon,
  }
}
