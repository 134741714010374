import { delay } from '@st/utils'
import { useGameInitStore } from '../stores/useGameInitStore'

/**
 * хук для анимации открытия игры
 * основан на состоянии launchingGameState из useGameInitStore
 * срабатывает в момент перехода на страницу с игрой (через middlware game.ts)
 */

export function useGameOpenAnimation(gameId: Ref<number>) {
  const { toogleTapBarVisibility } = useMobileLayout()

  const { launchingGameState } = storeToRefs(useGameInitStore())

  const isCurrentGameLounching = computed(
    () => launchingGameState.value.gameId === gameId.value,
  )
  const isLounchingGame = computed(
    () =>
      launchingGameState.value.state === 'loading' &&
      isCurrentGameLounching.value,
  )

  const isAnimated = ref(false)

  async function animateGameOpen() {
    isAnimated.value = true
    toogleTapBarVisibility()
    if (document) {
      document.body.classList.add('no-scroll')
    }

    await delay(1000)
    // очищаем все состояние проигрыша анимации, которая длится 600ms
    isAnimated.value = false
    toogleTapBarVisibility()

    if (document) {
      document.body.classList.remove('no-scroll')
    }
  }

  watchEffect(async () => {
    if (
      launchingGameState.value.state === 'success' &&
      isCurrentGameLounching.value
    ) {
      await animateGameOpen()
    }
  })

  return {
    isLounchingGame,
    isAnimated,
  }
}
