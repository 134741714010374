interface IntercomeEventData {
  api_base?: 'https://api-iam.intercom.io'
  custom_launcher_selector?: string /* селектор для кастомных кнопок, открывающих чат */
  app_id?: string
  name?: string /* Никнейм клиента из параметра nickname */
  user_id?: string /* externalID клиента */
  created_at?: string /* дата регистрации клиента из параметра createdAt */
  user_hash?: string /* Хэш, сгеренированный на бэке intercomHash */
  language_override?: string /* Язык виджета */
  customer_segment?: number /* Сегмент клиента */
}

export type IntercomApi = ((event: 'boot', data?: IntercomeEventData) => void) &
  ((event: 'shutdown') => void) &
  ((event: 'update', options?: IntercomeEventData) => void) &
  ((event: 'hide') => void) &
  ((event: 'show') => void) &
  ((
    event: 'showSpace',
    spaceName:
      | 'home'
      | 'messages'
      | 'help'
      | 'news'
      | 'tasks'
      | 'tickets'
      | string,
  ) => void) &
  ((event: 'showMessages') => void) &
  ((event: 'showNewMessage', content?: string) => void) &
  ((event: 'onHide', fn: () => void) => void) &
  ((event: 'onShow', fn: () => void) => void) &
  ((event: 'onUnreadCountChange', fn: () => void) => void) &
  ((
    event: 'trackEvent',
    eventName: string,
    metadata?: Record<string, any>,
  ) => void) &
  ((event: 'getVisitorId') => Promise<string>) &
  ((event: 'startTour', tourId: string | number) => void) &
  ((event: 'showArticle', articleId: string | number) => void) &
  ((event: 'showNews', newsItemId: string | number) => void) &
  ((event: 'startSurvey', surveyId: string | number) => void) &
  ((event: 'startChecklist', checklistId: string | number) => void) &
  ((event: 'showTicket', ticketId: string | number) => void) &
  ((event: 'showConversation', conversationId: string | number) => void) &
  ((event: 'onUserEmailSupplied', fn: () => void) => void) &
  ((event: string, ...params: any[]) => void)

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var Intercom: IntercomApi
}

/**
 * useIntercomScript - A hook to manage the Intercom script.
 *
 * This function initializes the Intercom script based on the runtime configuration
 * with manual trigger. You need to call intercom.load() to load the script.
 *
 * @returns {Object} An object containing the Intercom instance.
 */

export function useIntercomScript() {
  const config = useRuntimeConfig()
  const apiKey = config.public.intercomKey

  if (!apiKey) {
    console.error('NUXT_PUBLIC_INTERCOM_KEY is missing!')
  }

  const intercom = useScriptIntercom({
    app_id: apiKey,
    scriptOptions: {
      use: () => globalThis.Intercom,
      trigger: 'manual',
    },
  })

  return {
    intercom,
  }
}
