import { useQuestsStore } from '../stores/useQuestsStore'
import { useQuestsDeps } from '../useDeps'

export default defineNuxtPlugin({
  name: 'quests',
  parallel: true,
  order: 2,
  async setup() {
    const questsStore = useQuestsStore()
    const { isReadyUser } = useQuestsDeps()
    const restrictionRoutes = ['/promo/registration']

    usePreventInitByRoute(restrictionRoutes, async () => {
      await until(isReadyUser).toBeTruthy()
      questsStore.fetchActiveQuests()
    })
  },
})
