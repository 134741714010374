import {
  retrieveRawInitData,
  openLink,
  openTelegramLink as sdkOpenTelegramLink,
  isTMA,
} from '@telegram-apps/sdk-vue'

interface AuthData {
  language: string
  promocodeName?: string
  referralCode?: string
}

export function useTelegram() {
  const stFetch = useRawStFetch()
  const isTelegramMiniApp = ref(isTMA())

  const config = useRuntimeConfig()
  const botName = config.public.telegramAuthBotName
  const botLink = computed(() => `https://t.me/${botName}`)

  async function auth(data: AuthData) {
    const rawData = retrieveRawInitData()
    if (!rawData) throw new Error('Telegram auth error')

    return stFetch('/auth/telegram/mini-app', {
      method: 'post',
      body: {
        language: data.language as any,
        promocodeName: data.promocodeName,
        referralCode: data.referralCode,
        tgData: rawData,
      },
    })
  }

  function connectTelegramFromMiniApp() {
    return stFetch('/user/auth/telegram/mini-app', {
      method: 'post',
      body: {
        tgData: retrieveRawInitData() ?? '',
      },
    })
  }

  function openTelegramLink(link: string) {
    if (sdkOpenTelegramLink.isAvailable()) {
      sdkOpenTelegramLink(link)
    } else navigateTo(link)
  }

  function openExternalLinkFromMiniApp(link: string) {
    if (openLink.isAvailable()) {
      openLink(link, {
        tryBrowser: 'chrome',
        tryInstantView: true,
      })
    } else navigateTo(link)
  }

  function openTelegramBotFromMiniApp() {
    openTelegramLink(botLink.value)
  }

  return {
    isTelegramMiniApp,
    auth,
    openTelegramLink,
    openTelegramBotFromMiniApp,
    openExternalLinkFromMiniApp,
    connectTelegramFromMiniApp,
    botLink,
  }
}
