<template>
  <StIcon :size="size" :name="name" :data-t="dataT" />
</template>

<script setup lang="ts">
import { iconsMap, type CryptoIconType, type CurrencyCode } from './iconsMap'

const {
  currency,
  type = 'colored',
  size,
  dataT,
} = defineProps<{
  currency: string
  type?: CryptoIconType
  size?: number
  dataT?: string
  isUnavailable?: boolean
}>()

const name = computed(
  () => iconsMap[type][currency.toLowerCase() as CurrencyCode],
)
</script>
