import { useIntercomApi } from '../composables/useIntercomApi'

export default defineNuxtPlugin(() => {
  const route = useRoute()
  const { toogleChat } = useIntercomApi()

  onNuxtReady(() => {
    watchEffect(() => {
      if (route.query.chat) toogleChat()
    })
  })
})
