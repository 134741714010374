import { useLocalStorage, useIsNuxtReady } from '@st/use/composables'

interface CookiesStorage {
  functional: boolean
  analytic: boolean
  configured: boolean
}

/**
 * useGtmScript - A hook to manage the Google Tag Manager script.
 *
 * This function initializes the Google Tag Manager script based on the runtime configuration
 * and the user's cookie preferences.
 *
 * @returns {Object} An object containing the dataLayer - The Google Tag Manager dataLayer array.
 * @example const { dataLayer } = useGtmScript()
 * dataLayer.push({ event: 'pageview' })
 */
export function useGtmScript() {
  const config = useRuntimeConfig()
  const gtmKey = config.public.gtmId

  if (!gtmKey) {
    console.error('NUXT_PUBLIC_GTM_ID key is missing!')
  }

  const cookiesStorage = useLocalStorage<CookiesStorage>('cookiesStorage', {
    functional: true,
    analytic: true,
    configured: false,
  })

  const { isNuxtReady } = useIsNuxtReady()
  const { dataLayer } = useScriptGoogleTagManager({
    id: gtmKey,
    scriptOptions: {
      trigger: computed(
        () => isNuxtReady.value && !!gtmKey && cookiesStorage.value.analytic,
      ),
    },
  })

  return {
    dataLayer,
  }
}
