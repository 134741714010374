<template>
  <StIcon
    :class="color"
    :name="icon"
    class="live-indicator"
    data-t="live-indicator"
    :size="size"
  />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isPause?: boolean
    color?: 'white' | 'orange' | 'green' | 'yellow' | 'red'
    size?: number
    live?: boolean
  }>(),
  {
    isPause: false,
    color: 'white',
    size: 16,
    live: true,
  },
)

const icon = computed(() => {
  if (props.isPause) return 'pause-bold-solid'
  return 'live'
})
</script>

<style scoped>
.live-indicator {
  display: flex;

  &.orange {
    background-color: var(--icon-brand);
  }

  &.white {
    background-color: var(--icon-primary);
  }

  &.green {
    background-color: var(--icon-success);
  }

  &.yellow {
    background-color: var(--icon-warning);
  }

  &.red {
    background-color: var(--icon-error);
  }
}
</style>
