import { delay } from '@st/utils'
import { useVibration } from '@st/use/composables'
import { useFavoriteGamesStore } from '../stores/useFavoriteGamesStore'

export function useFavoriteGame(gameId: number | Ref<number> | (() => number)) {
  const id = computed(() => toValue(gameId))
  const { addFavoriteGame, deleteFavoriteGame, checkGameFavorite } =
    useFavoriteGamesStore()

  const vibrate = useVibration()

  const isFavoriteGame = computed(() => checkGameFavorite(id.value))

  const shakeAnimation = ref(false)
  async function animateFavoriteIcon() {
    shakeAnimation.value = true
    await delay(300)
    shakeAnimation.value = false
  }

  function handleFavoriteClick() {
    animateFavoriteIcon()
    vibrate()
    return isFavoriteGame.value
      ? deleteFavoriteGame(id.value)
      : addFavoriteGame(id.value)
  }

  const favoriteGameIconColor = computed(() =>
    isFavoriteGame.value ? '#fb1038' : undefined,
  )

  const favoriteGameIcon = computed(() =>
    isFavoriteGame.value ? 'heart-solid' : 'heart',
  )

  return {
    handleFavoriteClick,
    animateFavoriteIcon,
    shakeAnimation,
    isFavoriteGame,
    favoriteGameIconColor,
    favoriteGameIcon,
  }
}
