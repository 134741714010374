import { useUserStore } from './useUserStore'

interface Channel {
  channel: 'email' | 'telegram'
  active: boolean
}

export const use2FaStore = defineStore('2fa', () => {
  const stFetch = useRawStFetch()
  const channels = ref<Channel[]>([])
  const isReady = ref(false)

  async function fetchChannels() {
    const { data, error } = await stFetch('/user/communication/channel/list', {
      method: 'post',
    })
    isReady.value = true

    if (error) {
      console.error('FAILED TO FETCH USER 2FA CHANNELS', error)
      return
    }

    channels.value = data
  }

  const hasTelegram = computed(() =>
    Boolean(channels.value.find((provider) => provider.channel === 'telegram')),
  )

  const hasEmail = computed(() =>
    Boolean(channels.value.find((provider) => provider.channel === 'email')),
  )

  const { isAuthenticated } = storeToRefs(useUserStore())
  watchEffect(() => {
    if (isAuthenticated.value) {
      fetchChannels()
    } else {
      channels.value = []
    }
  })

  return {
    fetchChannels,
    channels,
    isReady,
    hasTelegram,
    hasEmail,
  }
})
