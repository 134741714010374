export function useIsNuxtReady() {
  const isNuxtReady = ref(false)
  onNuxtReady(() => {
    isNuxtReady.value = true
  })

  return {
    isNuxtReady,
  }
}
