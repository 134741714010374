<template>
  <slot v-if="isDesktop" name="desktop" />
  <header v-else class="section-header" data-t="st-section-header-mobile">
    <div class="header-wrapper">
      <component
        :is="link ? NuxtI18nLink : 'div'"
        :to="link"
        :data-t="dataT?.titleWrapper || ''"
        class="title-wrapper"
      >
        <StIcon
          v-if="withIcon"
          class="title-icon"
          :data-t="dataT?.titleIcon || ''"
          v-bind="iconProps"
          :size="20"
        />
        <component
          :is="size === 'm' ? 'h2' : 'h3'"
          class="title"
          :class="`size-${size}`"
          :data-t="dataT?.title || ''"
        >
          {{ title }}
        </component>
        <StIcon
          v-if="withChevron"
          class="chevron-icon"
          name="chevron-right"
          :size="size === 'm' ? 20 : 16"
        />
      </component>
      <slot name="interactive">
        <StButton
          v-if="buttonText"
          :to="buttonLink || link"
          :data-t="dataT?.button || ''"
          size="s"
          type="gray"
        >
          {{ buttonText }}
          <span
            v-if="buttonCounter"
            class="amount"
            :data-t="dataT?.buttonCounter || ''"
          >
            {{ buttonCounter }}
          </span>
        </StButton>
      </slot>
    </div>
    <slot name="addition" />
  </header>
</template>

<script setup lang="ts">
import NuxtI18nLink from '@st/i18n/components/NuxtI18nLink.vue'
import type { Props as IconProps } from '../StIcon/StIcon.vue'

export interface Props {
  title: string
  link?: string
  buttonText?: string
  dataT?: {
    title?: string
    titleWrapper?: string
    titleIcon?: string
    button?: string
    buttonCounter?: string
  }
  buttonCounter?: number
  buttonLink?: string
  size?: 's' | 'm'
  titleIcon?: IconProps['name']
  titleIconLink?: IconProps['iconLink'] | null
  withChevron?: boolean
}

const {
  link,
  // optional
  size = 's',
  withChevron = true,
  title,
  dataT,
  buttonCounter,
  buttonText,
  buttonLink,
  titleIcon,
  titleIconLink,
} = defineProps<Props>()

const withIcon = computed(() => !!(titleIcon || titleIconLink))

const iconProps = computed<Pick<IconProps, 'name' | 'iconLink'>>(() => {
  if (titleIcon) {
    return { name: titleIcon }
  }
  return { iconLink: titleIconLink ?? '' }
})

const { isDesktop } = usePlatform()

defineOptions({
  inheritAttrs: false,
})
</script>

<style scoped>
.section-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-wrapper {
  display: inline-flex;
  gap: var(--spacing-100);
  align-items: center;
  text-decoration: none;
}

.title-icon {
  color: var(--icon-secondary);
}

.title {
  margin: 0;
  color: var(--text-primary);

  &.size-m {
    font: var(--mobile-title-1-semibold);
  }

  &.size-s {
    font: var(--mobile-title-2-semibold);
  }
}

.chevron-icon {
  position: relative;
  top: calc(var(--spacing-025) / 4);
  right: var(--spacing-050);
  color: var(--icon-tertiary);
}

.amount {
  margin-left: var(--spacing-025);
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}
</style>
