export function useCopyWithToast(
  copiedValue: MaybeRefOrGetter<string>,
  toastLabel: MaybeRefOrGetter<string>,
) {
  const {
    copy: originalCopy,
    copied,
    isSupported,
  } = useClipboard({
    source: copiedValue,
    copiedDuring: 3000,
  })
  const toast = useToast()

  const { t } = useI18n()

  async function copy() {
    if (!isSupported.value) {
      toast.open({
        label: t('copyFailToast'),
        type: 'negative',
      })
      return
    }
    await originalCopy()
    toast.open({
      label: toValue(toastLabel),
      type: 'positive',
    })
  }

  return {
    isCopied: copied,
    copy,
  }
}
