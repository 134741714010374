import type { IconName } from '@st/ui/components/StIcon/types'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import type { Currency, CurrencyCode, RawCurrency } from '../interfaces'
import { getNetworkIconName, getCurrencyIconName } from '../helpers/index'

export const useCurrenciesStore = defineStore('currencies', () => {
  const stFetch = useRawStFetch()

  const currencies = ref<Record<number, Currency>>({})
  const { settings } = storeToRefs(useSettingsStore())

  const appCurrency = computed(() => ({
    code: settings.value?.appCurrencyCode ?? 'USD',
    id: settings.value?.appCurrencyId ?? 8,
  }))

  function extendCurrency(currency: RawCurrency): Currency {
    const currencySettings = settings.value?.currencySettings[currency.id]
    const allowedForDeposit = currencySettings?.allowedForDeposit || false
    const allowedForWithdrawal = currencySettings?.allowedForWithdrawal || false
    const allowedForCalypsoDepositNetworks =
      settings.value?.allowedForCalypsoDepositNetworks || []
    const allowedForDepositNetworks =
      settings.value?.allowedForDepositNetworks || []
    const allowedForWithdrawalNetworks =
      settings.value?.allowedForWithdrawalNetworks || []

    const networks =
      currency?.networks?.map((network) => ({
        ...network,
        allowedForDeposit: allowedForDepositNetworks?.includes(network.id),
        allowedForWithdrawal: allowedForWithdrawalNetworks?.includes(
          network.id,
        ),
        allowedForCalypso: allowedForCalypsoDepositNetworks?.includes(
          network.id,
        ),
        icon: getNetworkIconName(network.code.toLowerCase()) as IconName,
      })) ?? []

    return {
      ...currency,
      allowedForDeposit,
      allowedForWithdrawal,
      codeLowerCase: currency.code.toLowerCase() as CurrencyCode,
      icon: getCurrencyIconName(currency.code.toLowerCase()) as IconName,
      networks: [...networks],
    }
  }

  function setCurrencies(data: RawCurrency[]) {
    data.forEach((currency) => {
      currencies.value[currency.id] = extendCurrency(currency)
    })
  }

  async function getCurrenciesAndNetworks() {
    const { data } = await stFetch('/currency/networks/list', {
      method: 'post',
    })

    data.forEach((currency) => {
      const currentValue = currencies.value[currency.id]
      const mergedValue = {
        ...currentValue,
        ...currency,
      }

      currencies.value[currency.id] = extendCurrency(mergedValue)
    })
  }

  function getCurrencyByCode(code: string) {
    return Object.values(currencies.value).find(
      (currency) => currency.code === code,
    )
  }

  function getCurrencyById(id: number) {
    return currencies.value[id]
  }

  return {
    setCurrencies,
    getCurrenciesAndNetworks,
    appCurrency,
    currencies,
    getCurrencyByCode,
    getCurrencyById,
  }
})
