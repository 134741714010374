import type { components } from '@st/api/paths'
import { useVerificationModals } from '../composable/useVerificationModals'
import { useVerificationDeps } from '../useDeps'

export default defineNuxtPlugin({
  name: 'verificationStoreInitPlugin',
  dependsOn: ['diContainer' as any],
  async setup() {
    const route = useRoute()
    const io = useSocket()
    const { isAuthenticated } = useVerificationDeps()
    const { showVerificationReminderModals, showVerificationDepositModal } =
      useVerificationModals()

    async function depositStatusHandler(status: {
      depositId: number
      depositStatus: components['schemas']['DepositStatus']
      depositStatusFrom: components['schemas']['DepositStatus']
    }) {
      if (status.depositStatus === 'pendingKyc') {
        await showVerificationDepositModal(route.query)
      }
    }

    const routeToShowModals = '/personal/kyc'
    const routeNotToShowModals = 'modal=verificationKyc'
    onNuxtReady(() => {
      /**
       * Если юзер залогинился, то показываем модалку
       */
      watch(
        isAuthenticated,
        async (newValue) => {
          if (newValue) await showVerificationReminderModals()
        },
        { immediate: true },
      )
      /**
       * Отслеживаем роуты на которых надо показывать модалки и исключаем
       * цикличный вывод модалок если старый путь = роутам из массива
       */
      watch(
        () => route.fullPath,
        (newValue, oldValue) => {
          if (
            newValue.includes(routeToShowModals) &&
            !oldValue.includes(routeToShowModals) &&
            !oldValue.includes(routeNotToShowModals)
          ) {
            showVerificationReminderModals()
          }
        },
      )

      io.on('depositStatus', depositStatusHandler)
    })
  },
})
