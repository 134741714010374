<template>
  <div v-if="limits" class="game-limits">
    <StBadge
      v-if="limits.minBet || limits.maxBet"
      class="badge"
      background="ghost"
      :is-left-icon="false"
      size="s"
    >
      {{ currencyLimit }}
    </StBadge>
    <StBadge
      v-if="limits.maxPlayers || limits.totalSeatedPlayers"
      class="badge"
      background="ghost"
      type="user-solid"
      size="s"
    >
      {{ playersLimits }}
    </StBadge>
  </div>
</template>

<script lang="ts" setup>
const { limits, currency = 'USD' } = defineProps<{
  limits?: {
    minBet?: number
    maxBet?: number
    maxPlayers?: number
    totalSeatedPlayers?: number
  }
  currency?: string
}>()

const { format } = useNumberFormatter()
const { format: formatCurrency } = useCurrencyFormatter({ currency })

const playersLimits = computed(() => {
  if (!limits) return null

  const { totalSeatedPlayers, maxPlayers } = limits

  if (totalSeatedPlayers && maxPlayers) {
    return `${format(totalSeatedPlayers)}/${format(maxPlayers)}`
  }

  if (totalSeatedPlayers) {
    return format(totalSeatedPlayers)
  }

  if (maxPlayers) {
    return format(maxPlayers)
  }

  return null
})

const currencyLimit = computed(() => {
  if (!limits) return null

  const { minBet, maxBet } = limits

  if (minBet && maxBet) {
    return `${formatCurrency(minBet)} - ${formatCurrency(maxBet)}`
  }

  if (minBet) {
    return formatCurrency(minBet)
  }

  if (maxBet) {
    return formatCurrency(maxBet)
  }

  return null
})
</script>

<style scoped>
.game-limits {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  .badge {
    color: var(--text-primary);
    white-space: nowrap;
  }
}
</style>
