import type { MobileLayoutAPI } from '../components/StMobileLayoutProvider/types'

export function useMobileLayout() {
  return inject('MobileLayoutAPI', {
    closeMenu: () => undefined,
    openMenu: () => undefined,
    isOpenedMenu: undefined,
    isOpenedAuth: undefined,
    closeAuth: () => undefined,
    openAuth: () => undefined,
    headerColor: undefined,
    setHeaderColor: () => undefined,
    isDefaultHeaderColor: undefined,
    layoutTranslateY: undefined,
    setLayoutTranslateY: () => undefined,
    scrollToTop: () => undefined,
    toogleTapBarVisibility: () => undefined,
    isVisibleTapBar: undefined,
  }) as MobileLayoutAPI
}
