import type { IconName } from '@st/ui/components/StIcon/types'
import type { CurrencyCode } from '../interfaces'

const networkIconsNameMap: Record<string, IconName> = {
  ethereum: 'c-crypto-eth-gs',
  bsc: 'c-crypto-bnb-gs',
  bitcoin: 'c-crypto-btc-gs',
  litecoin: 'c-crypto-ltc-gs',
  tron: 'c-crypto-trx-gs',
  ton: 'c-crypto-ton-gs',
  polygon: 'c-crypto-pol-gs',
}

const currenciesIconsNameMap: Record<CurrencyCode, IconName> = {
  btc: 'c-crypto-btc',
  bnb: 'c-crypto-bnb',
  usdt: 'c-crypto-usdt',
  usdc: 'c-crypto-usdc',
  eth: 'c-crypto-eth',
  sol: 'c-crypto-sol',
  ltc: 'c-crypto-ltc',
  trx: 'c-crypto-trx',
  ton: 'c-crypto-ton',
  matic: 'c-crypto-pol',
}

export function getNetworkIconName(code: string) {
  return networkIconsNameMap[code as CurrencyCode] ?? 'default-icon'
}

export function getCurrencyIconName(code: string) {
  return currenciesIconsNameMap[code as CurrencyCode] ?? 'default-icon'
}

export function truncateAddress(address: string | null) {
  if (!address) return ''

  if (address.length <= 14) {
    return address
  }

  const firstTwoSymbols = address.slice(0, 2)

  const leftLength = firstTwoSymbols === '0x' ? 8 : 6
  const rightLength = 6

  const leftSide = address.slice(0, leftLength)
  const rightSide = address.slice(-1 * rightLength)

  return `${leftSide}...${rightSide}`
}
