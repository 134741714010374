import { useUserStore } from '@st/user/stores/useUserStore'
import { useVerificationModals } from '@st/verification/composable/useVerificationModals'

export default defineNuxtRouteMiddleware(async (_, from) => {
  if (import.meta.server) return undefined

  const { isReady, user } = storeToRefs(useUserStore())
  await until(isReady).toBeTruthy()
  const kycLevel = user.value?.kycLevel
  if (kycLevel === -1) {
    return navigateTo({ query: { ...from.query, modal: 'createAccount' } })
  }
  const { showVerificationDepositModal } = useVerificationModals()
  return showVerificationDepositModal(from.query)
})
