<template>
  <article class="tournament" data-t="tournament">
    <div class="info">
      <div class="info-top">
        <div>
          <p class="sub-title">{{ t('tournament.tournament') }}</p>
          <h3 class="title">{{ card.name }}</h3>
        </div>
        <div class="badges">
          <StBadge type="timer" :label="countdown" />
          <StBadge type="count" :label="card.prizePlaceNumber" />
        </div>
      </div>
      <div class="info-bottom">
        <p>{{ t('tournament.prizePool') }}</p>
        <PrizeAmount :amount="formattedAmount" :currency-icon="currencyIcon" />
      </div>
    </div>
    <div class="leader-board-wrapper">
      <LeaderBoard :card="card" size="l" />
      <WideButton
        :players-count="card.membersNumber"
        :tournament-id="card.id"
      />
    </div>
  </article>
</template>

<script setup lang="ts">
import type { Tournament } from '../../types'
import { useTournament } from '../../composables/useTournament'
import LeaderBoard from './parts/LeaderBoard.vue'
import PrizeAmount from '../PrizeAmount/PrizeAmount.vue'
import WideButton from './parts/WideButton.vue'

const { t } = useI18n()

const { card } = defineProps<{
  card: Tournament
}>()
const { formattedAmount, currencyIcon } = useTournament(computed(() => card))
const backgroundPath = computed(() => `url("${card.bannerUrl}"`)

const { countdown } = useCountdown(computed(() => card.validDateTo))
</script>

<style scoped>
.info {
  flex-grow: 1;

  min-width: 0;
  min-height: 274px;
  padding: var(--spacing-300);

  background-image: v-bind(backgroundPath);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-150) 0 0 var(--border-radius-150);
}

.info-top {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.title {
  margin: 0;
  font: var(--desktop-text-2xl-semibold);
}

.sub-title {
  margin: 0;
  font: var(--desktop-text-xs-semibold);
  color: var(--text-primary);
}

.badges {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
}

.info-bottom {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  margin-top: 38px; /* stylelint-disable-line */

  p {
    margin: 0;
    font: var(--desktop-text-xs-semibold);
    color: var(--text-tertiary);
  }
}

.leader-board-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  width: 416px;
  margin-left: calc(var(--spacing-200) * -1);
  padding: var(--spacing-300);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);
  box-shadow:
    0 -16px 50px 0 #21212b,
    0 -8px 16px 0 rgb(0 0 0 / 80%);
}

.tournament {
  display: flex;
  background: var(--background-primary, #1f1f29);
  border-radius: var(--border-radius-150);
}
</style>
