<template>
  <div class="button-wrapper" :class="{ disabled }">
    <slot :status="authTelegramStatus"></slot>
    <iframe
      v-if="isIframeVisible"
      :id="botId"
      :src="ifameUrl"
      frameborder="0"
      class="iframe"
      referrerpolicy="no-referrer"
      sandbox="allow-popups allow-scripts allow-same-origin allow-popups-to-escape-sandbox allow-top-navigation"
      data-t="tg-auth-button-iframe-kd6k"
      @error="emit('error')"
    />
  </div>
</template>

<script setup lang="ts">
import type { TelegramAuthParams, AuthEndpoint } from '../../types'
import { useTelegramAuthWidget } from '../../composables/useTelegramAuthWidget'

const {
  disabled = false,
  authParams = { language: 'en' },
  endpoint,
} = defineProps<{
  disabled?: boolean
  authParams: TelegramAuthParams
  endpoint?: AuthEndpoint
}>()

const emit = defineEmits<{
  success: []
  error: [error?: string]
}>()

const config = useRuntimeConfig()
const { telegramAuthBotId: botId, telegramLoginWidgetUrl } = config.public

const {
  handleTelegramAuth,
  removeHandler,
  status: authTelegramStatus,
  widgetId,
} = useTelegramAuthWidget(() => authParams, endpoint)

const telegramAuthParams = computed(() =>
  new URLSearchParams({
    botId,
    language: authParams.language,
    widgetId: widgetId.value,
  }).toString(),
)
const ifameUrl = computed(
  () => `${telegramLoginWidgetUrl}?${telegramAuthParams.value}`,
)

const { isNuxtReady } = useIsNuxtReady()
const isIframeVisible = computed(
  () => isNuxtReady.value && widgetId.value && telegramLoginWidgetUrl,
)

function handleError(err?: string) {
  emit('error', err)
}

onNuxtReady(async () => {
  try {
    await handleTelegramAuth(handleError)
    emit('success')
  } catch {
    emit('error')
  }
})

onBeforeUnmount(() => {
  removeHandler()
})
</script>

<style scoped>
.iframe {
  cursor: pointer;

  position: absolute;
  z-index: 1;
  inset: 0;

  width: 100%;
  height: 100%;
}

.button-wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 0;

  &.disabled {
    .iframe {
      pointer-events: none;
    }
  }
}
</style>
