import type { RouteLocationRaw, Router } from 'vue-router'

function isChangingPath(to: RouteLocationRaw, router: Router): boolean {
  try {
    const resolvedRoute = router.resolve(to)
    const { currentRoute } = router

    return resolvedRoute.path !== currentRoute.value.path
  } catch {
    return true
  }
}

function isMiddlewareRedirect(to: RouteLocationRaw): boolean {
  return Boolean(to && typeof to === 'object' && to?.force)
}

export default defineNuxtPlugin({
  name: 'i18n-router-push',
  async setup() {
    const router = useRouter()

    const prefixRoute = usePrefixRoute()
    const previousRoute = ref<any>()
    const originalReplace = router.replace
    router.replace = (...[to]: Parameters<typeof originalReplace>) => {
      /**
       * Без этой дополнительной проверки миддлвары (не серверные, а обычные) не работают при прямом заходе на url
       * @see https://nuxt.com/docs/guide/directory-structure/middleware
       */
      if (isMiddlewareRedirect(to)) return originalReplace(to)

      return originalReplace(prefixRoute(to))
    }

    const originalPush = router.push
    router.push = (...[to]: Parameters<typeof originalPush>) => {
      const prefixedTo = prefixRoute(to)
      return isChangingPath(prefixedTo, router)
        ? originalPush(prefixedTo)
        : originalReplace(prefixedTo)
    }

    return {
      provide: {
        previousRoute,
      },
    }
  },
})
