import { usePwaActivity } from '../composables/usePwaActivity'
import { useWebPushStore } from '../stores/useWebPushStore'
import { usePwaDeps } from '../useDeps'

export default defineNuxtPlugin({
  name: 'webPush',
  parallel: true,
  order: 3,
  async setup() {
    const { isPwa } = useApplicationType()
    const { isAuthenticated } = usePwaDeps()
    const webPushStore = useWebPushStore()
    const { firstPwaActivate } = usePwaActivity()
    const { registerConnection, init } = webPushStore
    const { hasSubscription } = storeToRefs(webPushStore)
    if (hasSubscription.value && isPwa.value) {
      registerConnection()
    }

    watchEffect(async () => {
      if (isAuthenticated.value && isPwa.value) {
        await init()
        await firstPwaActivate()
      }
    })
  },
})
