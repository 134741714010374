<template>
  <div class="casino-hover-content">
    <div class="top-info">
      <FavoriteButton
        v-if="isWithFavouriteButton"
        :id="game.gameId"
        data-t="game-add-to-favorite-i8la"
      />
      <StTooltip
        v-if="isWithTooltip && !!tooltipLabels.length"
        class="game-tooltip"
        placement="bottom-end"
        disable-teleport
        data-t="game-tooltip-jmgx"
        :styles="'border-top-right-radius: 4px'"
      >
        <template #activator>
          <div class="info-icon-wrapper">
            <StIcon class="icon" name="info" size="16" />
          </div>
        </template>
        <div class="tags-wrapper" data-t="tags-wrapper-cpu1">
          <NuxtI18nLink
            v-for="label in tooltipLabels"
            :key="label.code"
            :to="label.link"
            class="tooltip-tag"
            data-t="tooltip-tag-rj83"
          >
            {{ label.name }}
          </NuxtI18nLink>
        </div>
      </StTooltip>
    </div>
    <div :class="buttonsClass" class="buttons">
      <StButton
        :to="realLink"
        class="play-button"
        data-t="play-button"
        icon="play-solid"
      />
      <GameLimits v-if="isGameHasLimits" :limits class="gray-button" />
      <StButton
        v-if="game.hasDemoMode && !isGameHasLimits"
        :label="t('gameCard.demoMode')"
        :to="demoLink"
        class="demo-button"
        size="xs"
        type="gray"
        data-t="demo-button"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Game } from '@st/casino/types'
import FavoriteButton from './FavoriteButton.vue'
import GameLimits from './GameLimits.vue'
import useGameCard from '../useGameCard'

interface Props {
  game: Game
  realLink: string
  showPlayerCount: boolean
  isWithFavouriteButton: boolean
  isWithTooltip: boolean
}

const {
  game,
  showPlayerCount,
  isWithFavouriteButton,
  isWithTooltip,
  realLink,
} = defineProps<Props>()

const { t } = useI18n()
const { tooltipLabels, limits, isGameHasLimits } = useGameCard(
  toRef(() => game),
  toRef(() => showPlayerCount),
)

const demoLink = computed(() => `/casino/games/${game.gameId}?mode=demo`)
const buttonsClass = computed(() => ({
  'buttons-centered': !game.hasDemoMode && !isGameHasLimits,
}))
</script>

<style scoped>
.casino-hover-content {
  display: flex;
  align-items: center;
}

.top-info {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-self: flex-end;

  padding: var(--spacing-050);
}

.game-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
}

.info-icon-wrapper {
  cursor: help;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  padding: var(--spacing-050);

  background-color: rgb(0 0 0 / 48%);
  backdrop-filter: blur(4px);
  border-radius: var(--border-radius-full);

  .icon {
    background-color: var(--icon-secondary);
  }

  &:hover .icon {
    background-color: var(--icon-primary);
  }
}

.tags-wrapper {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-050);

  max-width: 200px;
  max-height: 140px;
  padding: var(--spacing-100);
}

.tooltip-tag {
  padding: var(--spacing-025) var(--spacing-100);

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
  text-decoration: none;

  background-color: var(--background-primary);
  border-radius: var(--spacing-125);

  &:hover {
    color: var(--text-link);
  }
}

.buttons {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;

  width: 54px;
  padding: var(--spacing-200) 0;
}

.buttons-centered {
  align-items: center;
}

.play-button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 54px;
  height: 54px;

  background: var(--text-link);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);

  &.st-button {
    border-radius: var(--border-radius-full);
  }
}

.demo-button {
  align-self: flex-end;
  width: 48px;
}

/* .demo-button, */
.gray-button {
  margin: var(--spacing-200) auto;
}
</style>
