export const usePwaActivity = () => {
  const stFetch = useRawStFetch()
  const wasFirstActivity = useLocalStorage<boolean>('wasFirstActivity', false)

  async function fetchFirstPwaActivate() {
    const { error } = await stFetch('/user/pwa/activate', {
      method: 'post',
    })

    return error
  }

  async function firstPwaActivate() {
    if (wasFirstActivity.value) return

    const error = await fetchFirstPwaActivate()
    if (!error) wasFirstActivity.value = true
  }
  return {
    firstPwaActivate,
  }
}
