<template>
  <Html v-if="isOnline">
    <Head>
      <VitePwaManifest />
    </Head>
    <Body>
      <StModalManager
        :modals="modalConfig"
        :is-telegram-mini-app="isTelegramMiniApp"
      />
      <StToastManager :position="toastsPosition" />
      <NuxtLayout>
        <NuxtPage @click="handleClick" />
        <NotificationsManager />
      </NuxtLayout>
      <Cookies />
    </Body>
  </Html>
  <OfflinePage v-else />
</template>

<script lang="ts" setup>
import { useUserStore } from '@st/user/stores/useUserStore'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import { useGlobalOutcomeButtonClickHandler } from '@st/coupon/composables/useGlobalOutcomeButtonClickHandler'
import { useBonusesRollingSocket } from '@st/bonuses/composables/useBonusesRollingSocket'
import { useTelegram } from '@st/telegram/composables/useTelegram'
import { useModalConfig } from './modal.config'
import OfflinePage from './components/OfflinePage/OfflinePage.vue'

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

useHead({
  htmlAttrs: {
    lang: head.value?.htmlAttrs?.lang ?? 'en',
    dir: head.value?.htmlAttrs?.dir ?? 'ltr',
  },
  link: [...(head.value?.link || [])],
  meta: [...(head.value?.meta || [])],
})

const { isTelegramMiniApp } = useTelegram()
const { isMobile } = usePlatform()
const toastsPosition = computed(() => (isMobile.value ? 'top' : 'bottom'))
const modalConfig = useModalConfig()

/*
  по какой-то причине после fix extends resolutions
  сторы инитятся до всех плагинов
  и внутри него нет io
*/
const io = useSocket()
const userStore = useUserStore()
const { handleFullLogout } = userStore
const { activeBetListener } = useActiveBetsStore()

interface FullLogoutPayload {
  reason: 'authForbidden' | 'selfExcluded'
}

/**
 * если сокет пришлет reason === 'selfExcluded',
 * то моментального редиректа на главную не будет
 * @param payload
 */
function handleFullLogoutSocket(payload: FullLogoutPayload) {
  handleFullLogout({
    withoutRedirect: payload?.reason === 'selfExcluded',
  })
}

io.on('fullLogout', handleFullLogoutSocket)
io.on('betStatus', activeBetListener)

const { isAuthenticated, isManualLogin } = storeToRefs(userStore)

const { handleDepositModalShow } = useDepositBonusesStore()

watch(isAuthenticated, () => {
  /**
   * [10.03.24]
   * Текущая реализация работает, но имеет нюанс:
   * пока ```isManualLogin.value = true``` происходит в хуке useEmailLogin,
   * то редирект в функции handleSuccess компонента AuthorizationForm.vue
   * будет перекрываться данным редиректом
   * Для разрешения ситуации достаточно вынести установку значения isManualLogin
   * в упомянутую функцию, а здесь начать смотреть на массив [isAuthenticated, isManualLogin]
   */
  if (isAuthenticated.value && isManualLogin.value) {
    handleDepositModalShow()
  }
})

const { isOnline } = useOnline()
const { handleClick } = useGlobalOutcomeButtonClickHandler()

useBonusesRollingSocket()

const { t } = useI18n()
if (!import.meta.env.DEV) {
  /**
   * Эти данные распространяются на все приложение и при необходимости переписываются
   * В dev-режиме этого не делаем, потому что иначе
   * переходы с /error на главную страницу перестают работать
   */
  defineOgImageComponent('OgImageLogo')
}
useSeoMeta({
  ogTitle: t('pages.main'),
  ogSiteName: 'BetOne',
  ogDescription: t('about.titleContent'),
})
</script>

<style>
@import url('@st/fonts/SFProDisplay/index.css');

:root {
  scrollbar-gutter: stable;
  font-family: var(--sf-pro-display);
  background-color: var(--background-base);
}

/* hide scroll with modal */
.no-scroll {
  overflow: hidden;
  height: 100%;
}

/* hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}
</style>
