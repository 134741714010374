import { useIsNuxtReady, createInjectableHook } from '@st/use/composables'

function useRecaptchaScriptImlementation() {
  const config = useRuntimeConfig()
  const apiKey = config.public.recaptchaKey

  const scriptPath = `https://www.google.com/recaptcha/enterprise.js?render=${apiKey}`

  const { isNuxtReady } = useIsNuxtReady()

  const grecaptcha = useScript(
    {
      src: scriptPath,
      async: true,
      crossorigin: false,
      referrerpolicy: false,
    },
    {
      trigger: computed(() => isNuxtReady.value && !!apiKey),
      use: () => globalThis.grecaptcha?.enterprise,
    },
  )

  return grecaptcha
}

const [useRecaptchaScript, provideRecaptchaScript] = createInjectableHook(
  useRecaptchaScriptImlementation,
)

export { useRecaptchaScript, provideRecaptchaScript }
