import { sortBy } from '@st/utils'
import type { Quest } from '../types'
import { useQuestsDeps } from '../useDeps'

export const [useQuestsStore, provideQuestsStore] = createInjectableHook(
  defineStore('quests', () => {
    const stFetch = useRawStFetch()

    const activeQuests = ref<Quest[]>([])
    const { isAuthenticated } = useQuestsDeps()

    const isReady = ref(false)

    const { isNuxtReady } = useIsNuxtReady()

    async function fetchActiveQuests() {
      if (isAuthenticated.value) {
        const { data, error } = await stFetch('/carrot/promos/personal/find', {
          method: 'post',
          body: {
            status: ['ACTIVE'],
          },
        })
        // Чтобы не вызывать мисматчей запрос делаем сразу, но ref меняем после nuxtReady
        await until(isNuxtReady).toBeTruthy()
        isReady.value = true
        if (!error) activeQuests.value = data as Quest[]
      } else {
        const { data, error } = await stFetch('/carrot/promos/find', {
          method: 'post',
          body: {
            status: ['ACTIVE'],
          },
        })
        await until(isNuxtReady).toBeTruthy()
        isReady.value = true
        if (!error) activeQuests.value = data as Quest[]
      }
    }

    watch(isAuthenticated, (newValue, oldValue) => {
      if (isReady.value && newValue !== oldValue) fetchActiveQuests()
    })

    const io = useSocket()
    io.on('carrotPromosUpdated', () => fetchActiveQuests())

    const activeQuestsCounter = computed(() => activeQuests.value.length)

    const claimableRewardsCounter = computed<number>(
      () =>
        activeQuests.value.filter((quest) =>
          quest.steps.some((step) => {
            if (!step.personalState) return false

            const { rewardStatus, status } = step.personalState
            return status === 'COMPLETED' && rewardStatus === 'PENDING'
          }),
        ).length,
    )

    const sortedActiveQuests = computed(() =>
      sortBy(activeQuests.value, (quest) => [
        quest.steps.length > 1 ? 0 : 1,
        quest.personalState?.completeUntil ? 0 : 1,
        quest.id,
      ]),
    )

    return {
      activeQuestsCounter,
      fetchActiveQuests,
      activeQuests: sortedActiveQuests,
      isReady,
      claimableRewardsCounter,
    }
  }),
)
