import { useUserStore } from '@st/user/stores/useUserStore'

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.server) return undefined

  const { isReady, isAuthenticated } = storeToRefs(useUserStore())
  await until(isReady).toBeTruthy()
  if (isAuthenticated.value) return undefined
  return navigateTo({ query: { modal: 'login' } })
})
