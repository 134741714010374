<template>
  <div
    v-if="isLoading || (total && total > 0)"
    class="casino-bets-history"
    data-t="casino-bets-history"
  >
    <div v-if="!mobile" class="header-wrapper">
      <div class="header">
        <div class="header-item game">{{ t('casinoBetsHistory.game') }}</div>
        <div class="header-item bet-number">
          {{ t('casinoBetsHistory.betNumber') }}
        </div>
        <div class="header-item date">{{ t('casinoBetsHistory.date') }}</div>
        <div class="header-item bet-amount">
          {{ t('casinoBetsHistory.betAmount') }}
        </div>
        <div class="header-item bet-rate">
          {{ t('casinoBetsHistory.betOdds') }}
        </div>
        <div class="header-item bet-result">
          {{ t('casinoBetsHistory.result') }}
        </div>
      </div>
    </div>
    <CasinoBet
      v-for="casinoBet in casinoBets"
      :key="casinoBet.sessionId"
      :casino-bet="casinoBet as any"
      :mobile="props.mobile"
    />
    <template v-if="isLoading">
      <StSkeletonLoader
        v-for="i in 10"
        :key="i"
        height="52px"
        bg-color="var(--background-secondary)"
        :shimmer-opacity="0.28"
      />
    </template>
    <div v-if="total" ref="endOfTheList" class="end-of-list" />
  </div>
  <div v-else class="empty-list" :class="{ 'mobile-empty': mobile }">
    <img src="./empty.svg" alt="empty list" width="200" height="113" />
    <p>{{ t('casinoBetsHistory.noBets') }}</p>
  </div>
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@st/use/composables'
import CasinoBet from './CasinoBet.vue'

const props = withDefaults(
  defineProps<{
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)

const { t } = useI18n()

const {
  items: casinoBets,
  canLoadMore,
  total,
  isLoading,
  loadMore,
} = useInfiniteList({
  url: '/casino/session/round/find',
  perPage: 20,
  orderBy: [{ fieldName: 'updatedAt', sortOrder: 'DESC' }],
})

const endOfTheList = ref<HTMLDivElement>()

useInfiniteScroll({
  el: endOfTheList,
  loadMore: async () => {
    if (canLoadMore.value) {
      await loadMore()
    }
  },
  disabledObserver: computed(() => !canLoadMore.value),
  options: { rootMargin: '400px', threshold: 0 },
})
</script>

<style scoped>
.casino-bets-history {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
}

.header-wrapper {
  position: sticky;
  z-index: 1;
  top: 115px;
  background-color: var(--background-base);

  &::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: -4px;
    left: 0;

    height: 4px;

    background-color: var(--background-base);
  }
}

.header {
  display: flex;
  gap: var(--spacing-300);

  padding: var(--spacing-100) var(--spacing-150);

  background-color: var(--background-base);
  border-radius: var(--border-radius-100);
}

.header-item {
  flex: 10 1 0;
  min-width: 100px;
  font: var(--desktop-text-xs-medium);
  color: var(--text-quaternary);

  &.game {
    flex-grow: 15;
  }

  &.bet-rate {
    flex-grow: 5;
  }

  &.bet-amount,
  &.bet-rate,
  &.bet-result {
    text-align: right;
  }
}

.empty-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  align-items: center;
  justify-content: center;

  height: 100%;

  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);

  > p {
    margin: 0;
  }

  &.mobile-empty {
    gap: var(--spacing-200);
    margin: auto;
    padding-top: var(--spacing-600);

    p {
      margin: 0;
      font: var(--mobile-text-content-regular);
      color: var(--text-secondary);
    }
  }
}

.mobile-title {
  padding: var(--spacing-125) var(--spacing-100);
  padding-bottom: var(--spacing-075);
  font: var(--mobile-title-1-semibold);
}
</style>
