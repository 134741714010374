import type { paths } from '@st/api/paths'
import type { ResponseBody } from '@st/openapi-tools'
import type { MaybeUndefinedFields } from '@st/utils'
import type { Currency, Network } from '../interfaces'
import { truncateAddress } from '../helpers'
import { useCurrenciesStore } from './useCurrenciesStore'

type RawAddress = ResponseBody<paths, '/payment-address/find', 'post'>[number]
export type Address = RawAddress &
  MaybeUndefinedFields<{
    networkName: Network['name']
    networkCode: Network['code']
    networkDescription: Network['description']
  }> & {
    currencyCode: Currency['code']
    currencyIcon: Currency['icon']
    truncatedAddress: string
  }

export const useAddressesStore = defineStore('addresses', () => {
  const currenciesStore = useCurrenciesStore()
  const { currencies } = storeToRefs(currenciesStore)

  const {
    execute: getAddresses,
    refresh: refreshAddresses,
    data: addresses,
  } = useStFetch('/payment-address/find', {
    method: 'POST',
    immediate: false,
    watch: false,
  })

  const mappedAddreses = computed<Address[]>(() =>
    (addresses.value || []).map((address) => {
      const currency = currencies.value[address.currencyId]
      const network = currency.networks.find(
        (item) => item.id === address.networkId,
      )

      return {
        ...address,
        currencyIcon: currency.icon,
        networkName: network?.name,
        networkCode: network?.code,
        networkDescription: network?.description,
        currencyCode: currency.code,
        truncatedAddress: truncateAddress(address.address),
      }
    }),
  )

  const selectedId = ref<number>()

  const { execute: executeDeleteAddress, status: deleteStatus } = useStFetch(
    '/payment-address/delete',
    {
      method: 'POST',
      immediate: false,
      watch: false,
      body: computed(() => ({ id: selectedId.value as number })),
    },
  )

  async function deleteAddress(id: number) {
    if (!id) return
    selectedId.value = id

    await executeDeleteAddress()
    refreshAddresses()
  }

  return {
    addresses: mappedAddreses,
    getAddresses,
    refreshAddresses,
    deleteAddress,
    deleteStatus,
  }
})
