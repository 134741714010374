import type { IconName } from '@st/ui/components/StIcon/types'
import { type BonusTooltipType } from './composables/useBonusTooltip'
import type { GameCardProps } from './types'

export interface UserAccount {
  name: string
  code: string
  fiatBalance: string
  icon: IconName
  id: number
  currencyId: number
  type: 'real' | 'bonus' | 'freebet'
  balance: string
  allowedForDeposit: boolean
  allowedForWithdrawal: boolean
  bonusAccountAmount: string
}

const [useBonusesDeps, provideBonusesDeps] = createEntityDeps<{
  bonusesTooltips: Record<BonusTooltipType, Component>
  GameCard: Component<GameCardProps>
  getCurrencyIcon: (currencyCode: string) => IconName
  getCurrencyIconById: (cyrrencyId: number) => IconName
  getCurrencyCodeById: (cyrrencyId: number) => string
  isAuthenticated: Ref<boolean>
  appCurrencyCode: Ref<string>
  referralCode: Ref<string | undefined>
  getCategoryNameById: (categoryId: number) => string | null | undefined
  getAccountById: (accountId: number) => UserAccount | undefined
  getReferralCurrency: (
    currencyId: number,
  ) => { icon: IconName; code: string } | undefined
  convert: (
    amount: string | number,
    {
      from,
      to,
    }: {
      from: number | string
      to: number | string
    },
  ) => string
  isGameLoading: Ref<boolean>
}>('bonuses')

export { useBonusesDeps, provideBonusesDeps }
