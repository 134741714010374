export interface Params {
  duration: number
  delay?: number
  repeatTimes?: number
}

export const DEFAULT_PARAMS: Required<Params> = {
  duration: 100,
  repeatTimes: 1,
  delay: 50,
}

export function useVibration(params: Params = DEFAULT_PARAMS) {
  const pattern: number[] = []
  const { duration, repeatTimes, delay } = { ...DEFAULT_PARAMS, ...params }

  for (let i = 0; i < repeatTimes; i += 1) {
    pattern.push(duration)
    if (i !== repeatTimes - 1) {
      pattern.push(delay)
    }
  }

  function vibrate() {
    if (!navigator.vibrate) return
    navigator.vibrate(pattern)
  }

  return vibrate
}
