import type { paths } from '@st/api/paths'
import type { ResponseError } from '@st/openapi-tools'
import { useAnalytics } from '@st/analytics/composables/useAnalytics'
import { delay } from '@st/utils'
import { useCasinoDeps } from '@st/casino/useDeps'

export type DemoInitError = ResponseError<
  paths,
  '/casino/game/init/demo',
  'post'
>
export type RealInitError = ResponseError<paths, '/casino/game/init', 'post'>
export type GameInitError = DemoInitError | RealInitError
export interface LauchGameState {
  gameId: number | null
  state: 'idle' | 'loading' | 'success' | 'error'
}

interface LauchGameOptions {
  gameId: number
  gameMode: 'real' | 'demo'
}

interface LaunchGameResult {
  result:
    | 'success'
    | 'initError'
    | 'authError'
    | 'geoError'
    | 'authErrorWithDemoAvailable'
    | 'geoErrorWithDemoAvaiable'
  error?: GameInitError
}

export const useGameInitStore = defineStore('gameInit', () => {
  const initError = ref<GameInitError | null>(null)
  const frameUrl = ref<string | null>(null)
  const sessionId = ref<string | null>(null)
  const gameMode = ref<'demo' | 'real' | null>(null)
  const { sendEvent } = useAnalytics()

  const stFetch = useRawStFetch()
  const {
    userSettings,
    isAuthenticated,
    isReady: isReadyAuth,
    activeAccount,
    getCurrencyIdByCode,
  } = useCasinoDeps()
  const { isReady: isReadyGeo, isAvailable } = useGeoAvailability()
  const url = useRequestURL()
  const gameCurrencyId = computed(() => {
    const code = userSettings.value?.preferredCurrencyCodeForCasino
    return getCurrencyIdByCode(code)
  })

  function clear() {
    initError.value = null
    frameUrl.value = null
    sessionId.value = null
    gameMode.value = null
  }

  async function initRealGame(gameId: number) {
    await until(
      () => !!gameCurrencyId.value && !!activeAccount.value?.id,
    ).toBeTruthy()

    const { data, error } = await stFetch('/casino/game/init', {
      method: 'post',
      body: {
        gameId,
        gameCurrencyId: gameCurrencyId.value as number,
        accountId: activeAccount.value?.id,
        historyUrl: `${url.origin}/personal/bets`,
        depositUrl: `${url.origin}/?modal=payments`,
        returnUrl: `${url.origin}/casino`,
      },
    })

    if (error) {
      initError.value = error
    } else {
      clear()
      sessionId.value = data.sessionId
      frameUrl.value = data.url
      gameMode.value = 'real'

      sendEvent('initGame', {
        sessionId: data.sessionId,
        gameId,
        gameMode: 'real',
      })
    }
  }

  async function initDemoGame(gameId: number) {
    const { data, error } = await stFetch('/casino/game/init/demo', {
      method: 'post',
      body: {
        gameId,
        returnUrl: `${url.origin}/casino`,
      },
    })

    if (error) {
      initError.value = error
    } else {
      clear()
      gameMode.value = 'demo'
      frameUrl.value = data.url

      sendEvent('initGame', {
        gameId,
        gameMode: 'demo',
      })
    }
  }

  async function checkIfGameHasDemo(gameId: number) {
    const {
      data: { data: games },
    } = await stFetch('/casino/game/find', {
      method: 'post',
      body: {
        pagination: {
          page: 0,
          perPage: 1,
          orderBy: [
            {
              fieldName: 'gameId',
              sortOrder: 'ASC',
            },
          ],
        },
        params: {
          gameId: [gameId],
        },
      },
    })
    return Boolean(games?.[0]?.hasDemoMode)
  }

  async function launchGame(
    options: LauchGameOptions,
  ): Promise<LaunchGameResult> {
    if (options.gameMode === 'demo') {
      await initDemoGame(options.gameId)
      if (initError.value) {
        return {
          result: 'initError',
          error: initError.value,
        }
      }
      return {
        result: 'success',
      }
    }

    await until(isReadyGeo).toBeTruthy()
    if (!isAvailable.value) {
      const hasDemo = await checkIfGameHasDemo(options.gameId)
      if (hasDemo) {
        return {
          result: 'geoErrorWithDemoAvaiable',
        }
      }

      return {
        result: 'geoError',
      }
    }

    await until(isReadyAuth).toBeTruthy()
    if (!isAuthenticated.value) {
      const hasDemo = await checkIfGameHasDemo(options.gameId)
      if (hasDemo) {
        return {
          result: 'authErrorWithDemoAvailable',
        }
      }

      return {
        result: 'authError',
      }
    }

    await initRealGame(options.gameId)
    if (initError.value) {
      return {
        result: 'initError',
        error: initError.value,
      }
    }
    return {
      result: 'success',
    }
  }

  const launchingGameState = ref<LauchGameState>({
    gameId: null,
    state: 'idle',
  })
  async function launchGameWithStates(
    options: LauchGameOptions,
  ): Promise<LaunchGameResult> {
    launchingGameState.value.gameId = options.gameId
    launchingGameState.value.state = 'loading'

    const launchResult = await launchGame(options)

    if (launchResult.result === 'success') {
      launchingGameState.value.state = 'success'
      // wait for 600ms to show game loading animation
      await delay(600)
    } else launchingGameState.value.state = 'error'

    launchingGameState.value.gameId = null
    launchingGameState.value.state = 'idle'
    return launchResult
  }

  const isGameLoading = computed(
    () => launchingGameState.value.state === 'loading',
  )

  return {
    launchGame,
    launchGameWithStates,
    frameUrl,
    sessionId,
    gameMode,
    initError,
    launchingGameState,
    checkIfGameHasDemo,
    isGameLoading,
  }
})
