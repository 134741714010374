import { useModalConfig } from '../modal.config'
import auth from './auth'
import geo from './geo'
import search from './search'
import kyc from './kyc'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const modal = to?.query?.modal as string | undefined
  if (!modal) return undefined

  const config = useModalConfig().value?.[modal]
  if (!config) return undefined

  const { middleware } = config
  if (!middleware?.length) return undefined

  const middlewareByName = {
    auth,
    geo,
    search,
    kyc,
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of middleware) {
    const navigationGuard = middlewareByName[key]
    // eslint-disable-next-line no-continue
    if (!navigationGuard) continue

    // eslint-disable-next-line no-await-in-loop
    const result = await navigationGuard(to, from)
    if (result) return result
  }

  return undefined
})
