import type { Ref, ComputedRef } from 'vue'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import type { Category, Game, GameLimits } from '../../types'
import { usePragmaticDGA } from '../../composables/usePragmaticDGA'

interface Label {
  code: string
  name: string
  color: string
}
interface TooltipLabel {
  code: string
  name: string
  link: string
}

interface UseGameCardReturn {
  labels: ComputedRef<Label[]>
  tooltipLabels: ComputedRef<TooltipLabel[]>
  imagePath: ComputedRef<string>
  showPlayers: ComputedRef<boolean>
  notAvailableInRegion: ComputedRef<boolean>
  isGameHasLimits: Ref<boolean>
  limits: ComputedRef<GameLimits | undefined>
}

export default function useGameCard(
  game: Ref<Game | null>,
  showPlayerCount?: Ref<boolean>,
): UseGameCardReturn {
  const { getCategoryByCode } = useCasinoStore()
  const { settings } = storeToRefs(useSettingsStore())

  const gameCategories = computed(() => {
    if (!game.value) return []

    return game.value.categories
      .map(({ code }) => getCategoryByCode(code) as Category)
      .filter(Boolean)
  })

  const { checkGameHasFreespins } = useCasinoStore()
  const isGameHaveFreespins = computed(() => {
    if (!game.value) return false
    return checkGameHasFreespins(game.value.gameId)
  })
  const { t } = useI18n()
  const rawLabels = computed(() =>
    gameCategories.value
      .filter((category) => category.visibleForGameCard)
      .slice(0, 3)
      .map((category) => ({
        code: category.code,
        name: category.labelName || category.name || category.code,
        color: category.labelColor
          ? `#${category.labelColor}`
          : 'var(--palette-brand-600)',
      })),
  )

  const labels = computed(() =>
    isGameHaveFreespins.value
      ? [
          {
            code: 'freespin',
            name: t('casinoGames.freeSpinsTag'),
            color: ' #4DB351',
          },
        ]
      : rawLabels.value,
  )

  const tooltipLabels = computed(() =>
    gameCategories.value
      .filter((category) => {
        if (
          category.type !== 'provider' &&
          category.type !== 'main' &&
          !(category.type === 'other' && category.parentCode)
        ) {
          return false
        }
        return true
      })
      .map((category) => {
        const linkPefix =
          category.type === 'provider'
            ? 'casino/providers/'
            : `casino/categories/`
        const fullLink = category.parentCode
          ? `/${linkPefix}${category.parentCode}/${category.code}`
          : `/${linkPefix}${category.code}`

        return {
          code: category.code,
          name: category.name || category.code,
          link: fullLink,
        }
      }),
  )

  const imagePath = computed(() => `url('${game.value?.image}'`)

  const showPlayers = computed(() => {
    if (!showPlayerCount?.value) return false
    if (
      game.value?.playersCount &&
      settings.value?.casinoGameMinPlayersNumberToShow
    ) {
      return (
        game.value?.playersCount >=
        settings.value.casinoGameMinPlayersNumberToShow
      )
    }
    return false
  })

  const notAvailableInRegion = computed(() =>
    game.value ? game.value.forbiddenByCountry : false,
  )

  const { isGameHasLimits, limits } = usePragmaticDGA(() => game.value?.gameId)

  return {
    labels,
    tooltipLabels,
    imagePath,
    showPlayers,
    notAvailableInRegion,
    isGameHasLimits,
    limits,
  }
}
