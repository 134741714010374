export function useApplicationType() {
  const { $pwa, $isTelegramMiniApp } = useNuxtApp()

  const isTelegramMiniApp = computed(
    () => ($isTelegramMiniApp as Ref<boolean>)?.value,
  )
  const isPwa = computed(() => !!($pwa as any)?.isPWAInstalled)
  const isWeb = computed(() => !isPwa.value && !isTelegramMiniApp.value)

  return {
    isTelegramMiniApp,
    isPwa,
    isWeb,
  }
}
