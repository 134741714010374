<template>
  <StTooltip trigger="hover" placement="top">
    <template #activator>
      <StButton
        size="m"
        type="gray"
        :icon="favoriteGameIcon"
        :icon-color="favoriteGameIconColor"
        data-t="favourite-game-jt3i"
        :data-t-is-favorite="isFavoriteGame"
        @click="handleFavoriteClick"
      />
    </template>
    <div class="tooltip-content">{{ favoriteGameTooltip }}</div>
  </StTooltip>
</template>

<script setup lang="ts">
const { gameId } = defineProps<{
  gameId: number
}>()

const { t } = useI18n()

const { isFavoriteGame, handleFavoriteClick, favoriteGameIconColor } =
  useFavoriteGame(() => gameId)

const favoriteGameIcon = computed(() =>
  isFavoriteGame.value ? 'heart-solid' : 'heart',
)

const favoriteGameTooltip = computed(() =>
  isFavoriteGame.value ? t('casino.favoritesRemove') : t('casino.favoritesAdd'),
)
</script>

<style scoped>
.tooltip-content {
  padding: var(--spacing-100) var(--spacing-150);
  font: var(--desktop-text-xs-medium);
}
</style>
