import {
  lengthBetween,
  required,
  useForm,
  atLeastCapitalLetter,
  atLeastLowerLetter,
  onlyLatinOrDigitsOrSpecialCharacter,
} from '@st/validate'

const PASSWORD_MIN_LENGTH = 8
const PASSWORD_MAX_LENGTH = 20

export function usePasswordValidator() {
  const { t } = useNuxtApp().$i18n

  const passwordValidators = computed(() => [
    {
      rule: (value: string) =>
        atLeastCapitalLetter(value) && atLeastLowerLetter(value),
      errorMessage: t('registration.passwordOneCapitalOneLowerSymbols'),
    },
    {
      rule: lengthBetween(PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH),
      errorMessage: t('registration.passwordLengthBetween', {
        min: PASSWORD_MIN_LENGTH,
        max: PASSWORD_MAX_LENGTH,
      }),
    },
    {
      rule: onlyLatinOrDigitsOrSpecialCharacter,
      errorMessage: t('registration.passwordOnlyLatin'),
    },
  ])

  const {
    fields: { password },
    values,
    isValid,
  } = useForm({
    fieldsSchema: {
      password: {
        initialValue: '',
        validators: [
          {
            rule: required,
            triggerErrorDisplay: 'never',
          },
          ...passwordValidators.value,
        ],
      },
    },
  })

  return {
    passwordValidators,
    password,
    values,
    isValid,
  }
}
