export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.server) return undefined

  const { isReady, isAvailable, countryCode, ip } = useGeoAvailability()
  await until(isReady).toBeTruthy()
  if (isAvailable.value) return undefined
  return navigateTo({
    query: {
      modal: 'restriction',
      countryCode: countryCode.value,
      ip: ip.value,
    },
  })
})
