type CommonPages =
  | 'main'
  | 'error'
  | 'about'
  | 'bonus-info'
  | 'bonuses'
  | 'quests'
  | 'refer-and-earn'
  | 'refer-info'
  | 'support'
  | 'unsubscribe'
  | 'promo-registration'
  | 'error'
  | 'pwa'
  // casino
  | 'casino'
  | 'casino-favorites'
  | 'casino-freespins'
  | 'casino-recent'
  | 'casino-restriction'
  | 'casino-game'
  | 'casino-providers'
  | 'casino-provider'
  | 'casino-category'
  | 'casino-subcategory'
  // tournaments
  | 'tournaments'
  | 'tournament'
  // promotions
  | 'promotions'

type SportPagesCommon<T extends 'sport' | 'cybersport'> =
  | T
  | `${T}-live`
  | `${T}-upcoming`
  | `${T}-category`
  | `${T}-tournament`
  | `${T}-match`
type SportPages =
  | SportPagesCommon<'cybersport'>
  | SportPagesCommon<'sport'>
  | 'sport-all'
  | 'sport-type'
type PersonalPages =
  | 'personal-bets'
  | 'personal-kyc'
  | 'personal-profile'
  | 'personal-transactions'
  | 'personal-wallet'

type DesktopOnlyPages = 'cybersport-all'
type MobileOnlyPages = 'personal-settings'

type SitePage =
  | CommonPages
  | SportPages
  | PersonalPages
  | DesktopOnlyPages
  | MobileOnlyPages

interface ProvidedData {
  casinoProviderPageTitle?: MaybeRefOrGetter<string>
}

export function useSeoData(page: SitePage, providedData?: ProvidedData) {
  const { t } = useI18n()

  const seoTitles = computed<Record<SitePage, string | null>>(() => ({
    // no seo
    about: null,
    unsubscribe: null,
    'personal-kyc': null,
    'personal-bets': null,
    // common
    main: t('pages.main'),
    error: t('pages.error'),
    'bonus-info': t('pages.bonuses'),
    bonuses: t('pages.bonuses'),
    quests: t('pages.quests'),
    'refer-info': t('pages.referral'),
    'refer-and-earn': t('pages.referral'),
    support: t('pages.support'),
    'promo-registration': t('pages.main'),
    pwa: t('pages.pwa'),
    // casino
    casino: t('pages.casino.main'),
    'casino-favorites': t('pages.casino.favorites'),
    'casino-freespins': t('pages.casino.freespins'),
    'casino-recent': t('pages.casino.recentGames'),
    'casino-restriction': t('pages.casino.restriction'),
    'casino-game': t('pages.casino.main'),
    'casino-providers': t('pages.casino.providers'),
    'casino-provider': toValue(providedData?.casinoProviderPageTitle) ?? null,
    'casino-category': t('pages.casino.main'),
    'casino-subcategory': t('pages.casino.main'),
    // tournaments
    tournaments: t('pages.tournaments'),
    tournament: t('pages.tournament'),
    // promotions
    promotions: t('pages.promotions'),
    // sport
    sport: t('pages.sport.real'),
    'sport-all': t('pages.sport.all'),
    'sport-live': t('pages.sport.live'),
    'sport-upcoming': t('pages.sport.upcoming'),
    'sport-type': t('pages.sport.real'),
    'sport-category': t('pages.sport.real'),
    'sport-tournament': t('pages.sport.real'),
    'sport-match': t('pages.sport.real'),
    // cybersport
    cybersport: t('pages.sport.cyber'),
    'cybersport-all': t('pages.sport.all'),
    'cybersport-live': t('pages.sport.live'),
    'cybersport-upcoming': t('pages.sport.upcoming'),
    'cybersport-category': t('pages.sport.cyber'),
    'cybersport-tournament': t('pages.sport.cyber'),
    'cybersport-match': t('pages.sport.cyber'),
    // personal
    'personal-profile': t('pages.personal.profile'),
    'personal-transactions': t('pages.personal.transactions'),
    'personal-wallet': t('pages.personal.wallet'),
    'personal-settings': t('pages.personal.settings'),
  }))

  const title = seoTitles.value[page]

  if (!title) return
  useHead({ title })
  useSeoMeta({ ogTitle: title })
}
