import useNumberFormatter, {
  type UseNumberFormatReturn,
  type UseNumberFormatOptions,
} from './useNumberFormatter'

/**
 * Хук для форматирования криптовалюты.
 * Формат НЕ зависит от локали i18n.
 * Каждый параметр может быть передан как Ref для реактивного обновления.
 * Максимум 8 знаков после запятой
 *
 * @example
 * const { formatCrypto } = useCryptoFormatter({ currency: 'BTC' })
 * formatCrypto(1000.1234567891) => '1,000.12345679 BTC'
 */
export default function useCryptoFormatter(
  options: UseNumberFormatOptions = {},
): UseNumberFormatReturn {
  const fullOptions: UseNumberFormatOptions = { ...options }

  if (options.currency || options.style === 'currency') {
    fullOptions.style = 'currency'
    fullOptions.currency = 'BTC'
  }

  const { format: numberFormat } = useNumberFormatter({
    maximumFractionDigits: 8,
    minimumFractionDigits: 0,
    ...fullOptions,
  })

  const { format: zeroFormat } = useNumberFormatter({
    ...fullOptions,
    maximumFractionDigits: 8,
    minimumFractionDigits: 2,
    trailingZeroDisplay: 'auto',
  })

  function format(amount: number | string, currency?: string): string {
    const formatted =
      Number(amount) === 0 ? zeroFormat(amount) : numberFormat(amount)

    const optionsCurrency = currency ?? toValue(options.currency)
    if (!optionsCurrency) {
      return formatted
    }

    const withoutBTC = formatted.replace(/\s*BTC\s*/i, '').trim()

    return `${withoutBTC} ${optionsCurrency}`
  }

  return {
    format,
  }
}
