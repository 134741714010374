<template>
  <div class="outcome-wrapper" data-t="bet-outcome">
    <div class="outcome-separator"></div>
    <div class="outcome">
      <div class="column">
        <span class="small">{{ i18n.t('bets.bet') }}</span>
        <p class="outcome-name">{{ outcomeName }}</p>
        <p class="market-name">{{ marketName }}</p>
      </div>
      <div class="column">
        <HistoryBetBreadcrumbs
          :breadcrumbs="[
            outcome.sbSportName,
            outcome.sbCategoryName,
            outcome.sbTournamentName,
          ]"
        />

        <div class="competitors">
          <NuxtI18nLink
            v-if="outcome.event?.url"
            :to="outcome.event?.url"
            class="overlay-link"
          />
          <p class="competitor" :title="competitorsNames.home">
            {{ competitorsNames.home }}
          </p>
          {{ ' - ' }}
          <p class="competitor" :title="competitorsNames.away">
            {{ competitorsNames.away }}
          </p>
        </div>
        <div class="status">
          <EventStatusBar
            v-if="outcome.event"
            :event="outcome.event"
            with-scores
          />
          <div v-else class="history-result">
            <span class="small"> {{ i18n.t('bets.ended') }}</span>
            <span class="small active"> {{ historyScore }} </span>
            <span class="small"> {{ historyMatchResult }}</span>
          </div>
        </div>
      </div>
      <div class="column info">
        <div class="info-wrapper">
          <div class="column">
            <template v-if="props.bet.type === 'ordinary'">
              <span class="small right">
                {{
                  props.bet.accountType === 'real'
                    ? i18n.t('bets.bid')
                    : i18n.t('bets.freebet')
                }}
              </span>
              <div class="amount">
                <strong data-t="bidAmount">
                  {{ format(Number(props.bet.amount)) }}
                </strong>
                <StIcon v-if="currencyIcon" :name="currencyIcon" :size="16" />
              </div>
            </template>
          </div>
          <div class="column">
            <span class="small right">
              {{ i18n.t('bets.rate') }}
            </span>
            <p v-if="isRefunded" class="rate">-</p>
            <p v-else class="rate">
              {{ truncRate(Number(props.outcome.rate)) }}
            </p>
          </div>
          <div class="column">
            <template v-if="props.bet.type === 'ordinary'">
              <span class="small right">
                {{ resultText }}
              </span>
              <div class="amount result-amount">
                <strong data-t="resultAmount" :class="betWinLoseStatus">
                  {{ resultAmount }}
                </strong>
                <StIcon v-if="currencyIcon" :name="currencyIcon" :size="16" />
              </div>
            </template>
            <div
              v-else
              class="icon-status"
              :style="{ color: selfStatusInExpress.color }"
            >
              <StIcon
                v-if="selfStatusInExpress.name"
                :name="selfStatusInExpress.name"
                :size="16"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { truncRate } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import {
  useHistoryBet,
  useHistoryOutcome,
} from '../../../composables/useHistoryBet'
import type { ExtendedBetOutcome, Bet } from '../../../types'
import HistoryBetBreadcrumbs from './HistoryBetBreadcrumbs.vue'

interface Props {
  outcome: ExtendedBetOutcome
  bet: Bet
  cashOutAmount?: number
  currencyIcon: IconName
}

const props = defineProps<Props>()
const { bet, outcome } = toRefs(props)
const i18n = useI18n()
const { format } = useCryptoFormatter()

const { resultAmount, betWinLoseStatus, resultText } = useHistoryBet(bet)

const {
  outcomeName,
  marketName,
  competitorsNames,
  historyMatchResult,
  historyScore,
  selfStatusInExpress,
  isRefunded,
} = useHistoryOutcome(outcome)
</script>

<style scoped>
.outcome-wrapper {
  position: relative;
}

.outcome-separator {
  position: absolute;
  top: -6px;
  right: 0;
  left: 0;

  height: 8px;

  background-image: url('bet-separator.svg');
  background-repeat: repeat-x;
  background-position: top;

  &::before,
  &::after {
    content: '';

    position: absolute;
    top: -5px;

    display: block;

    width: 12px;
    height: 12px;

    background-color: var(--background-base);
    border-radius: var(--border-radius-150);
  }

  &::before {
    left: -18px;
  }

  &::after {
    right: -18px;
  }
}

.outcome {
  display: flex;
  gap: var(--spacing-250);
  align-items: flex-start;
  padding: var(--spacing-100) 0 var(--spacing-125) 0;
}

.column {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  min-width: 0;

  &.info {
    flex-basis: 10%;
  }
}

.outcome-name {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}

.market-name {
  overflow: hidden;

  margin: 0;

  font: var(--desktop-text-xs-semibold);
  color: var(--text-primary);
  text-overflow: ellipsis;
}

.small {
  overflow: hidden;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;

  &.active {
    margin: 0 var(--spacing-075);
    color: var(--text-primary);
  }
}

.right {
  text-align: right;
}

.competitors {
  position: relative;
  display: flex;
  align-items: center;
}

.competitor {
  overflow: hidden;

  max-width: 174px;
  margin: 0;

  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  display: flex;
  gap: var(--spacing-075);
}

.rate {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
  text-align: right;
}

.info-wrapper {
  display: flex;
  gap: var(--spacing-050);
}

.amount {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: flex-end;

  strong {
    font: var(--desktop-text-sm-semibold);
    color: var(--text-primary);
    text-align: right;

    &.win,
    &.cashout {
      color: var(--system-success);
    }

    &.lose {
      color: var(--text-secondary);
    }
  }
}

.icon-status {
  display: flex;
  margin-top: 19px; /* stylelint-disable-line */
  margin-left: auto;
}

.status {
  display: flex;
}

.history-result {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.overlay-link {
  position: absolute;
  inset: 0;
}

.result-amount {
  .st-icon {
    min-width: 16px;
  }
}
</style>
