import { useRegularBonusesStore } from './useRegularBonusesStore'
import { useFreebetsStore } from './useFreebetsStore'
import { useFreespinsStore } from './useFreespinsStore'
import { useDepositBonusesStore } from './useDepositBonusesStore'

export const [useBonusesCountStore, provideBonusesCountStore] =
  createInjectableHook(
    defineStore('bonusesCount', () => {
      const { count: freebetsCount } = storeToRefs(useFreebetsStore())
      const { count: freespinsCount } = storeToRefs(useFreespinsStore())
      const { regularBonusesCount } = storeToRefs(useRegularBonusesStore())
      const { bonusesCount: depositBonusesCount } = storeToRefs(
        useDepositBonusesStore(),
      )

      const bonusesCount = computed(
        () =>
          freebetsCount.value +
          freespinsCount.value +
          regularBonusesCount.value +
          depositBonusesCount.value,
      )

      return {
        bonusesCount,
        freebetsCount,
        freespinsCount,
        depositBonusesCount,
      }
    }),
  )
