import type { IconName } from '@st/ui/components/StIcon/types'
import { createEntityDeps } from '@st/use/composables'

const [useVerificationDeps, provideVerificationDeps] = createEntityDeps<{
  userKycLevel: Ref<number | null>
  isReadyUser: Ref<boolean>
  isAuthenticated: Ref<boolean>
  isReadyTags: Ref<boolean>
  hasSkipKycLevelCheckOnDepositTag: Ref<boolean>
  appCurrencyCode: Ref<string>
  getCurrencyIcon: (currencyId: number) => IconName
  getFiatAmount: (currencyId: number, amount: string) => string
  setKycLevel: (level: number) => void
}>('verification')

export { useVerificationDeps, provideVerificationDeps }
