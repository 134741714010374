/**
 * Создает хук с возможностью замены реализации на уровне родительского компонента (под капотом - provide()).
 * Данная возможность используется преимущественно в тестах.
 * Для лучшей тестируемости компонентов рекомендуется все хуки создавать через данную функцию.
 *
 * @param hook Функция, реализующая хук
 *
 * @return [useHook, provideHook]
 * Возвращает кортеж из двух функций. Первая - вызывает хук, вторая - заменяет реализацию.
 */
export function createInjectableHook<A extends unknown[], R>(
  hook: (...args: A) => R,
): [(...args: A) => R, (hookToProvide: (...args: A) => R) => void] {
  const key = Symbol('injectable hook')

  const useHook = (...args: A) => inject(key, () => hook, true)(...args)

  const provideHook = (hookToProvide: (...args: A) => R) =>
    provide(key, hookToProvide)

  return [useHook, provideHook]
}
