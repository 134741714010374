<template>
  <div class="user-account" :class="type" data-t="user-account">
    <div class="header-panel" @click.stop>
      <span class="header-panel-title">
        {{ t('payments.wallet.myAssets') }}
      </span>
      <div class="header-panel-toggle">
        <StToggle v-model="hideNulls" size="s" data-t="header-panel-toggle" />
        <span class="header-panel-subtitle">
          {{ t('payments.wallet.hideNulls') }}
        </span>
      </div>
    </div>
    <div
      v-if="
        !hideHeader &&
        (type === 'default' || type === 'mobile') &&
        realAccounts.length
      "
      class="curency-header"
    >
      <div class="wrapper-left">
        <div class="header-active">
          {{ t('payments.account.headerActive') }}
        </div>
      </div>
      <div class="wrapper-right">
        <div class="header-balance">
          {{ t('payments.account.headerBalance') }}
        </div>
        <div v-if="type === 'default'" class="space-right"></div>
      </div>
    </div>
    <div v-if="!realAccounts.length" class="empty-accounts">
      <img :src="emptyAccounts" class="empty-image" alt="empty" />
      <span class="empty-accounts-text">
        {{ t('payments.wallet.hiddenAccounts') }}
      </span>
    </div>
    <template v-for="account in realAccounts" v-else :key="account.id">
      <div
        class="currency"
        :class="{ disabled: !account.isAvailable }"
        data-t="currency"
        :data-is-active="account.code === activeAccount?.code"
        :data-currency-code="account.code"
        @click="setNewActiveAccount(account.id)"
      >
        <div class="wrapper-left">
          <div
            class="radio"
            data-t="currency-radio-button-r34c"
            :class="{ active: account.code === activeAccount?.code }"
          />
          <div class="currency-logo">
            <StIcon
              :name="account.icon"
              :size="type === 'small' ? 24 : 32"
              class="icon"
            />
          </div>
          <div class="currency-content">
            <div class="currency-name">
              <div class="short-name" data-t="short-name">
                {{ account.code }}
              </div>
              <div class="full-name" data-t="full-name">
                {{ getAccountName(account) }}
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper-right">
          <div class="balance">
            <div class="balance-crypto" data-t="balance-crypto">
              {{ formatCrypto(account.balance) }}
            </div>
            <BonusBalance
              v-if="+account.bonusAccountAmount > 0"
              :amount="account.bonusAccountAmount"
            />
            <div v-else class="balance-fiat" data-t="balance-fiat">
              {{ `~${formatCurrency(account.fiatBalance)}` }}
            </div>
          </div>
          <div v-if="type === 'default'" class="balance-actions">
            <div
              v-if="account.code === activeAccount?.code"
              class="active-button"
              data-t="active-account"
            >
              {{ t('payments.account.active') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <SiteAvailableCrypto
      v-if="!hideSiteCrypto"
      class="site-currencies"
      background="primary"
    />
    <div v-if="isOpenWalletButtonShown" class="wallet-button-wrapper">
      <StButton
        class="wallet-button"
        icon="wallet-solid"
        type="gray"
        is-left-icon
        to="/personal/wallet"
        data-t="open-wallet-button-bfxp"
      >
        {{ t('payments.wallet.openWallet') }}
      </StButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ExtendedUserAccount } from '../../composables/useAllowedAssets'
import { useAccountsStore } from '../../stores/useAccountsStore'
import { useCurrenciesStore } from '../../stores/useCurrenciesStore'
import emptyAccounts from './parts/empty.svg'
import BonusBalance from '../BonusBalance/BonusBalance.vue'
import { useAllowedAssets } from '../../composables/useAllowedAssets'

interface Props {
  type?: 'default' | 'small' | 'mobile'
  hideHeader?: boolean
  hideSiteCrypto?: boolean
}

const emit = defineEmits<{
  accountSelect: []
}>()

const { type = 'default', hideHeader, hideSiteCrypto } = defineProps<Props>()

const accountStore = useAccountsStore()
const { updateActiveAccount } = accountStore

const { activeAccount, hideNulls } = storeToRefs(accountStore)
const { realAccounts } = useAllowedAssets({ omitZeroBalanceFiltration: false })

const currenciesStore = useCurrenciesStore()
const { appCurrency } = storeToRefs(currenciesStore)

const { t } = useI18n()
const { format: formatCrypto } = useCryptoFormatter()
const { format: formatCurrency } = useCurrencyFormatter({
  currency: appCurrency.value.code,
})

const isOpenWalletButtonShown = computed(
  () => type === 'small' || (type === 'mobile' && hideHeader),
)

function setNewActiveAccount(accountId: number): void {
  updateActiveAccount(accountId)
  emit('accountSelect')
}

function getAccountName(account: ExtendedUserAccount): string {
  let res = account.name
  if (!account.isAvailable) {
    res += ` (${t('payments.siteAvailableCrypto.siteOnly')})`
  }
  return res
}
</script>

<style scoped>
.user-account {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  background-color: var(--background-primary);
}

.radio {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--spacing-200);
  height: var(--spacing-200);

  border: 1.5px solid var(--border-primary);
  border-radius: var(--border-radius-full);

  transition: all 0.2s ease;

  &.active {
    border-color: var(--text-link);
  }

  &.active::after {
    content: '';

    width: 10px;
    height: 10px;

    background-color: var(--text-link);
    border-radius: var(--border-radius-full);
  }
}

.currency-logo {
  line-height: 0;
}

.currency-content {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: var(--border-radius-050);
}

.currency {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 56px;
  padding-left: var(--spacing-200);

  border-radius: var(--border-radius-100);

  transition: all 0.2s ease;

  &:hover {
    background-color: var(--background-tertiary);

    .radio {
      border-color: var(--text-link);
    }
  }

  &.disabled {
    pointer-events: none;

    .currency-logo,
    .currency-content,
    .wrapper-right {
      opacity: 0.4;
    }
  }
}

.currency-name {
  .short-name {
    font: var(--desktop-text-md-semibold);
  }

  .full-name {
    font: var(--desktop-text-xs-medium);
    color: var(--palette-light-600);
  }
}

.balance {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .balance-crypto {
    font: var(--desktop-text-md-semibold);
  }

  .balance-fiat {
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
}

.balance-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 121px;

  /* stylelint-disable */
  padding-right: 18px;

  .active-button {
    padding: var(--spacing-025) var(--spacing-100);

    font: var(--desktop-text-xs-medium);
    text-align: center;

    background-color: var(--button-primary-default);
    border-radius: var(--border-radius-075);
  }
}

.wrapper-left,
.wrapper-right {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
  height: 100%;
}

.curency-header {
  display: flex;
  justify-content: space-between;

  min-height: 16px;
  padding: var(--spacing-075) 0;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);

  .header-active {
    padding-left: var(--spacing-600);
  }

  .space-right {
    min-width: 121px;
  }
}

.user-account.small {
  max-width: 360px;

  .wrapper-left,
  .wrapper-right {
    gap: var(--spacing-150);
  }

  .currency {
    min-height: 48px;
    padding: var(--spacing-050) var(--spacing-150);

    &:hover {
      border-radius: var(--border-radius-075);
    }
  }
}

.user-account.mobile {
  gap: 0;
  background-color: transparent;

  .curency-header {
    margin: 0;

    .wrapper-right {
      margin-right: var(--spacing-200);
      font: var(--mobile-caption-1-medium);
    }
  }

  .currency {
    position: relative;
    min-height: 52px;
    margin-right: var(--spacing-200);
    background-color: transparent;

    &::after {
      content: '';

      position: absolute;
      right: calc(-1 * var(--spacing-200));
      bottom: 0;

      width: calc(100% - var(--spacing-400));

      border-bottom: var(--border-width-medium) solid var(--border-secondary);
    }
  }

  .currency-content {
    margin-left: calc(-1 * var(--spacing-050));
  }

  .short-name {
    margin-bottom: var(--spacing-025);
    font: var(--mobile-text-medium);
  }

  .full-name {
    font: var(--mobile-caption-1-regular);
  }

  .balance-crypto {
    margin-bottom: var(--spacing-025);
    font: var(--mobile-text-medium);
  }

  .balance-fiat {
    font: var(--mobile-caption-1-regular);
  }
}

.header-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--spacing-050);

  .header-panel-title {
    font: var(--desktop-text-lg-semibold);
  }

  .header-panel-toggle {
    display: flex;
    gap: var(--spacing-100);
    align-items: center;
  }

  .header-panel-subtitle {
    font: var(--desktop-text-sm-medium);
  }
}

.empty-accounts {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  align-items: center;
  justify-content: center;

  margin-top: 20%;
  margin-bottom: 20%;

  .empty-accounts-text {
    font: var(--desktop-text-md-semibold);
    color: var(--text-tertiary);
    text-align: center;
  }
}

.small {
  .header-panel {
    order: 2;
    padding: var(--spacing-150);
    border-top: 0.5px solid var(--border-primary);
  }

  .header-panel-title {
    display: none;
  }

  .empty-accounts {
    .empty-accounts-text {
      width: 300px;
      font: var(--mobile-text-content-regular);
    }

    .empty-image {
      width: 200px;
    }
  }
}

.wallet-button-wrapper {
  display: flex;
  justify-content: center;
  padding: var(--spacing-150);
  order: 2;
}

.wallet-button {
  width: 100%;
}

.mobile {
  .header-panel {
    margin: var(--spacing-100) var(--spacing-150);
    padding: var(--spacing-125);
    background-color: var(--background-secondary);
    border-radius: var(--border-radius-125);
  }

  .header-panel-title {
    display: none;
  }

  .header-panel-subtitle {
    font: var(--mobile-text-content-regular);
    color: var(--text-primary);
  }

  .empty-accounts-text {
    width: 300px;
    font: var(--mobile-text-content-regular);
  }

  .empty-image {
    width: 200px;
  }

  .curency-header {
    display: none;
  }

  .wallet-button-wrapper {
    padding: var(--spacing-200);
  }
}

.site-currencies {
  margin: var(--spacing-200) 0 calc(-1 * var(--spacing-200));
}

/* stylelint-enable */
</style>
