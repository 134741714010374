<template>
  <label class="switcher" :class="classes">
    <input
      v-bind="$attrs"
      v-model="isChecked"
      class="input"
      type="checkbox"
      :disabled="disabled"
      data-t="st-toggle"
    />
    <span class="switch" :class="color" data-t="st-toggle-switch" />
    <span class="label">{{ label }} <slot /></span>
  </label>
</template>

<script setup lang="ts">
const {
  disabled,
  label,
  size = 'm',
  color = 'primary',
} = defineProps<{
  disabled?: boolean
  label?: string
  size?: 's' | 'm'
  color?: 'primary' | 'secondary'
}>()

const isChecked = defineModel<boolean>()

const sizeValue = computed(() =>
  size === 'm'
    ? {
        container: '44px',
        height: '24px',
      }
    : {
        container: '36px',
        height: '20px',
      },
)

const classes = computed(() => [size, { disabled, active: isChecked.value }])
</script>

<style scoped>
.switcher {
  --switch-container-width: v-bind('sizeValue.container');
  --switch-size: calc(var(--switch-container-width) / 2);

  cursor: pointer;

  display: flex;
  align-items: center;

  /** 
   * @TODO: 
   * 1. Переделать на min-размеры,
   * 2. Убрать все встречающиеся по проекту при использовании компонента классы,
   * влияющие на ширину тоггла
   * 3. По необходимости внести выбивающиеся размеры в size
   */
  width: v-bind('sizeValue.container');
  height: v-bind('sizeValue.height');
}

.switcher .label {
  overflow: hidden;

  margin-left: var(--spacing-150);

  font: var(--desktop-text-md-medium);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.switcher.s .label {
  margin-left: var(--spacing-125);
  font: var(--mobile-text-content-regular);
}

.switcher .input {
  position: absolute;

  overflow: hidden;

  width: 0;
  height: 0;
  padding: 0;

  white-space: nowrap;

  opacity: 0;
  border-width: 0;
}

.switcher .switch {
  position: relative;

  display: flex;
  flex-basis: 100%;
  flex-shrink: 0;
  align-items: center;

  height: 100%;

  background-color: var(--button-gray-default);
  border-radius: var(--switch-size);

  transition: background-color 0.2s ease-in-out;
}

.switcher .switch::before {
  content: '';

  position: absolute;
  left: 2px;

  width: calc(var(--switch-size) - var(--spacing-025));
  height: calc(var(--switch-size) - var(--spacing-025));

  background-color: var(--text-primary);
  border-radius: var(--border-radius-full);

  transition: transform 0.2s ease-in-out;
}

.switcher:hover .switch {
  background-color: var(--button-gray-hover);
}

.switcher.active .input + .switch {
  background-color: var(--button-primary-default);

  &.secondary {
    background-color: var(--button-secondary-default);
  }
}

.switcher.active .input + .switch::before {
  left: 0;
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}

.switcher .input:focus-visible + .switch {
  box-shadow: var(--focus-rings-button-gray);
}

.switcher.disabled .input + .switch {
  opacity: 0.32;
}

.switcher.active .input:focus-visible + .switch {
  box-shadow: var(--focus-rings-button-primary);
}

.switcher.disabled {
  cursor: not-allowed;
}

.switcher.disabled .label {
  cursor: not-allowed;
}
</style>
