import type { RouteLocationNormalizedGeneric } from 'vue-router'

export function useNavigationHistory() {
  const history = useState<RouteLocationNormalizedGeneric[]>(
    'navigationHistory',
    () => [],
  )

  const previousRoute = computed(() => history.value.at(-2))
  const isEmpty = computed(() => history.value.length === 0)

  return { history, previousRoute, isEmpty }
}
