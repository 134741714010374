<template>
  <article class="history-bet" data-t="history-bet">
    <header class="header">
      <div class="left">
        <div class="info">
          <div class="icon-status" :style="{ color: statusIcon.color }">
            <StIcon :name="statusIcon.name" :size="16" />
          </div>
          <span class="bet-type">{{ betNameByType }}</span>
          <StCounter
            v-if="bet.outcomes.length > 1"
            bg-color="black"
            color="white"
            :label="bet.outcomes.length"
            size="xs"
          />
        </div>
        <p class="created-at">{{ createdAt }}</p>
      </div>
      <div class="actions">
        <StCopy :value="bet.id">
          <span class="bet-id">{{ bet.id }}</span>
        </StCopy>
        <StIcon
          v-if="props.bet.type === 'express'"
          class="toggle"
          :name="isShown ? 'chevron-top' : 'chevron-down'"
          :size="16"
          @click="isShown = !isShown"
        />
      </div>
    </header>
    <StTransitionExpand with-overflow>
      <div v-if="isShown" class="bet-body">
        <HistoryBetOutcome
          v-for="outcome in bet.outcomes"
          :key="outcome.id"
          :outcome="outcome"
          :bet="props.bet"
          :currency-icon="currencyIcon"
        />
      </div>
    </StTransitionExpand>
    <footer v-if="isShownFooter" class="bet-footer">
      <div class="footer-body">
        <div class="column">
          <div v-if="isVisibleCashoutsButtons" data-t="cashouts-buttons">
            <CashoutButtons
              :amount="cashoutAmountWithCurrency"
              :back-amount="cashoutBackendAmountWithCurrency"
              :cashout-info-status="cashoutInfoStatus"
              :cashout-status="cashoutStatus"
              @start-cashout-process="startCashoutProcess"
              @cash-out-bet="cashOutBet"
            />
          </div>
        </div>
        <div class="column" />
        <div class="bet-info">
          <template v-if="props.bet.type === 'express'">
            <div class="column">
              <span class="small right">
                {{
                  props.bet.accountType === 'real'
                    ? t('bets.bid')
                    : t('bets.freebet')
                }}
              </span>
              <div class="amount">
                <strong data-t="bidAmount">
                  {{ format(Number(props.bet.amount)) }}
                </strong>
                <StIcon v-if="currencyIcon" :name="currencyIcon" :size="16" />
              </div>
            </div>
            <div class="column">
              <span class="small right">
                {{ t('bets.rate') }}
              </span>
              <p class="rate">
                {{ betRate }}
              </p>
            </div>
            <div class="column">
              <span class="small right">
                {{ resultText }}
              </span>
              <div class="amount result-amount">
                <strong data-t="resultAmount" :class="betWinLoseStatus">
                  {{ resultAmount }}
                </strong>
                <StIcon v-if="currencyIcon" :name="currencyIcon" :size="16" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </footer>
  </article>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import CashoutButtons from '../../CashoutButtons/CashoutButtons.vue'
import { useHistoryBet } from '../../../composables/useHistoryBet'
import { useBetCashout } from '../../../composables/useBetCashout'
import type { ExtendedBet } from '../../../types'
import HistoryBetOutcome from './HistoryBetOutcome.vue'

interface Props {
  bet: ExtendedBet
}

const { t } = useI18n()

const props = defineProps<Props>()
const { bet } = toRefs(props)

const {
  currencyIcon,
  statusIcon,
  resultAmount,
  createdAt,
  betWinLoseStatus,
  resultText,
  betRate,
} = useHistoryBet(bet)
const { format } = useCryptoFormatter()

const betNameByType = computed(() =>
  props.bet.type === 'express' ? t('bets.express') : t('bets.ordinar'),
)
const isShown = ref(true)
const {
  betCashOutAmount,
  cashoutStatus,
  cashoutInfoStatus,
  cashoutAmountWithCurrency,
  cashoutBackendAmountWithCurrency,
  startCashoutProcess,
  cashOutBet,
} = useBetCashout(bet)

const isVisibleCashoutsButtons = computed(
  () =>
    new Decimal(betCashOutAmount.value).greaterThan(0) &&
    cashoutStatus.value !== 'success',
)

const isShownFooter = computed(
  () => bet.value.type === 'express' || isVisibleCashoutsButtons.value,
)
</script>

<style scoped>
.bet-footer {
  padding: var(--spacing-125) var(--spacing-150);
  background: var(--background-tertiary);
  border-radius: 0 0 var(--spacing-100) var(--spacing-100);
}

.history-bet {
  display: flex;
  flex-direction: column;
  background: var(--background-secondary);
  border-radius: var(--border-radius-150);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-125) var(--spacing-150);

  color: var(--text-tertiary);
}

.actions {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
}

.created-at {
  margin: 0;
  font: var(--desktop-text-xs-medium);
}

.info {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
}

.bet-type {
  font: var(--desktop-text-xs-semibold);
  color: var(--text-primary);
}

.toggle {
  cursor: pointer;

  &:hover {
    color: var(--text-primary);
  }
}

.left {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
}

.bet-body {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);

  padding: 0 var(--spacing-150);
  padding-top: var(--spacing-050);
}

.footer-body {
  display: flex;
  gap: var(--spacing-250);
}

.bet-info {
  display: flex;
  flex: 1 1 10%;
  gap: var(--spacing-050);
  justify-content: flex-end;
}

.icon-status {
  display: flex;
  align-items: center;
}

.small {
  overflow: hidden;
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
}

.right {
  text-align: right;
}

.amount {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: flex-end;

  strong {
    font: var(--desktop-text-sm-semibold);
    color: var(--text-primary);
    text-align: right;

    &.win,
    &.cashout {
      color: var(--system-success);
    }

    &.lose {
      color: var(--text-secondary);
    }
  }
}

.rate {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
  text-align: right;
}

.column {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  min-width: 0;
}

.bet-id {
  font: var(--desktop-text-xs-medium);
}
</style>
