<template>
  <div
    v-if="isTelegramMiniApp && prohibitedCurrencies.length"
    class="wrapper"
    data-t="site-available-crypto"
  >
    <div class="content">
      <div class="currencies-container">
        <StCryptoIcon
          v-for="currency of prohibitedCurrencies"
          :key="currency.id"
          :currency="currency.code"
          :size="28"
          type="grayscale"
          data-t="prohibited-crypto-icon"
        />
      </div>
      <div class="bottom-container">
        <div class="text">
          {{ t('payments.siteAvailableCrypto.text') }}
        </div>
        <StButton
          class="site-button"
          size="s"
          type="primary"
          icon="chevron-right"
          :label="t('payments.siteAvailableCrypto.button')"
          @click="sendToSite"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTelegram } from '@st/telegram/composables/useTelegram'
import { usePaymentsDeps } from '../../useDeps'

const { background = 'base' } = defineProps<{
  background?: 'base' | 'primary'
}>()

const { t } = useI18n()
const { isTelegramMiniApp } = usePaymentsDeps()
const { prohibitedCurrencies } = useAllowedAssets()
const url = useRequestURL()
const { openExternalLinkFromMiniApp } = useTelegram()

const backgroundStyle = computed<string>(
  () => `var(--background-${background})`,
)

function sendToSite() {
  openExternalLinkFromMiniApp(url.origin)
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;

  padding: 0 var(--spacing-200) var(--spacing-200);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);

  width: 100%;
  padding: var(--spacing-200);

  background-color: v-bind(backgroundStyle);
  border: var(--border-width-boldest);
  border-radius: var(--border-radius-150);
}

.currencies-container {
  display: flex;
  gap: var(--spacing-075);
}

.bottom-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.text {
  flex-basis: 46%;
  max-width: 170px;
  font: var(--mobile-text-semibold);
}

.site-button {
  width: 84px;
  height: 28px;
}
</style>
