/**
 * Этот плагин предназначен для отправки сообщения от встроенной страницы игры
 * казино к родительскому окну. Это позволяет родительской странице
 * корректно обрабатывать сценарии, когда ifrmame игры становится недоступен и
 * происходит редирект на главную страницу в рамках этого
 * же iframe, предотвращая возможную рекурсию загрузок.
 */
export default defineNuxtPlugin({
  name: 'CasinoPostMessage',
  async setup() {
    window?.parent?.postMessage({ type: 'openedMainPage' }, '*')
  },
})
