<template>
  <nav class="game-navigation">
    <StBreadCrumbs :crumbs="gamePageCrumbs" class="crumbs" />
    <div class="wrapper">
      <div class="wrapper">
        <div class="demo-toggler">
          <span :class="{ inactive: demoToggle }">
            {{ t('casinoGames.demoMode') }}
          </span>
          <StToggle
            v-model="demoToggle"
            size="s"
            :disabled="!hasDemo"
            color="secondary"
            data-t="demo-toggle"
          />
          <span
            :class="{ inactive: !demoToggle }"
            data-t="play-on-money-label-k1oj"
            >{{ t('casinoGames.playOnMoney') }}</span
          >
        </div>
        <StTooltip trigger="hover" placement="top">
          <template #activator>
            <StButton
              size="m"
              type="gray"
              icon="graph-solid"
              data-t="max-win-toggle-button"
              @click="isShowMaxWinWidget = !isShowMaxWinWidget"
            />
          </template>
          <div class="tooltip-content">{{ t('casino.biggestWins') }}</div>
        </StTooltip>
        <FavoriteButton v-if="isShownFavoriteButton" :game-id="gameId" />
      </div>
      <MaxWinWidget
        v-model="isShowMaxWinWidget"
        class="widget"
        :game-id="gameId"
        is-desktop
        data-t="max-win-widget"
      />
    </div>
    <div class="right-content">
      <CurrencyWidget v-if="gameMode === 'real'" />
      <div class="buttons">
        <StTooltip trigger="hover" placement="top">
          <template #activator>
            <StButton
              icon="expand-1"
              size="m"
              type="gray"
              data-t="fullscreen-button"
              @click="emit('resize')"
            />
          </template>
          <div class="tooltip-content">{{ t('casino.fullScreenMode') }}</div>
        </StTooltip>
        <StTooltip trigger="hover" placement="top">
          <template #activator>
            <StButton
              data-t="go-back-button"
              icon="cross-large"
              size="m"
              type="gray"
              @click="goBack"
            />
          </template>
          <div class="tooltip-content">{{ t('casino.closeGame') }}</div>
        </StTooltip>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import CurrencyWidget from './CurrencyWidget.vue'
import MaxWinWidget from './MaxWinWidget/MaxWinWidget.vue'
import FavoriteButton from './FavoriteButton.vue'

interface Props {
  gameMode: 'real' | 'demo'
  gamePageCrumbs: { label: string; link: string }[]
  isShownFavoriteButton?: boolean
  hasDemo: boolean
  gameId: number
}

const {
  gameId,
  gameMode,
  gamePageCrumbs,
  isShownFavoriteButton = true,
  hasDemo,
} = defineProps<Props>()

const demoToggle = defineModel<boolean>('demo', { default: false })
const isShowMaxWinWidget = defineModel<boolean>('widget', { default: false })

const emit = defineEmits<{
  resize: []
}>()

const { t } = useI18n()
const { goBack } = useRouterBack()
</script>

<style scoped>
.game-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;
  padding: 0 var(--spacing-100) 0 var(--spacing-200);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.tabs {
  flex-shrink: 1;
  background: var(--background-base);
  border-radius: var(--border-radius-100);
}

.buttons {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
}

.right-content {
  display: flex;
  gap: var(--spacing-200);
  justify-content: flex-end;
}

.currency-block {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  &::after {
    content: '';

    display: block;

    width: 1px;
    height: 16px;
    margin-left: var(--spacing-100);

    background: var(--border-secondary);
    border-radius: var(--border-radius-full);
  }
}

.crumbs,
.right-content {
  flex-basis: 0;
  flex-grow: 1;
}

.wrapper {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
}

.widget {
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.demo-toggler {
  display: grid;
  grid-template-columns: max-content 36px max-content;
  gap: var(--spacing-100);

  span {
    font: var(--desktop-text-sm-semibold);
  }
}

.inactive {
  color: var(--text-secondary);
}

.tooltip-content {
  padding: var(--spacing-100) var(--spacing-150);
  font: var(--desktop-text-xs-medium);
}
</style>
