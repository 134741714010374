import SocketIO, { type Socket } from 'socket.io-client'
import { usePreventInitByRoute } from '@st/use/composables'

export default defineNuxtPlugin((nuxt) => {
  const requestURL = useRequestURL()
  const WS_URL = useRuntimeConfig().public.ws || `wss://${requestURL.host}`
  const WS_APP = useRuntimeConfig().public.wsApp

  const io: Socket = SocketIO(WS_URL, {
    transports: ['websocket', 'polling'],
    path: '/ws',
    autoConnect: false,
    query: { app: WS_APP },
  })

  function init() {
    io.connect()
  }

  onNuxtReady(() => {
    const restrictionRoutes = ['/promo/registration']
    usePreventInitByRoute(restrictionRoutes, init)
    /* тут можно вызвать отдельные composables для взаимодействия с io */
  })

  nuxt.vueApp.provide('io', io)

  return {
    provide: {
      io,
    },
  }
})
