<template>
  <div ref="reference" class="wrapper" v-bind="listeners">
    <slot name="activator" :is-open="isOpen" />
    <Teleport v-if="!disabled" to="body" :disabled="disableTeleport">
      <Transition
        name="tooltip"
        :class="[`background-${background}`]"
        data-t="tooltip"
      >
        <div
          v-if="isOpen"
          ref="floating"
          class="tooltip"
          :style="[floatingStyles, styles]"
          v-bind="tooltipListeners"
        >
          <div ref="floatingArrow" class="arrow" :style="arrowStyle">
            <svg
              width="16"
              height="8"
              viewBox="0 0 16 8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0L6.93934 6.93934C7.52513 7.52513 8.47487 7.52513 9.06066 6.93934L16 0H0Z"
              />
            </svg>
          </div>
          <slot>
            <div class="default-content" @click.stop>
              <span>{{ text }}</span>
            </div>
          </slot>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import {
  arrow,
  autoUpdate,
  flip,
  shift,
  offset,
  type Placement,
  useFloating,
} from '@floating-ui/vue'
import { delay, isNullish } from '@st/utils'

const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    placement?: Placement
    text?: string
    trigger?: 'hover' | 'click' | 'custom'
    disableTeleport?: boolean
    disabled?: boolean
    background?:
      | 'black'
      | 'container-primary'
      | 'container-secondary'
      | 'container-base'
      | 'container-tertiary'
    styles?: string
  }>(),
  {
    trigger: 'hover',
    placement: 'bottom',
    background: 'black',
  },
)

const { placement } = toRefs(props)

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void
}>()

const isOpen = ref(props.modelValue ?? false)

watchEffect(() => {
  emit('update:modelValue', isOpen.value)
})

watchEffect(async () => {
  if (isNullish(props.modelValue)) return
  await delay(0)
  isOpen.value = props.modelValue
})

/*
 * Решает баг со скрытием тултипа при переносе курсора с активатора на сам тултип
 * Часто при таком переносе курсор уходит за пределы активатора/тултипа и тултип скрывается.
 * Чтобы это пофикситить мы даем юзеру окно в 100мс чтобы вернуть ховер и не прятать тултип
 */
const isGoingToClose = ref(false)

function handleOpen() {
  isOpen.value = true
  isGoingToClose.value = false
}

function handleClick() {
  isOpen.value = !isOpen.value
}

async function handleClose() {
  isGoingToClose.value = true
  await delay(200)
  if (isGoingToClose.value) {
    isOpen.value = false
    isGoingToClose.value = true
  }
}

const listeners = computed(() => {
  if (props.trigger === 'custom') return {}

  if (props.trigger === 'hover') {
    return {
      'on:mouseover': handleOpen,
      'on:mouseleave': handleClose,
    }
  }
  return {
    'on:click': () => {
      handleClick()
    },
  }
})

const tooltipListeners = computed(() => {
  if (props.trigger === 'hover') {
    return {
      'on:mouseover': () => {
        if (isOpen.value) isGoingToClose.value = false
      },
      'on:mouseleave': handleClose,
    }
  }
  return {}
})

const reference = ref<HTMLDivElement>()
const floating = ref<HTMLDivElement>()
const floatingArrow = ref<HTMLDivElement>()

onClickOutside(reference, (event) => {
  if (!floating.value?.contains(event.target as Node)) isOpen.value = false
})

const {
  floatingStyles,
  middlewareData,
  placement: actualPlacement,
} = useFloating(reference, floating, {
  middleware: [
    offset(16),
    flip(),
    shift({ padding: 8 }),
    arrow({ element: floatingArrow }),
  ],
  strategy: 'absolute',
  placement,
  whileElementsMounted: autoUpdate,
})

const arrowStyle = computed(() => {
  const side = actualPlacement.value.split('-')[0]
  const { x, y } = middlewareData.value?.arrow ?? {}

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[side as never]

  const rotation = {
    top: '0deg',
    right: '90deg',
    bottom: '180deg',
    left: '270deg',
  }[side as never]

  return {
    left: x != null ? `${x}px` : '',
    top: y != null ? `${y}px` : '',
    [staticSide]: `-8px`,
    transform: `rotate(${rotation})`,
  }
})
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.arrow {
  position: absolute;

  display: flex;
  justify-content: center;

  width: 8px;
  height: 8px;

  svg {
    flex-shrink: 0;
  }
}

.default-content {
  max-width: 400px;
  padding: var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  text-align: center;
}

.tooltip {
  z-index: 999999;
  width: max-content;
  border-radius: var(--border-radius-100);
  box-shadow:
    0 24px 64px -4px rgb(0 0 0 / 40%),
    0 8px 16px -4px rgb(0 0 0 / 20%);

  &.background-black {
    background-color: var(--background-ghost-tooltip);
    backdrop-filter: blur(10px);

    svg {
      fill: var(--background-ghost-tooltip);
    }
  }

  &.background-container-base {
    background-color: var(--background-base);

    svg {
      fill: var(--background-base);
    }
  }

  &.background-container-primary {
    background-color: var(--background-primary);

    svg {
      fill: var(--background-primary);
    }
  }

  &.background-container-secondary {
    background-color: var(--background-secondary);

    svg {
      fill: var(--background-secondary);
    }
  }

  &.background-container-tertiary {
    background-color: var(--background-tertiary);

    svg {
      fill: var(--background-tertiary);
    }
  }
}

.tooltip-enter-active,
.tooltip-leave-active {
  transition: opacity 0.2s;
}

.tooltip-enter-from,
.tooltip-leave-to {
  opacity: 0;
}
</style>
