<template>
  <component
    :is="wrapperComponent"
    :to="notAvailableInRegion ? NOT_AVAILABLE_LINK : realLink"
    :target="notAvailableInRegion ? '_blank' : '_self'"
    class="game-card-wrapper"
    data-t="game-card"
    :data-game-id="game.gameId"
    :title="game.name"
    :class="cardClasses"
  >
    <div v-if="isWithLabels" class="labels">
      <StLabel
        v-for="label in labels"
        :key="label.name"
        :label="label.name"
        :bg-color="label.color"
        data-t="game-label-m3a3"
      />
    </div>
    <article class="game-card">
      <figure class="img-wrapper">
        <div class="img" data-t="game-image-6jvr">
          <CheckAnimation v-if="isSelected" />
          <GameImage :game="game" :width="216" />
          <div v-if="isInBonus && !isSelected" class="hover-card hover-bonus">
            {{ t('casino.selectThisGame') }}
          </div>
          <CasinoHover
            v-if="isInCasino"
            class="hover-card"
            :is-with-favourite-button="isAuthenticated"
            :is-with-tooltip="!isInSearch"
            :real-link
            :game
            :show-player-count
          />
          <div v-if="notAvailableInRegion" class="img-content">
            <p class="not-available-text">
              {{ t('casino.notAvailableInRegion') }}
            </p>
            <p v-if="isInCasino" class="how-change">
              {{ t('casino.howChangeRegion') }}
            </p>
          </div>
          <GameCardAnimation
            v-if="isInCasino"
            :image="game.image"
            :game-id="game.gameId"
            :is-animated="isAnimated"
          />
        </div>
        <figcaption class="info-wrapper">
          <div class="text-wrapper">
            <h3 class="card-header" data-t="game-name" :title="game.name">
              {{ game.name }}
            </h3>
            <p class="card-caption" data-t="game-provider">
              {{ game.provider }}
            </p>
          </div>
          <div v-if="showPlayers && isInCasino" class="players-info">
            <span class="players-count">
              <StLiveIndicator :size="16" color="orange" />
              <span>{{ game.playersCount }}</span>
            </span>
            <span class="players">{{ t('gameCard.plays') }}</span>
          </div>
        </figcaption>
      </figure>
    </article>
  </component>
</template>

<script lang="ts" setup>
import NuxtI18nLink from '@st/i18n/components/NuxtI18nLink.vue'
import { useCasinoDeps } from '@st/casino/useDeps'
import type { Game } from '../../types'
import useGameCard from './useGameCard'
import GameCardAnimation from './GameCardAnimation.vue'
import { useGameOpenAnimation } from '../../composables/useGameOpenAnimation'
import GameImage from './parts/GameImage.vue'
import CheckAnimation from './parts/CheckAnimation.vue'
import CasinoHover from './parts/CasinoHover.vue'

export interface Props {
  game: Game
  showPlayerCount?: boolean
  isSelected?: boolean
  type?: 'casino' | 'bonus' | 'casino-search'
}

const {
  game,
  isSelected,
  type = 'casino',
  showPlayerCount = true,
} = defineProps<Props>()

const { t } = useI18n()
const { isAuthenticated } = useCasinoDeps()
const { labels, showPlayers, notAvailableInRegion } = useGameCard(
  toRef(() => game),
  toRef(() => showPlayerCount),
)

const { createSupportLink } = useSupportUrl()

const NOT_AVAILABLE_LINK = computed(() =>
  createSupportLink('/articles/9516574'),
)

const realLink = computed(() => `/casino/games/${game.gameId}?mode=real`)

const { isLounchingGame, isAnimated } = useGameOpenAnimation(
  toRef(() => game.gameId),
)

const cardClasses = computed(() => [
  `type-${type}`,
  {
    loading: isLounchingGame.value,
    animated: isAnimated.value,
    'not-available': notAvailableInRegion.value,
  },
])

const isInCasino = computed(() => type === 'casino' || type === 'casino-search')

const isInSearch = computed(() => type === 'casino-search')

const isInBonus = computed(() => type === 'bonus')

const isWithLabels = computed(
  () =>
    labels.value.length &&
    !notAvailableInRegion.value &&
    !isInSearch.value &&
    !isInBonus.value,
)

const wrapperComponent = computed(() =>
  isInBonus.value ? 'div' : NuxtI18nLink,
)
</script>

<style lang="css" scoped>
.labels {
  pointer-events: none;

  position: absolute;
  z-index: 1;
  top: 10px;
  left: -4px;
  transform: translateX(0);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: flex-start;

  opacity: 1;

  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}

.hover-card {
  pointer-events: none;

  position: absolute;
  z-index: 2;
  inset: 0;

  display: flex;
  flex-direction: column;

  opacity: 0;
  background: rgb(0 0 0 / 68%);
  border-radius: var(--border-radius-100);

  transition: opacity 0.2s ease-in-out;
}

.hover-bonus {
  display: flex;
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
  text-align: center;
}

.img {
  position: relative;
  z-index: 1;
  transform: translateY(0);

  aspect-ratio: 216/146;
  width: 100%;
  height: auto;

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);

  transition: transform 0.2s ease-in-out;

  img {
    position: absolute;
    z-index: -1;
    inset: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    border-radius: var(--border-radius-100);
  }
}

.img-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: var(--spacing-300);

  font: var(--desktop-text-sm-semibold);
  text-align: center;

  background-color: #000000a3; /* stylelint-disable-line */
  border-radius: var(--border-radius-100);
}

.game-card-wrapper {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: block;

  padding-top: var(--spacing-050);

  color: var(--text-primary);
  text-decoration: none;

  transition: transform 0.2s ease-in-out;

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 66px;
    left: 50%;
    transform: translate(-50%);

    width: 180px;
    height: 70px;

    opacity: 0;
    filter: blur(25px);
    border-radius: var(--border-radius-150);

    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 0.48;
    }

    .labels {
      transform: translateX(8px);
      opacity: 0;
    }
  }

  &.not-available {
    &::after {
      content: '';

      position: absolute;
      z-index: 1;
      inset: 0;

      border-radius: var(--border-radius-150);
    }
  }

  &.loading {
    .img img {
      opacity: 0.66;
    }

    .hover-card {
      opacity: 0;
    }
  }

  &.animated {
    .labels {
      opacity: 0;
    }
  }

  &.type-bonus {
    &.not-available {
      pointer-events: none;
    }

    .img-content {
      padding: var(--spacing-050);
    }
  }
}

.img-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-0);
  align-items: center;
  align-self: stretch;

  margin: 0;

  filter: blur(0);
  border-radius: var(--border-radius-100);

  transition: filter 0.2s ease-in-out;
}

.info-wrapper {
  display: flex;
  gap: var(--spacing-075);
  align-items: flex-start;
  align-self: stretch;

  padding: var(--spacing-075) var(--spacing-125) var(--spacing-0)
    var(--spacing-125);
}

.game-card {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
  align-items: flex-start;

  width: 100%;
  height: auto;

  border-radius: var(--border-radius-150);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);

  &:hover {
    .img {
      transform: translateY(-4px);
    }

    .hover-card {
      pointer-events: auto;
      opacity: 1;
      border-radius: var(--border-radius-100);
    }
  }
}

.text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-header {
  overflow: hidden;

  max-width: 100%;
  margin: 0;

  font: var(--desktop-text-md-semibold);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-caption {
  overflow: hidden;

  max-width: 100%;
  margin: 0;

  font: var(--desktop-text-xxs-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.players-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  align-items: flex-end;
  justify-content: center;

  width: var(--spacing-600);
  padding-top: var(--spacing-075);
}

.players-count {
  display: flex;
  align-items: center;
  font: var(--desktop-text-xs-medium);
}

.players {
  font: var(--desktop-text-xxs-medium);
  color: var(--text-tertiary);
}

.info-icon {
  background-color: var(--icon-secondary);
  transition: background-color 0.2s ease-in-out;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.how-change {
  margin: 0;
  color: var(--button-primary-default);
}

.loader {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
}

.not-available-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  max-height: 60px;

  text-overflow: ellipsis;

  -webkit-line-clamp: 2;
}
</style>
