import Decimal from '@st/decimal'
import { useCasinoStore } from '@st/casino/stores/useCasinoStore'
import type { CasinoLastBet } from '@st/casino/components/LastBets/types'
import type { IconName } from '@st/ui/components/StIcon/types'
import { useCasinoDeps } from '@st/casino/useDeps'

export interface UseCasinoBetReturn {
  categoryIcon: Ref<string | null>
  categoryName: Ref<string>
  date: Ref<string>
  relativeDate: Ref<string>
  currencyIcon: Ref<IconName>
  cryptoBetsAmount: Ref<string>
  fiatBetsAmount: Ref<string>
  rate: Ref<string | null>
  formattedRate: Ref<string>
  betResultClass: Ref<'positive' | 'negative'>
  cryptoAmount: Ref<string>
  fiatBetResult: Ref<string>
  gameLink: Ref<string>
  winsAmountInGameCurrency: Ref<string>
}

export function useCasinoBet(bet: Ref<CasinoLastBet>): UseCasinoBetReturn {
  const { parseDate } = useDate()
  const { getCategoryByCode } = useCasinoStore()
  const { format: formatCrypto } = useCryptoFormatter()

  const { gameCurrencyCode, getCurrencyIconById } = useCasinoDeps()

  const { format: formatFiat } = useCurrencyFormatter({
    currency: gameCurrencyCode(() => bet.value),
    signDisplay: 'never',
  })

  const currencyIcon = computed(
    () => getCurrencyIconById(bet.value.currencyId) as IconName,
  )
  const category = computed(() => getCategoryByCode(bet.value.categoryCode))
  const cryptoBetsAmount = computed(() => formatCrypto(bet.value.betsAmount))
  /**
   * DayJS при нулевой разнице считает, что действие произошло в будущем
   * и пишет "через несколько секунд", поэтому вычитаем 1 секунду.
   */
  const utcDateOffset = computed(() =>
    parseDate(bet.value.updatedAt).subtract(1, 'second'),
  )
  const date = computed(() => utcDateOffset.value.fromNow())

  const relativeDate = computed(() =>
    parseDate(bet.value.updatedAt).format('LL LT'),
  )
  const categoryName = computed(
    () => category.value?.name || bet.value.categoryCode,
  )
  const categoryIcon = computed(() => category.value?.iconLink ?? null)
  const gameLink = computed(() => `/casino/games/${bet.value.gameId}?mode=real`)
  const fiatBetsAmount = computed(() =>
    formatFiat(bet.value.betsAmountInGameCurrency),
  )

  const rate = computed(() => {
    const winsAmount = bet.value.winsAmount || '0'
    const betsAmount = bet.value.betsAmount || '0'
    if (betsAmount === '0') return null
    return new Decimal(winsAmount).dividedBy(betsAmount).toFixed(2)
  })

  const formattedRate = computed(() =>
    rate.value === null ? '—' : `${rate.value}x`,
  )
  const winsAmountInGameCurrency = computed(
    () => bet.value.winsAmountInGameCurrency ?? '0',
  )
  const betResult = computed(() => ({
    fiat: winsAmountInGameCurrency.value,
    crypto: bet.value.winsAmount ?? '0',
  }))

  const cryptoAmount = computed(() => formatCrypto(betResult.value.crypto))
  const fiatBetResult = computed(() => formatFiat(betResult.value.fiat))

  const betResultClass = computed(() => {
    const betRate = new Decimal(rate.value ?? 0)

    if (betRate.greaterThan(0)) {
      return 'positive'
    }
    return 'negative'
  })
  return {
    categoryIcon,
    categoryName,
    date,
    relativeDate,
    currencyIcon,
    cryptoBetsAmount,
    fiatBetsAmount,
    rate,
    formattedRate,
    betResultClass,
    cryptoAmount,
    fiatBetResult,
    gameLink,
    winsAmountInGameCurrency,
  }
}
