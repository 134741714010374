import { nextTick, type Ref, watch, watchEffect, onUnmounted } from 'vue'

/**
 * Забористый хук, который фиксит баг, когда клавиатура в miniApp перекрывает drawer-compact.
 * Изменяет высоту контейнера на 100%, когда пользователь явно нажимает на инпут.
 *
 * @param container - ref на элемент контейнера модалки (drawer)
 * @param isActive - ref состояния активности модалки
 */
export function scrollToView(
  container: Ref<HTMLElement | null>,
  isActive: Ref<boolean>,
) {
  // Наблюдатель за DOM-изменениями внутри контейнера
  let observer: MutationObserver | null = null

  // Сет для хранения элементов, на которые уже навешаны обработчики
  const inputsWithListeners = new WeakSet<Element>()

  // Флаг, показывающий, что пользователь явно взаимодействовал с инпутом (кликнул или коснулся)
  let userInteracted = false

  // Следим за состоянием модалки (открыта/закрыта)
  watch(isActive, (active) => {
    const containerEl = container.value

    // При каждом изменении состояния модалки сбрасываем флаг взаимодействия пользователя
    userInteracted = false

    // Если модалка закрыта, очищаем высоту контейнера до исходного состояния
    if (!active && containerEl) {
      containerEl.style.height = ''
    }
  })

  // Функция, устанавливающая флаг взаимодействия с инпутом
  function setUserInteracted() {
    userInteracted = true
  }

  // Обработчик фокуса на инпуте
  function handleInputFocus(event: Event) {
    // Не меняем высоту контейнера, если пользователь явно не взаимодействовал с инпутом
    if (!userInteracted) return

    const target = event.target as HTMLElement

    nextTick(() => {
      const containerEl = container.value
      if (!containerEl) return

      // Прокручиваем инпут в центр экрана и увеличиваем высоту модалки на весь экран
      target.scrollIntoView({ behavior: 'smooth', block: 'center' })
      containerEl.style.height = '100vh'
    })
  }

  // Функция добавления обработчиков событий на инпуты внутри контейнера
  function addInputListeners() {
    const containerEl = container.value
    if (!containerEl) return

    const inputElements = containerEl.querySelectorAll('input, textarea')

    inputElements.forEach((input) => {
      if (!inputsWithListeners.has(input)) {
        // Обработчик фокуса
        input.addEventListener('focus', handleInputFocus)

        // Обработчики явного взаимодействия пользователя (для надёжности на touch и mouse события)
        input.addEventListener('touchstart', setUserInteracted, { once: true })
        input.addEventListener('mousedown', setUserInteracted, { once: true })

        // Сохраняем инпут в сете, чтобы избежать повторного навешивания обработчиков
        inputsWithListeners.add(input)
      }
    })
  }

  // Функция удаления обработчиков событий с инпутов
  function removeInputListeners() {
    const containerEl = container.value
    if (!containerEl) return

    const inputElements = containerEl.querySelectorAll('input, textarea')

    inputElements.forEach((input) => {
      if (inputsWithListeners.has(input)) {
        // Удаляем все обработчики событий
        input.removeEventListener('focus', handleInputFocus)
        input.removeEventListener('touchstart', setUserInteracted)
        input.removeEventListener('mousedown', setUserInteracted)

        // Убираем инпут из сета после удаления обработчиков
        inputsWithListeners.delete(input)
      }
    })
  }

  // Функция, которая запускает MutationObserver для отслеживания изменений внутри контейнера
  function observeInputs() {
    const containerEl = container.value
    if (!containerEl) return

    observer = new MutationObserver(() => {
      // При любых изменениях DOM внутри контейнера проверяем новые инпуты
      addInputListeners()
    })

    observer.observe(containerEl, {
      childList: true,
      subtree: true,
    })

    // Первоначальное добавление обработчиков при запуске observer-а
    addInputListeners()
  }

  // Автоматически запускаем и останавливаем наблюдение за контейнером в зависимости от состояния модалки
  watchEffect(() => {
    if (isActive.value && container.value) {
      nextTick(() => {
        observeInputs()
      })
    } else {
      // Останавливаем observer и удаляем слушатели, когда модалка закрывается
      if (observer) observer.disconnect()
      removeInputListeners()
    }
  })

  // При уничтожении компонента полностью очищаем обработчики и observer
  onUnmounted(() => {
    if (observer) observer.disconnect()
    removeInputListeners()

    // Сбрасываем высоту контейнера, если он ещё существует
    const containerEl = container.value
    if (containerEl) containerEl.style.height = ''
  })
}
