import { useAnalyticsScript } from './useAnalyticsScript'

interface Events {
  login: { userId: number }
  registration: void
  geoUnavailable: void
  initGame: {
    sessionId?: string
    gameId: number
    gameMode: string
  }
}

export function useAnalytics() {
  const { onLoaded } = useAnalyticsScript()
  /**
   * Отправка переменной в Matomo Tag Manager
   * @param variableName - Имя переменной
   * @param value - Значение переменной
   */
  function sendVariable(variableName: string, value: any) {
    onLoaded((mtm) => {
      mtm.push({
        [variableName]: value,
      })
    })
  }

  /**
   * Отправка события в Matomo Tag Manager
   * @param eventName - Имя события из интерфейса Events
   * @param payload - Данные события
   */
  function sendEvent<K extends keyof Events>(
    eventName: K,
    payload?: Events[K],
  ) {
    onLoaded((mtm) => {
      mtm.push({
        event: eventName,
        ...payload,
      })
    })
  }

  return { sendEvent, sendVariable }
}
