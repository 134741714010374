import { minBy, maxBy, groupBy, sortBy } from '@st/utils'
import { useFreespinsStore } from './useFreespinsStore'
import { useFreebetsStore } from './useFreebetsStore'
import { useCashBonusesStore } from './useCashBonusesStore'
import type { DepositBonus, ExtendedDepositBonus } from '../types'
import { useRefreshBonusesSocket } from '../composables/useRefreshBonusesSocket'
import { useBonusesDeps } from '../useDeps'

/**
  стор для агрегации бонусов, учавствующих в бонусной программе за депозит
  отдает наружу бонусные программы (одиночные, мультипрограммы, ступенчатые, цепочки)
  с наградами ( денежный бонус, фрибет, фриспин, релоад )
  и сами награды после вхождения в бонусную программу
*/
export const [useDepositBonusesStore, provideDepositBonusesStore] =
  createInjectableHook(
    defineStore('depositBonuses', () => {
      const stFetch = useRawStFetch()
      const { parseDate } = useDate()
      const rawDepositPrograms = ref<DepositBonus[]>([])
      const { depositFreespins } = storeToRefs(useFreespinsStore())
      const { depositFreebets } = storeToRefs(useFreebetsStore())
      const { cashBonuses: depositCashBonuses } = storeToRefs(
        useCashBonusesStore(),
      )

      /**
       * все бонусы с groupName группируем для вывода в объедененной карточке
       * остальные группируются как default и выводятся по 1-ой карточке
       */
      const groupedByNameDepositPrograms = computed(() =>
        groupBy(
          rawDepositPrograms.value,
          (bonus) => bonus.groupName ?? 'default',
        ),
      )
      const defaultDepositPrograms = computed(() => [
        ...groupedByNameDepositPrograms.value.get('default'),
      ])
      const stepDepositPrograms = computed<ExtendedDepositBonus[]>(() => {
        const result: ExtendedDepositBonus[] = []
        groupedByNameDepositPrograms.value.forEach(
          (groupBonuses, groupName) => {
            if (groupName === 'default') return

            /*
          из группы бонусов для отрисовки используется с Max weightInGroup
          minDepositAmount берется из Min weightInGroup
        */
            const lastStep = maxBy(groupBonuses, (item) => item.weightInGroup)
            const firstStep = minBy(groupBonuses, (item) => item.weightInGroup)
            if (!lastStep) return

            result.push({
              ...lastStep,
              steps: groupBonuses,
              minFromAmount: firstStep?.minDepositAmountInAppCurrency,
            })
          },
        )

        return result
      })
      const filteredDepositPrograms = computed(() =>
        stepDepositPrograms.value.concat(defaultDepositPrograms.value),
      )
      /**
       * группируем бонусные программы по chainName
       * в них также могут быть программы с groupName
       */
      const depositProgramsWithChain = computed(() => {
        const programsGroupedByChain = groupBy(
          filteredDepositPrograms.value,
          (bonus) => bonus.chainName ?? 'default',
        )
        const result: ExtendedDepositBonus[][] = []
        programsGroupedByChain.forEach((chainBonuses, chainName) => {
          if (chainName === 'default') return

          const isSomeProgramInProgress = chainBonuses.some(
            (bonus) => bonus.status === 'inProgress',
          )
          if (isSomeProgramInProgress) {
            result.push(sortBy(chainBonuses, (item) => [item.level]))
          }
        })

        return result
      })

      /**
       * в allDepositPrograms могут находится:
       * обычные бонусные программы за депозит
       * программы со ступенькой (groupName)
       * активные программы из цепочки бонусов (chainName)
       */
      const allDepositPrograms = computed(() =>
        filteredDepositPrograms.value.filter(
          (item) => !item.status || item.status === 'inProgress',
        ),
      )
      const depostProgramsAndAwards = computed(() => [
        ...allDepositPrograms.value,
        ...depositFreespins.value,
        ...depositFreebets.value,
        ...depositCashBonuses.value,
      ])
      const bonusesCount = computed(
        () =>
          allDepositPrograms.value.length +
          depositFreespins.value.length +
          depositFreebets.value.length +
          depositCashBonuses.value.length,
      )
      const favoriteNewDepositBonus = computed(() =>
        minBy(allDepositPrograms.value, (bonus) =>
          parseDate(bonus.expiredAt).unix(),
        ),
      )
      const isLoading = ref(false)

      async function fetchDepositBonusPrograms() {
        isLoading.value = true
        const { data, error } = await stFetch('/bonus-for-deposit/find', {
          method: 'post',
          body: {
            params: {},
            pagination: {
              page: 0,
              perPage: 50,
              orderBy: [
                {
                  fieldName: 'createdAt',
                  sortOrder: 'DESC',
                },
              ],
            },
          },
        })
        isLoading.value = false

        if (error) {
          console.error('Failed to load deposit bonuses', error)
          return
        }

        // TODO убрать as DepositBonus[] после доработок бэка
        rawDepositPrograms.value = data.data as DepositBonus[]
      }

      const router = useRouter()
      async function handleDepositModalShow() {
        if (!rawDepositPrograms.value.length) {
          await fetchDepositBonusPrograms()
        }

        if (favoriteNewDepositBonus.value) {
          router.replace({ query: { modal: 'depositBonus' } })
        }
      }

      const { isAuthenticated } = useBonusesDeps()
      watchEffect(() => {
        if (isAuthenticated.value) {
          fetchDepositBonusPrograms()
        } else {
          rawDepositPrograms.value = []
        }
      })

      useRefreshBonusesSocket(fetchDepositBonusPrograms)

      return {
        allDepositPrograms,
        depositProgramsWithChain,
        depostProgramsAndAwards,
        depositFreespins,
        depositFreebets,
        depositCashBonuses,
        favoriteNewDepositBonus,
        bonusesCount,
        isLoading,
        handleDepositModalShow,
        fetchDepositBonusPrograms,
        rawDepositPrograms,
      }
    }),
  )
