export function useConnectTelegramMiniApp() {
  const toast = useToast()
  const { t } = useNuxtApp().$i18n

  const { hasTelegramProvider, getAuthProvidersList } = useUserAuthProviders()
  const {
    isTelegramMiniApp,
    openTelegramBotFromMiniApp,
    connectTelegramFromMiniApp,
  } = useTelegram()

  async function handleTelegramConnect() {
    if (!isTelegramMiniApp.value) return

    // логика для tg-mini-app
    if (hasTelegramProvider.value) {
      openTelegramBotFromMiniApp()
      return
    }

    const { error } = await connectTelegramFromMiniApp()

    if (!error) {
      openTelegramBotFromMiniApp()
      getAuthProvidersList()
    } else {
      toast.open({
        type: 'negative',
        label: t('userVerificationModal.errors.somethingWentWrong'),
      })
    }
  }

  return {
    isTelegramMiniApp,
    handleTelegramConnect,
  }
}
