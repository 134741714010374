<template>
  <Teleport to="body">
    <div class="toasts-wrapper" :class="position">
      <TransitionGroup name="list" class="toasts" tag="div">
        <StToast
          v-for="toast in toasts"
          :key="toast.key"
          class="toasts-item"
          :type="toast.type"
          :label="toast.label"
          @close="close(toast.key)"
        />
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import type { Toast } from './types'

interface Props {
  position?: 'top' | 'bottom'
}

const { position = 'top' } = defineProps<Props>()

const toasts = useState<Toast[]>('toasts', () => [])

function close(key: string) {
  toasts.value = toasts.value.filter((toast) => toast.key !== key)
}
</script>

<style scoped>
.toasts-wrapper {
  position: fixed;
  z-index: 10000;
  right: 0;
  bottom: var(--spacing-200);
  left: 0;
}

.toasts {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: center;

  padding: 0 var(--spacing-100);
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.list-enter-from,
.list-leave-to {
  transform: translateY(16px);
  opacity: 0;
}

.list-leave-active:not(:first-child) {
  position: absolute;
}

.toasts-wrapper.top {
  top: var(--spacing-200);
  bottom: auto;

  .toasts {
    flex-direction: column-reverse;
  }

  .list-enter-from,
  .list-leave-to {
    transform: translateY(-16px);
  }
}
</style>
