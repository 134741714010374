<template>
  <div
    v-if="isOpened"
    class="max-win"
    :class="{ 'is-desktop': isDesktop }"
    :data-t="isDesktop ? 'max-win-desktop' : 'max-win-mobile'"
  >
    <div
      class="max-win-wrapper"
      :class="{ 'without-progress': withoutProgress }"
      data-t="max-win-wrapper"
    >
      <div v-if="isDesktop" class="arrow">
        <svg
          data-v-49238364=""
          width="16"
          height="8"
          viewBox="0 0 16 8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            data-v-49238364=""
            d="M0 0L6.93934 6.93934C7.52513 7.52513 8.47487 7.52513 9.06066 6.93934L16 0H0Z"
          ></path>
        </svg>
      </div>
      <div class="title" data-t="max-win-title">
        {{ t('maxWin.about') }}
        <button
          v-if="!isDesktop"
          class="close"
          data-t="close-button"
          @click="isOpened = false"
        >
          <StIcon class="cross-icon" name="cross-large" size="16" />
        </button>
      </div>
      <div class="blocks-wrapper" data-t="blocks-wrapper">
        <div class="block" data-t="block-all-time">
          <img class="block-img" src="../img/treasure.png" alt="total" />
          <div class="block-text">
            {{ t('maxWin.forAllTime') }}
            <div class="count" data-t="total-wins">{{ bigWins.total }}</div>
          </div>
        </div>
        <div class="block" data-t="block-monthly">
          <img class="block-img" src="../img/bag.png" alt="monthly" />
          <div class="block-text">
            {{ t('maxWin.forMonth') }}
            <div class="count" data-t="monthly-wins">{{ bigWins.monthly }}</div>
          </div>
        </div>
        <div class="block" data-t="block-daily">
          <img class="block-img daily" src="../img/coins.png" alt="monthly" />
          <div class="block-text">
            {{ t('maxWin.forDay') }}
            <div class="count" data-t="daily-wins">{{ bigWins.daily }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import { useMaxWin } from '../../../../composables'

const props = defineProps<{
  gameId: number
  isDesktop?: boolean
  modelValue: boolean
}>()

const { gameId } = toRefs(props)
const { t } = useI18n()
const { bigWins } = useMaxWin(gameId)
const withoutProgress = ref(false)

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const isOpened = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const hasShownKey = computed(() => `maxWinWidgetShown_${gameId.value}`)
const hasShown = useLocalStorage(hasShownKey.value, false)

async function callWithDelay() {
  isOpened.value = true
  await delay(3500)
  isOpened.value = false
}

watchEffect(async () => {
  if (
    !withoutProgress.value &&
    Object.values(bigWins.value).some((val) => +val !== 0)
  ) {
    if (!hasShown.value) {
      await callWithDelay()
      hasShown.value = true
    }
    withoutProgress.value = true
  }
})
</script>

<style scoped>
.max-win {
  position: relative;

  gap: var(--spacing-075);

  width: 100%;
  margin: var(--spacing-075) auto 0;

  color: var(--text-primary);

  &.is-desktop {
    width: min-content;
  }
}

.max-win-wrapper {
  overflow: hidden;

  margin: 0 var(--spacing-100);
  padding-bottom: var(--spacing-150);

  background: var(--background-ghost-tooltip, rgb(0 0 0 / 76%));
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-150, 12px);
  box-shadow: 0 24px 50px -8px #121219;
}

.title {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--spacing-075);
  padding-left: var(--spacing-150);

  font: var(--mobile-text-semibold);
  white-space: nowrap;
}

.close {
  all: unset;

  cursor: pointer;

  width: 32px;
  height: 32px;

  text-align: center;
}

.cross-icon {
  background-color: var(--icon-secondary);
}

.blocks-wrapper {
  position: relative;

  display: flex;
  gap: var(--spacing-100);
  align-self: stretch;

  margin: 0 var(--spacing-100) 0;
  padding: var(--spacing-025) 0 var(--spacing-150);

  &::after {
    content: '';

    position: absolute;
    z-index: 1;
    bottom: -2px;
    left: 0;

    display: block;

    width: 100%;
    height: 4px;

    background-color: var(--background-tertiary);
    border-radius: var(--border-radius-full);
  }

  &::before {
    content: '';

    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: 0;

    display: block;

    width: 100%;
    height: 4px;

    background-color: var(--background-brand);
    border-radius: var(--border-radius-full);

    animation: progress 3.5s linear forwards;
  }

  .is-desktop &::after {
    bottom: -14px;
  }

  .is-desktop &::before {
    bottom: -14px;
  }

  &.without-progress {
    &::before {
      content: none;
    }

    &::after {
      content: none;
    }
  }
}

.block {
  position: relative;

  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: var(--spacing-075);
  align-items: center;

  width: 127px;

  &::after {
    content: '';

    position: absolute;
    z-index: 1;
    top: 4px;
    right: -4px;
    bottom: 4px;

    display: block;

    width: 0.5px;

    background: var(--border-primary);
  }

  &:last-child::after {
    content: none;
  }
}

.block-text {
  font: var(--mobile-caption-2-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.count {
  margin-top: var(--spacing-025);
  font: var(--mobile-text-semibold);
  color: var(--text-primary);
}

.block-img {
  width: 32px;
  height: 32px;
}

.is-desktop {
  .max-win-wrapper {
    padding: var(--spacing-100) var(--spacing-100) var(--spacing-300);
  }

  .title {
    justify-content: center;
    margin-bottom: var(--spacing-100);
    padding: 0;
    font: var(--desktop-text-md-semibold);
  }

  .block {
    width: 184px;
  }

  .block-text {
    font: var(--desktop-text-xs-medium);
  }

  .count {
    font: var(--desktop-text-md-semibold);
  }

  .block-img {
    width: 36px;
    height: 36px;
  }
}

@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.arrow {
  position: absolute;
  top: -9px;
  right: 168px;
  transform: rotate(180deg);

  svg {
    fill: var(--background-ghost-tooltip, rgb(0 0 0 / 76%));

    path {
      backdrop-filter: blur(10px);
    }
  }
}
</style>
