import { useUserStore } from '../stores/useUserStore'

export interface UseKycLevelReturn {
  kycLevel: Ref<number>
  kycLevelName: Ref<string>
}

export function useKycLevel(): UseKycLevelReturn {
  const { t } = useNuxtApp().$i18n
  const { user } = storeToRefs(useUserStore())

  const kycLevel = computed(() => user.value?.kycLevel ?? 0)

  const kycLevelName = computed(() => {
    if (user.value?.kycLevel === -1) {
      return t('verification.minusOneKycLevel')
    }

    if (user.value?.kycLevel && user.value.kycLevel > 0) {
      return t('verification.level', { level: user.value.kycLevel })
    }

    return t('verification.base')
  })

  return {
    kycLevel,
    kycLevelName,
  }
}
