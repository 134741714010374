<template>
  <img
    v-if="image"
    :src="image"
    :alt="game.name"
    :width="width"
    loading="lazy"
    @error="handleError"
  />
</template>

<script setup lang="ts">
import type { Game } from '../../../types'

const { game, width = 216 } = defineProps<{
  game: Game
  width: number
}>()

// @ts-expect-error outdated backend
const images = computed(() => [game.image, game.aggregatorUrl].filter(Boolean))

const currentIndex = ref(0)

watch(
  () => game.gameId,
  () => {
    currentIndex.value = 0
  },
)

const image = computed(() => images.value[currentIndex.value])

function handleError() {
  currentIndex.value += 1
}
</script>
